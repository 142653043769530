import "./IncidentPicker.scss";

import { IonButton, IonContent, IonIcon, IonLabel } from "@ionic/react";
import { useStateMachine } from "little-state-machine";
import { useHistory } from "react-router";

import { IncidentType } from "../../model/IncidentType.enum";
import { RoutePath } from "../../model/RoutePath.enum";
import {
  updateSteps,
  updateSummary,
  updateTemporaryIncidentSummary,
} from "../../state/updateState";
import { MainFooter, MainHeader } from "../Layout/Layout";
import { useTranslation } from "react-i18next";
import React from "react";

interface IncidentButtons {
  label: IncidentType;
  type: IncidentType;
  url: RoutePath;
  icon: string;
}

const IncidentPicker: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { actions, state } = useStateMachine({
    updateSteps,
    updateTemporaryIncidentSummary,
    updateSummary,
  });

  const baseIconPath = `${process.env.PUBLIC_URL}/assets/images/${
    process.env.REACT_APP_ALT_ICONS_PATH || ""
  }`;
  const incidentsTypes: IncidentButtons[] = [
    {
      label: IncidentType.START_ISSUE,
      type: IncidentType.START_ISSUE,
      url: RoutePath.STARTER_ISSUE_INCIDENT,
      icon: `${baseIconPath}${IncidentType.START_ISSUE}.svg`,
    },
    {
      label: IncidentType.ENGINE,
      type: IncidentType.ENGINE,
      url: RoutePath.ENGINE_INCIDENT,
      icon: `${baseIconPath}${IncidentType.ENGINE}.svg`,
    },
    {
      label: IncidentType.WHEEL_AND_TIRE,
      type: IncidentType.WHEEL_AND_TIRE,
      url: RoutePath.WHEELS_AND_TIRES,
      icon: `${baseIconPath}${IncidentType.WHEEL_AND_TIRE}.svg`,
    },
    {
      label: IncidentType.DOOR_AND_KEYS,
      type: IncidentType.DOOR_AND_KEYS,
      url: RoutePath.DOORS_AND_KEYS_INCIDENT,
      icon: `${baseIconPath}${IncidentType.DOOR_AND_KEYS}.svg`,
    },
    {
      label: IncidentType.FUEL,
      type: IncidentType.FUEL,
      url: RoutePath.FUEL_INCIDENT,
      icon: `${baseIconPath}${IncidentType.FUEL}.svg`,
    },
    {
      label: IncidentType.FLUID_LOSS,
      type: IncidentType.FLUID_LOSS,
      url: RoutePath.FLUID_LOSS_INCIDENT,
      icon: `${baseIconPath}${IncidentType.FLUID_LOSS}.svg`,
    },
    {
      label: IncidentType.ACCIDENT,
      type: IncidentType.ACCIDENT,
      url: RoutePath.LOCATION,
      icon: `${baseIconPath}${IncidentType.ACCIDENT}.svg`,
    },
    ...(process.env.REACT_APP_HIDE_BIKE_INCIDENT === "true"
      ? []
      : [
          {
            label: IncidentType.BICYCLE,
            type: IncidentType.BICYCLE,
            url: RoutePath.BICYCLE_INCIDENT,
            icon: `${baseIconPath}${IncidentType.BICYCLE}.svg`,
          },
        ]),
    {
      label: IncidentType.OTHER,
      type: IncidentType.OTHER,
      url: RoutePath.OTHER_INCIDENT,
      icon: `${baseIconPath}${IncidentType.OTHER}.svg`,
    },
  ];

  const goToPage = (page: RoutePath, incidentType: IncidentType) => {
    if (state.isInEditMode) {
      actions.updateTemporaryIncidentSummary({
        incidentDetails: state.summary.incidentDetails,
        incidentType: state.summary.incidentType,
      });

      // Accident Incident does not have an incident details screen, so we need to clear all the information
      incidentType === IncidentType.ACCIDENT
        ? actions.updateSummary({
            incidentType: incidentType,
            incidentDetails: undefined,
          })
        : actions.updateSummary({ incidentType: incidentType });
    } else {
      // if it has incident details, reset when changing incident type to avoid error on form validation
      state.steps.incidentDetails
        ? actions.updateSteps({
            incidentType: incidentType,
            incidentDetails: undefined,
          })
        : actions.updateSteps({ incidentType: incidentType });
    }
    history.push(`${RoutePath.REQUEST}/${page}`);
  };

  const getIncidentsTypes = (): IncidentButtons[] => {
    if (
      state.steps.immobilizedOnHighway ||
      (state.isInEditMode && state.summary.immobilizedOnHighway)
    ) {
      return incidentsTypes.filter(
        (incident: IncidentButtons) => incident.type !== IncidentType.BICYCLE
      );
    }
    return incidentsTypes;
  };

  const handleOnBack = () => {
    // Reset the incident details of summary
    if (state.isInEditMode && state.temporaryIncidentSummary) {
      actions.updateSummary({
        incidentDetails: state.temporaryIncidentSummary.incidentDetails,
        incidentType: state.temporaryIncidentSummary.incidentType,
      });
    }
    history.back();
  };

  return (
    <IonContent>
      <div className="incident-component">
        <MainHeader title={t("What sort of issue are you encountering?")}>
          <section className="incident-type-grid">
            {getIncidentsTypes().map(
              (incident: IncidentButtons, index: number) => {
                return (
                  <div
                    id={incident.type}
                    key={index}
                    className="incident-button"
                    onClick={() => goToPage(incident.url, incident.type)}
                  >
                    <img
                      alt="incident-logo"
                      className="button-logo"
                      src={incident.icon}
                    />
                    <IonLabel class="label">{t(incident.label)}</IonLabel>
                    <IonIcon
                      class="incident-arrow-icon"
                      size="large"
                      src={`${process.env.PUBLIC_URL}/assets/images/chevron-forward.svg`}
                    />
                  </div>
                );
              }
            )}
          </section>
        </MainHeader>
        <MainFooter>
          <IonButton
            id="incident-picker-back"
            className="button-tcs full-width back"
            expand="block"
            onClick={() => handleOnBack()}
          >
            <div className="wrapper">
              <IonIcon
                size="large"
                src={`${process.env.PUBLIC_URL}/assets/images/chevron-back.svg`}
              />
              <IonLabel>{t("Back")}</IonLabel>
              <div />
            </div>
          </IonButton>
        </MainFooter>
      </div>
    </IonContent>
  );
};

export default IncidentPicker;
