// t('Accident')
// t('Starter Issue')
// t('Bicycle')
// t('Door & Keys')
export enum IncidentType {
  START_ISSUE = "Starter Issue",
  ENGINE = "Engine",
  WHEEL_AND_TIRE = "Wheel & Tire",
  DOOR_AND_KEYS = "Door & Keys",
  FUEL = "Fuel",
  FLUID_LOSS = "Fluid Loss",
  ACCIDENT = "Accident",
  OTHER = "Other",
  BICYCLE = "Bicycle",
}
