import { Language } from "../model/Language";

export const languages: Language[] = [
  {
    title: "English",
    value: "en",
  },
  {
    title: "Deutsch",
    value: "de",
  },
  {
    title: "Français",
    value: "fr",
  },
  {
    title: "Italiano",
    value: "it",
  },
];
