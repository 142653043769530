import "./ImmobilizedLane.scss";

import { IonButton, IonContent, IonIcon, IonLabel } from "@ionic/react";
import { useStateMachine } from "little-state-machine";
import { useHistory } from "react-router";
import { ImmobilizationType } from "../../model/ImmobilizationType.enum";
import { RoutePath } from "../../model/RoutePath.enum";
import { updateSteps, updateSummary } from "../../state/updateState";
import { MainFooter, MainHeader } from "../Layout/Layout";
import { useTranslation } from "react-i18next";
import React from "react";

const ImmobilizedLane: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { state, actions } = useStateMachine({ updateSteps, updateSummary });
  const goToPage = (page: string, immobilizationType: ImmobilizationType) => {
    if (state.isInEditMode) {
      actions.updateSummary({ immobilizationType });

      if (state.summary.immobilizedOnHighway) {
        // if it's on the highway cannot be at home
        actions.updateSummary({ parkedAtHome: false });
        history.push(`${RoutePath.REQUEST}/${RoutePath.LOCATION}`);
      } else {
        history.push(`${RoutePath.REQUEST}/${RoutePath.PARKING_TYPE}`);
      }
    } else {
      actions.updateSteps({ immobilizationType });
      history.push(`${RoutePath.REQUEST}/${page}`);
    }
  };

  return (
    <IonContent>
      <MainHeader
        title={t("Which option matches your location most accurately?")}
        image={`${process.env.PUBLIC_URL}/assets/images/${
          process.env.REACT_APP_ALT_ICONS_PATH || ""
        }highway.svg`}
      >
        <section>
          <div className="button-grid">
            <IonButton
              id="immobilized-lane-active-lane"
              className="button-tcs"
              color="secondary"
              expand="block"
              type="button"
              onClick={() =>
                goToPage(
                  RoutePath.ACTIVE_LANE_WARNING,
                  ImmobilizationType.ACTIVE_LANE
                )
              }
            >
              {t("Active lane")}
            </IonButton>
            <IonButton
              id="immobilized-lane-emergency-lane"
              className="button-tcs"
              color="secondary"
              expand="block"
              type="button"
              onClick={() =>
                goToPage(
                  RoutePath.EMERGENCY_LANE_WARNING,
                  ImmobilizationType.EMERGENCY_LANE
                )
              }
            >
              {t("Emergency lane")}
            </IonButton>
            <IonButton
              id="immobilized-lane-rest-area"
              className="button-tcs"
              color="secondary"
              expand="block"
              type="button"
              onClick={() =>
                goToPage(RoutePath.INCIDENT_TYPE, ImmobilizationType.REST_AREA)
              }
            >
              {t("Rest area")}
            </IonButton>
          </div>
        </section>
      </MainHeader>
      <MainFooter>
        <IonButton
          id="immobilized-lane-back"
          className="button-tcs full-width back"
          expand="block"
          onClick={() => history.back()}
        >
          <div className="wrapper">
            <IonIcon
              size="large"
              src={`${process.env.PUBLIC_URL}/assets/images/chevron-back.svg`}
            />
            <IonLabel>{t("Back")}</IonLabel>
            <div />
          </div>
        </IonButton>
      </MainFooter>
    </IonContent>
  );
};

export default ImmobilizedLane;
