import { Case } from "../../api/models/Case";
import { IncidentUtils } from "../IncidentUtils";
import { PersonDetail } from "../../model/PersonDetail";
import { PersonalDetailsUtils } from "../PersonalDetailsUtils";
import { Appointment } from "../../model/Appointment";
import { AppointmentUtils } from "../AppointmentUtils";
import { LocationUtils } from "../LocationUtils";
import { ImmobilizationType } from "../../model/ImmobilizationType.enum";

export class AccidentUtils {
  public static serialize(
    personalDetail: PersonDetail,
    appointment: Appointment | undefined,
    immobilizedOnHighway: boolean | undefined,
    immobilizationType: ImmobilizationType
  ): Partial<Case> {
    const isAutoCoverageCheck =
      process.env.REACT_APP_USE_AUTO_COVERAGE_CHECK === "true";
    return {
      MainCause: "2",
      Comment: this.getCaseComment(
        personalDetail,
        immobilizedOnHighway,
        immobilizationType
      ),
      Priority: this.getPriority(appointment),
      ItemsUsedList: [
        {
          ...IncidentUtils.getItemType(),
          ...(isAutoCoverageCheck
            ? {
                LastVehicleStatus: {
                  PrimaryFault: this.getPrimaryFault(),
                },
              }
            : {}),
        },
      ],
    };
  }

  private static getCaseComment(
    data: PersonDetail,
    immobilizedOnHighway: boolean | undefined,
    immobilizationType: ImmobilizationType
  ): string {
    return [
      LocationUtils.getCaseComment(immobilizedOnHighway, immobilizationType),
      "Callback Unfall",
      PersonalDetailsUtils.serializeCaseComment(data),
      ...PersonalDetailsUtils.serializeCoverageComment(data),
    ]
      .filter(Boolean)
      .join("; ");
  }

  private static getPriority(appointment: Appointment | undefined) {
    const appointmentPriority = AppointmentUtils.getPriority(appointment);

    return appointmentPriority ? appointmentPriority : 1;
  }

  private static getPrimaryFault(): string {
    // code for "Accident guilt not clear"
    return "20002251";
  }
}
