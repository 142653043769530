// t('Coolant')
// t('Oil')
// t('Fuel')
// t('Other / Unknown')

export enum FluidLossFluidType {
  COOLANT = "Coolant",
  OIL = "Oil",
  FUEL = "Fuel",
  OTHER_UNKNOWN = "Other / Unknown",
}

export interface FluidLossDetails {
  fluidType: FluidLossFluidType | undefined;
  additionalInformation: string;
  hasSmell: boolean | undefined;
  wheelWidth: string;
  wheelAspectRadio: string;
  wheelRimDiameter: string;
}
