import "./FuelIncident.scss";

import {
  IonButton,
  IonContent,
  IonIcon,
  IonInput,
  IonLabel,
  IonTextarea,
  IonRadioGroup,
  IonItem,
  IonRadio,
} from "@ionic/react";
import { useStateMachine } from "little-state-machine";
import { useHistory } from "react-router";
import * as Yup from "yup";
import { FormikProvider, useFormik } from "formik";
import { FormValidation } from "../../../helpers/FormValidation";
import React, { useRef } from "react";
import { updateSteps } from "../../../state/updateState";
import { MainFooter, MainHeader } from "../../Layout/Layout";
import {
  FuelDetails,
  FuelIncidentType,
  FuelTypeOptions,
} from "../../../model/Incident/FuelDetails";
import { IncidentType } from "../../../model/IncidentType.enum";
import { useTranslation } from "react-i18next";
import { IncidentUtils } from "../../../helpers/IncidentUtils";
import { ScrollToError } from "../../shared/ScrollToError/scroll-to-error";

const incidentOptions = [
  {
    label: FuelIncidentType.INCORRECTLY_REFUELED,
    value: FuelIncidentType.INCORRECTLY_REFUELED,
  },
  {
    label: FuelIncidentType.EMPTY_TANK,
    value: FuelIncidentType.EMPTY_TANK,
  },
  /*{
    label: FuelIncidentType.FUEL_FILLER_BLOCKED,
    value: FuelIncidentType.FUEL_FILLER_BLOCKED,
  },*/
  {
    label: FuelIncidentType.FUEL_LOSS,
    value: FuelIncidentType.FUEL_LOSS,
  },
  {
    label: FuelIncidentType.FUEL_TANK_EV_CONNECTOR,
    value: FuelIncidentType.FUEL_TANK_EV_CONNECTOR,
  },
  {
    label: FuelIncidentType.OTHER,
    value: FuelIncidentType.OTHER,
  },
];

const fuelTypeOptions = [
  {
    label: FuelTypeOptions.UNLEADED_GAS,
    value: FuelTypeOptions.UNLEADED_GAS,
  },
  {
    label: FuelTypeOptions.DIESEL,
    value: FuelTypeOptions.DIESEL,
  },
  {
    label: FuelTypeOptions.ELECTRIC,
    value: FuelTypeOptions.ELECTRIC,
  },
  {
    label: FuelTypeOptions.OTHER,
    value: FuelTypeOptions.OTHER,
  },
];

const fuelTypeRefueledOptions = [
  {
    label: FuelTypeOptions.UNLEADED_GAS,
    value: FuelTypeOptions.UNLEADED_GAS,
  },
  {
    label: FuelTypeOptions.DIESEL,
    value: FuelTypeOptions.DIESEL,
  },
  {
    label: FuelTypeOptions.ADBLUE,
    value: FuelTypeOptions.ADBLUE,
  },
  {
    label: FuelTypeOptions.OTHER,
    value: FuelTypeOptions.OTHER,
  },
];

const FuelIncident: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { actions, state } = useStateMachine({ updateSteps });
  const textArea = useRef<HTMLIonTextareaElement>(null);

  const errMinLiters = t("Number of wheels must be greater than or equal to 1");
  const requiredBreakdownField = t("Breakdown type is a required field");
  const requiredLitersField = t("Liters is a required field");
  const requiredFuelTypeField = t("Fuel type is a required field");
  const requiredAdditionalField = t(
    "Additional information is a required field"
  );
  const radioStart =
    process.env.REACT_APP_RADIO_START &&
    process.env.REACT_APP_RADIO_START == "true"
      ? "start"
      : "end";

  const validationSchema = Yup.object({
    breakdownType: Yup.string()
      .required(requiredBreakdownField)
      .oneOf(
        Object.values(FuelIncidentType) as string[],
        requiredBreakdownField
      ),
    wrongFuelLiters: Yup.number().when("breakdownType", {
      is: FuelIncidentType.INCORRECTLY_REFUELED,
      then: Yup.number().required(requiredLitersField).min(1, errMinLiters),
    }),
    fuelType: Yup.string().required(requiredFuelTypeField),
    additionalInformation: Yup.string().when(["breakdownType", "fuelType"], {
      is: (breakdownType: string, fuelType: string) =>
        breakdownType === FuelIncidentType.OTHER ||
        fuelType === FuelTypeOptions.OTHER,
      then: Yup.string().required(requiredAdditionalField),
    }),
  });

  const getInitialValues = (): FuelDetails => {
    const initialValues =
      state.steps.incidentDetails &&
      state.steps.incidentType === IncidentType.FUEL
        ? state.steps.incidentDetails
        : {
            breakdownType: "",
            fuelType: "",
            wrongFuelLiters: "",
            additionalInformation: "",
          };
    return initialValues as FuelDetails;
  };

  const formik = useFormik({
    initialValues: getInitialValues(),
    validationSchema,
    onSubmit: (data: FuelDetails) => handleSubmit(data),
    validate: () => {
      const shouldScroll =
        formik.values.breakdownType &&
        formik.values.fuelType &&
        !formik.values.additionalInformation;
      if (shouldScroll && textArea && textArea.current) {
        textArea.current.scrollIntoView();
      }
    },
  });

  const handleSubmit = (fuelIncident: FuelDetails) => {
    actions.updateSteps({ incidentDetails: fuelIncident });
    IncidentUtils.goToNextRoute(
      state.isInEditMode,
      state.steps.immobilizedOnHighway,
      history,
      state.summary
    );
  };

  return (
    <IonContent>
      <FormikProvider value={formik}>
        <ScrollToError />
        <form className="fuel-issue-component" onSubmit={formik.handleSubmit}>
          <MainHeader
            imageTitle={t("Fuel")}
            image={`${process.env.PUBLIC_URL}/assets/images/${
              process.env.REACT_APP_ALT_ICONS_PATH || ""
            }${IncidentType.FUEL}.svg`}
          >
            <section>
              <h3>{t("What is the problem?")}</h3>
              <IonRadioGroup
                value={formik.values.breakdownType}
                name="breakdownType" // should have the same name as the form
                onIonChange={(e) => {
                  formik.setFieldValue("breakdownType", e.detail.value);
                }}
              >
                {incidentOptions.map(({ label, value }, i) => {
                  return (
                    <IonItem key={i}>
                      <span className={"tab capitalize"}>{t(label)}</span>
                      <IonRadio slot={radioStart} value={value} />
                    </IonItem>
                  );
                })}
              </IonRadioGroup>
              {FormValidation.getFormErrorMessage("breakdownType", formik, t)}
            </section>
            {formik.values.breakdownType ===
              FuelIncidentType.INCORRECTLY_REFUELED && (
              <section>
                <h3>{t("How much incorrect fuel did you put in the tank?")}</h3>
                <div className="wrong-fuel-litters">
                  <IonInput
                    className="fuel-input"
                    type="number"
                    min="1"
                    id="wrongFuelLiters"
                    name="wrongFuelLiters"
                    value={formik.values.wrongFuelLiters}
                    onIonChange={formik.handleChange}
                  />
                  <p>{t("Liters")}</p>
                </div>
                <br />
                {FormValidation.getFormErrorMessage(
                  "wrongFuelLiters",
                  formik,
                  t
                )}
              </section>
            )}

            {formik.values.breakdownType ===
              FuelIncidentType.INCORRECTLY_REFUELED && (
              <section>
                <h3>{t("What type was incorrectly refuelled?")}</h3>
                <IonRadioGroup
                  value={formik.values.fuelType}
                  name="fuelType" // should have the same name as the form
                  onIonChange={(e) => {
                    formik.setFieldValue("fuelType", e.detail.value);
                  }}
                >
                  {fuelTypeRefueledOptions.map(({ label, value }, i) => {
                    return (
                      <IonItem key={i}>
                        <span className={"tab capitalize"}>{t(label)}</span>
                        <IonRadio slot={radioStart} value={value} />
                      </IonItem>
                    );
                  })}
                </IonRadioGroup>
                {FormValidation.getFormErrorMessage("fuelType", formik, t)}
              </section>
            )}
            {formik.values.breakdownType !==
              FuelIncidentType.INCORRECTLY_REFUELED && (
              <section>
                <h3>{t("What fuel type does your vehicle use?")}</h3>
                <IonRadioGroup
                  value={formik.values.fuelType}
                  name="fuelType" // should have the same name as the form
                  onIonChange={(e) => {
                    formik.setFieldValue("fuelType", e.detail.value);
                  }}
                >
                  {fuelTypeOptions.map(({ label, value }, i) => {
                    return (
                      <IonItem key={i}>
                        <span className={"tab capitalize"}>{t(label)}</span>
                        <IonRadio slot={radioStart} value={value} />
                      </IonItem>
                    );
                  })}
                </IonRadioGroup>
                {FormValidation.getFormErrorMessage("fuelType", formik, t)}
              </section>
            )}

            <section>
              <h6>
                {t("Additional information")}
                {IncidentUtils.displayOptionalTag(
                  state.isInEditMode
                    ? state.summary.incidentType
                    : state.steps.incidentType,
                  formik.values.breakdownType,
                  t,
                  formik.values.fuelType
                )}
              </h6>
              <IonTextarea
                rows={5}
                ref={textArea}
                id="additionalInformation"
                name="additionalInformation"
                value={formik.values.additionalInformation}
                onIonChange={formik.handleChange}
              />
              {FormValidation.getFormErrorMessage(
                "additionalInformation",
                formik,
                t
              )}
            </section>
          </MainHeader>
          <MainFooter>
            {!state.isInEditMode && (
              <IonButton
                id="fuel-back"
                className="button-tcs back"
                expand="block"
                onClick={() => history.back()}
              >
                <div className="wrapper">
                  <IonIcon
                    size="large"
                    src={`${process.env.PUBLIC_URL}/assets/images/chevron-back.svg`}
                  />
                  <IonLabel>{t("Back")}</IonLabel>
                  <div />
                </div>
              </IonButton>
            )}
            {state.isInEditMode && (
              <IonButton
                id="fuel-cancel"
                className="button-tcs"
                expand="block"
                onClick={() => history.back()}
              >
                <IonLabel>{t("Cancel")}</IonLabel>
              </IonButton>
            )}
            {!state.isInEditMode && (
              <IonButton
                id="fuel-next"
                className="button-tcs cta"
                color="primary"
                expand="block"
                onClick={() => formik.handleSubmit()}
              >
                <div className="wrapper">
                  <div />
                  <IonLabel>{t("Next")}</IonLabel>
                  <IonIcon
                    slot="end"
                    size="large"
                    src={`${process.env.PUBLIC_URL}/assets/images/chevron-forward.svg`}
                  />
                </div>
              </IonButton>
            )}
            {state.isInEditMode && (
              <IonButton
                id="fuel-save"
                className="button-tcs cta"
                color="primary"
                expand="block"
                onClick={() => formik.handleSubmit()}
              >
                <IonLabel>{t("Save")}</IonLabel>
              </IonButton>
            )}
          </MainFooter>
        </form>
      </FormikProvider>
    </IonContent>
  );
};

export default FuelIncident;
