import React from "react";
import { Route } from "react-router-dom";
import { Redirect } from "react-router";
import { useStateMachine } from "little-state-machine";
import { GeneralUtils } from "../../../helpers/general-utils";

const ProtectedRoute = ({ children, ...rest }: any) => {
  const { state } = useStateMachine();
  return (
    <>
      <Route
        {...rest}
        render={({ location }) =>
          state.token && GeneralUtils.isTokenValid(state.token) ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/request/request-authentication",
                state: { from: location },
              }}
            />
          )
        }
      />
    </>
  );
};

export default ProtectedRoute;
