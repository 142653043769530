import "./OtherIncident.scss";
import {
  IonButton,
  IonContent,
  IonIcon,
  IonLabel,
  IonTextarea,
  isPlatform,
} from "@ionic/react";
import { useStateMachine } from "little-state-machine";
import { useHistory } from "react-router";
import * as Yup from "yup";
import { FormikProvider, useFormik } from "formik";
import { FormValidation } from "../../../helpers/FormValidation";
import React, { useEffect, useRef } from "react";
import { updateSteps } from "../../../state/updateState";
import { MainFooter, MainHeader } from "../../Layout/Layout";
import { OtherDetails } from "../../../model/Incident/OtherDetails";
import { IncidentType } from "../../../model/IncidentType.enum";
import { useTranslation } from "react-i18next";
import { IncidentUtils } from "../../../helpers/IncidentUtils";
import { ScrollToError } from "../../shared/ScrollToError/scroll-to-error";

const OtherIncident: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const textArea = useRef<HTMLIonTextareaElement>(null);
  const { actions, state } = useStateMachine({ updateSteps });
  const requiredMessage = t("Description is a required field");
  const validationSchema = Yup.object({
    description: Yup.string().required(requiredMessage),
  });

  const getInitialValues = (): OtherDetails => {
    const initialValues =
      state.steps.incidentDetails &&
      state.steps.incidentType === IncidentType.OTHER
        ? state.steps.incidentDetails
        : { description: "" };
    return initialValues as OtherDetails;
  };

  useEffect(() => {
    if (isPlatform("desktop")) {
      setTimeout(() => textArea?.current?.setFocus(), 250);
    }
  }, []);

  const formik = useFormik({
    initialValues: getInitialValues(),
    validationSchema,
    onSubmit: (data: OtherDetails) => handleSubmit(data),
  });

  const handleSubmit = (otherIncident: OtherDetails) => {
    actions.updateSteps({ incidentDetails: otherIncident });
    IncidentUtils.goToNextRoute(
      state.isInEditMode,
      state.steps.immobilizedOnHighway,
      history,
      state.summary
    );
  };

  return (
    <IonContent>
      <FormikProvider value={formik}>
        <ScrollToError />
        <form onSubmit={formik.handleSubmit}>
          <MainHeader
            imageTitle={t("Other Issue")}
            image={`${process.env.PUBLIC_URL}/assets/images/${
              process.env.REACT_APP_ALT_ICONS_PATH || ""
            }${IncidentType.OTHER}.svg`}
          >
            <section>
              <h3>
                {t("Please describe the type of issue you are encountering")}
              </h3>
              <IonTextarea
                autoGrow
                ref={textArea}
                id="description"
                name="description"
                rows={5}
                value={formik.values.description}
                onIonChange={formik.handleChange}
              />
              {FormValidation.getFormErrorMessage("description", formik, t)}
            </section>
          </MainHeader>
          <MainFooter>
            {!state.isInEditMode && (
              <IonButton
                id="other-back"
                className="button-tcs outline back"
                expand="block"
                onClick={() => history.back()}
              >
                <div className="wrapper">
                  <IonIcon
                    size="large"
                    src={`${process.env.PUBLIC_URL}/assets/images/chevron-back.svg`}
                  />
                  <IonLabel>{t("Back")}</IonLabel>
                  <div />
                </div>
              </IonButton>
            )}
            {state.isInEditMode && (
              <IonButton
                id="other-cancel"
                className="button-tcs"
                expand="block"
                onClick={() => history.back()}
              >
                <IonLabel>{t("Cancel")}</IonLabel>
              </IonButton>
            )}
            {!state.isInEditMode && (
              <IonButton
                id="other-next"
                className="button-tcs cta"
                color="primary"
                expand="block"
                onClick={() => formik.handleSubmit()}
              >
                <div className="wrapper">
                  <div />
                  <IonLabel>{t("Next")}</IonLabel>
                  <IonIcon
                    slot="end"
                    size="large"
                    src={`${process.env.PUBLIC_URL}/assets/images/chevron-forward.svg`}
                  />
                </div>
              </IonButton>
            )}
            {state.isInEditMode && (
              <IonButton
                id="other-save"
                className="button-tcs cta"
                color="primary"
                expand="block"
                onClick={() => formik.handleSubmit()}
              >
                <IonLabel>{t("Save")}</IonLabel>
              </IonButton>
            )}
          </MainFooter>
        </form>
      </FormikProvider>
    </IonContent>
  );
};

export default OtherIncident;
