// we should never add any route that has only 5 characters
export enum RoutePath {
  REQUEST = "request",
  PERSONAL_DETAILS = "personal-details",
  VEHICLE_DETAILS = "vehicle-details",
  IMMOBILIZED_LANE = "immobilized-lane",
  HIGHWAY_IMMOBILIZED = "highway-immobilized",
  ACTIVE_LANE_WARNING = "active-lane-warning",
  EMERGENCY_LANE_WARNING = "emergency-lane-warning",
  THANK_YOU = "thank-you",
  INCIDENT_TYPE = "incident-type",
  STARTER_ISSUE_INCIDENT = "starter-issue-incident",
  FUEL_INCIDENT = "fuel-incident",
  DOORS_AND_KEYS_INCIDENT = "doors-and-keys-incident",
  ENGINE_INCIDENT = "engine-incident",
  OTHER_INCIDENT = "other-incident",
  BICYCLE_INCIDENT = "bicycle-incident",
  WHEELS_AND_TIRES = "wheels-and-tires-incident",
  FLUID_LOSS_INCIDENT = "fluid-loss-incident",
  PARKING_TYPE = "parking-type",
  LOCATION = "location",
  LOCATION_TUTORIAL = "location-tutorial",
  SUMMARY = "summary",
  CONFIRMATION = "confirmation",
  SCHEDULE_OPTIONS = "schedule-options",
  SCHEDULE_SERVICE = "schedule-service",
  REQUEST_AUTHENTICATION = "request-authentication",
  VALIDATE_AUTHENTICATION = "validate-authentication",
  LOCATE_ME_NOW = "locate-me",
  CASE_ALREADY_SUBMITTED = "case-already-submitted",
  ABROAD_ADDITIONAL_INFOS = "abroad-additional-informations",
}
