import "./EngineIncident.scss";

import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonRadio,
  IonRadioGroup,
  IonTextarea,
} from "@ionic/react";
import { useStateMachine } from "little-state-machine";
import { useHistory } from "react-router";
import * as Yup from "yup";
import { FormikProvider, useFormik } from "formik";
import { FormValidation } from "../../../helpers/FormValidation";
import React, { useRef } from "react";
import { updateSteps } from "../../../state/updateState";
import { MainFooter, MainHeader } from "../../Layout/Layout";
import {
  EngineDetails,
  EngineIncidentOptions,
} from "../../../model/Incident/EngineDetails";
import { IncidentType } from "../../../model/IncidentType.enum";
import { useTranslation } from "react-i18next";
import { IncidentUtils } from "../../../helpers/IncidentUtils";
import { ScrollToError } from "../../shared/ScrollToError/scroll-to-error";

const incidentOptions = [
  {
    label: EngineIncidentOptions.LESS_POWER,
    value: EngineIncidentOptions.LESS_POWER,
  },
  {
    label: EngineIncidentOptions.MOTOR_OVERHEATED_SMOKE,
    value: EngineIncidentOptions.MOTOR_OVERHEATED_SMOKE,
  },
  {
    label: EngineIncidentOptions.UNUSUAL_NOISE,
    value: EngineIncidentOptions.UNUSUAL_NOISE,
  },
  {
    label: EngineIncidentOptions.WARNING_LIGHT,
    value: EngineIncidentOptions.WARNING_LIGHT,
  },
  {
    label: EngineIncidentOptions.OTHER,
    value: EngineIncidentOptions.OTHER,
  },
];

const EngineIncident: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { actions, state } = useStateMachine({ updateSteps });
  const textArea = useRef<HTMLIonTextareaElement>(null);
  const radioStart =
    process.env.REACT_APP_RADIO_START &&
    process.env.REACT_APP_RADIO_START == "true"
      ? "start"
      : "end";

  const requiredAdditionalField = t(
    "Additional information is a required field"
  );
  const requiredBreakdownField = t("Breakdown type is a required field");
  const validationSchema = Yup.object({
    breakdownType: Yup.string()
      .required(requiredBreakdownField)
      .oneOf(
        Object.values(EngineIncidentOptions) as string[],
        requiredBreakdownField
      ),
    additionalInformation: Yup.string().when("breakdownType", {
      is: (breakdownType: string) =>
        breakdownType === EngineIncidentOptions.OTHER,
      then: Yup.string().required(requiredAdditionalField),
    }),
  });

  const getInitialValues = (): EngineDetails => {
    const initialValues =
      state.steps.incidentDetails &&
      state.steps.incidentType === IncidentType.ENGINE
        ? state.steps.incidentDetails
        : {
            breakdownType: "",
            additionalInformation: "",
          };
    return initialValues as EngineDetails;
  };

  const formik = useFormik({
    initialValues: getInitialValues(),
    validationSchema,
    onSubmit: (data: EngineDetails) => handleSubmit(data),
    validate: () => {
      const shouldScroll =
        formik.values.breakdownType && !formik.values.additionalInformation;
      if (shouldScroll && textArea && textArea.current) {
        textArea.current.scrollIntoView();
      }
    },
  });

  const handleSubmit = (engineIncident: EngineDetails) => {
    actions.updateSteps({ incidentDetails: engineIncident });
    IncidentUtils.goToNextRoute(
      state.isInEditMode,
      state.steps.immobilizedOnHighway,
      history,
      state.summary
    );
  };

  return (
    <IonContent>
      <FormikProvider value={formik}>
        <ScrollToError />
        <form onSubmit={formik.handleSubmit}>
          <MainHeader
            imageTitle={t("Engine")}
            image={`${process.env.PUBLIC_URL}/assets/images/${
              process.env.REACT_APP_ALT_ICONS_PATH || ""
            }${IncidentType.ENGINE}.svg`}
          >
            <section>
              <h3>
                {t(
                  "Which of the following statements matches your issue the most?"
                )}
              </h3>
              <IonRadioGroup
                value={formik.values.breakdownType}
                name="breakdownType"
                onIonChange={(e) => {
                  formik.setFieldValue("breakdownType", e.detail.value);
                }}
              >
                {incidentOptions.map(({ label, value }, i) => {
                  return (
                    <IonItem key={i}>
                      <span className={"tab capitalize"}>{t(label)}</span>
                      <IonRadio slot={radioStart} value={value} />
                    </IonItem>
                  );
                })}
              </IonRadioGroup>
              {FormValidation.getFormErrorMessage("breakdownType", formik, t)}
            </section>
            <section>
              <h6>
                {t("Additional information")}
                {IncidentUtils.displayOptionalTag(
                  state.isInEditMode
                    ? state.summary.incidentType
                    : state.steps.incidentType,
                  formik.values.breakdownType,
                  t
                )}
              </h6>
              <IonTextarea
                ref={textArea}
                rows={5}
                id="additionalInformation"
                name="additionalInformation"
                value={formik.values.additionalInformation}
                onIonChange={formik.handleChange}
              />
              {FormValidation.getFormErrorMessage(
                "additionalInformation",
                formik,
                t
              )}
            </section>
          </MainHeader>
          <MainFooter>
            {!state.isInEditMode && (
              <IonButton
                id="engine-back"
                className="button-tcs back"
                expand="block"
                onClick={() => history.back()}
              >
                <div className="wrapper">
                  <IonIcon
                    size="large"
                    src={`${process.env.PUBLIC_URL}/assets/images/chevron-back.svg`}
                  />
                  <IonLabel>{t("Back")}</IonLabel>
                  <div />
                </div>
              </IonButton>
            )}
            {state.isInEditMode && (
              <IonButton
                id="engine-back-cancel"
                className="button-tcs"
                expand="block"
                onClick={() => history.back()}
              >
                <IonLabel>{t("Cancel")}</IonLabel>
              </IonButton>
            )}
            {!state.isInEditMode && (
              <IonButton
                id="engine-next"
                className="button-tcs cta"
                color="primary"
                expand="block"
                onClick={() => formik.handleSubmit()}
              >
                <div className="wrapper">
                  <div />
                  <IonLabel>{t("Next")}</IonLabel>
                  <IonIcon
                    slot="end"
                    size="large"
                    src={`${process.env.PUBLIC_URL}/assets/images/chevron-forward.svg`}
                  />
                </div>
              </IonButton>
            )}
            {state.isInEditMode && (
              <IonButton
                id="engine-save"
                className="button-tcs cta"
                color="primary"
                expand="block"
                onClick={() => formik.handleSubmit()}
              >
                <IonLabel>{t("Save")}</IonLabel>
              </IonButton>
            )}
          </MainFooter>
        </form>
      </FormikProvider>
    </IonContent>
  );
};

export default EngineIncident;
