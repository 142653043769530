import "./Layout.scss";
import classNames from "classnames/bind";
import React, {
  CSSProperties,
  JSXElementConstructor,
  ReactElement,
  ReactNodeArray,
  ReactPortal,
} from "react";
import { IonButton, IonIcon } from "@ionic/react";

interface MainHeaderProps {
  title?: string;
  subTitle?: string;
  titleStyle?: CSSProperties;
  imageTitle?: string;
  image?: string;
  icon?: string;
  onIconClick?: () => void;
  isBorderLess?: boolean;
  style?: CSSProperties;
}
export const MainHeader: React.FC<MainHeaderProps> = ({
  children,
  title,
  subTitle,
  titleStyle,
  imageTitle,
  image,
  icon,
  onIconClick,
  isBorderLess,
  style,
}) => {
  return (
    <div
      className={classNames("layout-header-component", {
        borderless: isBorderLess,
      })}
    >
      <div className={classNames("title-container")}>
        {title && !subTitle && (
          <h2 className="layout-title" style={titleStyle}>
            {title}
          </h2>
        )}
        {!title && subTitle && (
          <h3 className="layout-title" style={titleStyle}>
            {subTitle}
          </h3>
        )}
        {icon && (
          <div className="icon-container">
            <IonButton
              expand="block"
              color="secondary"
              shape="round"
              className="icon-action"
              onClick={onIconClick}
            >
              <IonIcon src={icon} />
            </IonButton>
          </div>
        )}
      </div>
      <div
        className={classNames({
          "layout-image-title-container": image && imageTitle,
          "layout-image-container": image && !imageTitle,
        })}
      >
        {image && (
          <div>
            <div
              className={classNames("layout-logo-wrapper", {
                "only-image": image && !imageTitle,
              })}
            >
              <img alt="img" className="layout-logo" src={image} />
            </div>
          </div>
        )}
        {imageTitle && <h2 className="layout-logo-title">{imageTitle}</h2>}
      </div>
      <div className="layout-grid">{children}</div>
    </div>
  );
};

interface MainFooterProps {
  children?:
    | ReactElement<any, string | JSXElementConstructor<any>>
    | string
    | number
    | {}
    | ReactNodeArray
    | ReactPortal
    | boolean
    | null
    | undefined;
  isSticky?: boolean;
  style?: CSSProperties;
}
export const MainFooter: React.FC<MainFooterProps> = ({
  children,
  isSticky,
  style,
}) => (
  <div
    style={style}
    className={classNames(
      "layout-footer-component",
      "layout-footer-actions",
      {
        "layout-grid": React.Children.toArray(children).length > 1,
        "layout-flex": React.Children.toArray(children).length === 1,
      },
      { stickyFooter: isSticky }
    )}
  >
    {children}
  </div>
);
