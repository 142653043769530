import "./Menu.scss";
import { menuController } from "@ionic/core";
import {
  IonButton,
  IonContent,
  IonIcon,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuButton,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import { languages } from "../../constants/Languages";
import { useLocation } from "react-router";

const Menu: React.FC = () => {
  const { i18n } = useTranslation();
  const { search } = useLocation();
  const changeLanguage = async (code: string) => {
    await i18n.changeLanguage(code);
    await menuController.toggle();
  };

  useEffect(() => {
    const params = new URLSearchParams(search);
    const lang = params.get("lang");
    if (lang && languages.some((l) => l.value === lang.trim().toLowerCase())) {
      i18n.changeLanguage(lang);
    }
  }, []);

  return (
    <IonMenu
      className={"menu-component"}
      side="end"
      contentId="main"
      type="overlay"
      swipeGesture={false}
    >
      <IonContent>
        <IonList id="languages-list">
          <IonListHeader color="secondary">
            <div className="header-container">
              <IonLabel className="abrev">
                {i18n.language.toUpperCase()}
              </IonLabel>
              <IonMenuButton className="action">
                <IonIcon
                  size="medium"
                  icon={`${process.env.PUBLIC_URL}/assets/images/chevron-forward.svg`}
                />
              </IonMenuButton>
            </div>
          </IonListHeader>
          {languages.map((language) => {
            return (
              <IonButton
                id={language.value}
                key={language.value}
                className={`button-tcs  ${
                  i18n.language === language.value
                    ? "menu-button-active"
                    : "menu-button"
                }`}
                expand="full"
                onClick={() => changeLanguage(language.value)}
              >
                <div className="content">
                  <IonLabel className="abrev">
                    {language.value.toUpperCase()}
                  </IonLabel>
                  <IonLabel className="title">{language.title}</IonLabel>
                  <div />
                </div>
              </IonButton>
            );
          })}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
