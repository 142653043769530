import {
  FluidLossDetails,
  FluidLossFluidType,
} from "../../model/Incident/FluidLossDetails";
import { IncidentUtils } from "../IncidentUtils";
import { Case } from "../../api/models/Case";
import { Appointment } from "../../model/Appointment";
import { AppointmentUtils } from "../AppointmentUtils";
import { PersonDetail } from "../../model/PersonDetail";
import { PersonalDetailsUtils } from "../PersonalDetailsUtils";
import { ImmobilizationType } from "../../model/ImmobilizationType.enum";
import { HighwayUtils } from "../HighwayUtils";
import { LocationUtils } from "../LocationUtils";

export class FluidLossUtils {
  public static serialize(
    data: FluidLossDetails,
    appointment: Appointment | undefined,
    personDetail: PersonDetail,
    immobilizationType: ImmobilizationType,
    immobilizedOnHighway: boolean | undefined
  ): Partial<Case> {
    return {
      MainCause: "12",
      Comment: this.getCaseComment(
        data,
        personDetail,
        immobilizedOnHighway,
        immobilizationType
      ),
      Priority: this.getPriority(
        immobilizationType,
        appointment,
        data.fluidType
      ),
      ItemsUsedList: [
        {
          ...IncidentUtils.getItemType(),
          LastVehicleStatus: {
            PrimaryFault: this.getPrimaryFault(),
            Comment: this.getTCSDescription(data),
          },
        },
      ],
    };
  }

  public static getSummary(data: FluidLossDetails): string {
    return data.fluidType ? data.fluidType : "";
  }

  private static getPrimaryFault(): string {
    return "65087";
  }

  private static getTCSDescription(data: FluidLossDetails): string {
    return [
      this.getCodeOfVehicleDescriptionByFluidType(data.fluidType),
      data.additionalInformation && data.additionalInformation !== ""
        ? IncidentUtils.formatAdditionalInformation(data.additionalInformation)
        : undefined,
    ]
      .filter(Boolean)
      .join("; ");
  }

  private static getCodeOfVehicleDescriptionByFluidType(
    fluidType: FluidLossFluidType | undefined
  ): string | undefined {
    switch (fluidType) {
      case FluidLossFluidType.OIL:
        return "Verliert Öl";
      case FluidLossFluidType.COOLANT:
        return "Verliert Wasser";
      case FluidLossFluidType.FUEL:
        return "Callback Treibstoffverlust";
      case FluidLossFluidType.OTHER_UNKNOWN:
        return "Flüssigkeitsverlust, unklar was";
      default:
        return undefined;
    }
  }

  private static getCaseComment(
    data: FluidLossDetails,
    personDetail: PersonDetail,
    immobilizedOnHighway: boolean | undefined,
    immobilizationType: ImmobilizationType
  ): string | undefined {
    const comments: string[] = [];

    if (data.fluidType && data.fluidType === FluidLossFluidType.FUEL) {
      comments.push("Callback Treibstoffverlust");
    }

    if (data.additionalInformation && data.additionalInformation !== "") {
      comments.push("S. Kommentar Pannenart");
    }

    return [
      LocationUtils.getCaseComment(immobilizedOnHighway, immobilizationType),
      ...comments,
      PersonalDetailsUtils.serializeCaseComment(personDetail),
      ...PersonalDetailsUtils.serializeCoverageComment(personDetail),
    ]
      .filter(Boolean)
      .join("; ");
  }

  private static getPriority(
    type: ImmobilizationType,
    appointment: Appointment | undefined,
    fluidType: FluidLossFluidType | undefined
  ): number {
    const appointmentPriority = AppointmentUtils.getPriority(appointment);
    let basePriority: number | undefined;

    switch (fluidType) {
      case FluidLossFluidType.FUEL:
        basePriority = appointmentPriority || 1;
        break;
      default:
        basePriority = appointmentPriority;
    }

    return HighwayUtils.serializePriority(type, basePriority);
  }
}
