import React, { FC } from "react";
import { AppStoreIcon } from "./AppStoreIcon";
import { AppStoreLink } from "../../model/AppStore";
import "./AppDownload.scss";
import { useTranslation } from "react-i18next";

interface AppDownloadProps {
  appIconDetails: AppStoreLink;
}

export const AppDownload: FC<AppDownloadProps> = ({ appIconDetails }) => {
  const { t } = useTranslation();
  const platformDetails = Object.keys(appIconDetails.brands).map(
    (brand) => appIconDetails.brands[brand]
  );

  return (
    <div className="warning-card">
      <div className="store-notes">
        <div className="title">{t("Note")}:</div>
        <div>
          {t(
            "You can also contact the breakdown and accident service via our app"
          )}
        </div>
      </div>
      <div className="store-icons">
        {platformDetails.map((app) => (
          <AppStoreIcon key={app.url} url={app.url} icon={app.icon} />
        ))}
      </div>
    </div>
  );
};
