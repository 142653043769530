import { Case } from "../../api/models/Case";
import { IncidentUtils } from "../IncidentUtils";
import { BicycleDetails } from "../../model/Incident/BicycleDetails";
import { Appointment } from "../../model/Appointment";
import { AppointmentUtils } from "../AppointmentUtils";
import { PersonalDetailsUtils } from "../PersonalDetailsUtils";
import { PersonDetail } from "../../model/PersonDetail";
import { HighwayUtils } from "../HighwayUtils";
import { ImmobilizationType } from "../../model/ImmobilizationType.enum";

export class BicycleUtils {
  public static serialize(
    data: BicycleDetails,
    appointment: Appointment | undefined,
    personDetail: PersonDetail,
    immobilizationType: ImmobilizationType
  ): Partial<Case> {
    return {
      MainCause: "12",
      Comment: this.getCaseComment(personDetail),
      Priority: HighwayUtils.serializePriority(
        immobilizationType,
        AppointmentUtils.getPriority(appointment)
      ),
      ItemsUsedList: [
        {
          ...IncidentUtils.getItemType(),
          Category: "Bike",
          Brand: "503",
          LastVehicleStatus: {
            Comment: data.description,
          },
        },
      ],
    };
  }

  public static getSummary(data: BicycleDetails): string {
    return data.description;
  }

  private static getCaseComment(data: PersonDetail): string {
    return [
      "S. Kommentar Pannenart",
      PersonalDetailsUtils.serializeCaseComment(data),
      ...PersonalDetailsUtils.serializeCoverageComment(data),
    ]
      .filter(Boolean)
      .join("; ");
  }
}
