import "./styles/theme.scss";
import "./styles/main.scss";

import { IonApp, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { createStore, StateMachineProvider } from "little-state-machine";
import { Redirect, Route } from "react-router-dom";

import Menu from "./components/Menu/Menu";
import Home from "./pages/Home";
import { initialState } from "./state/initialState";
import i18n from "./i18n/i18n.config";
import React, { Suspense, useEffect } from "react";
import { I18nextProvider } from "react-i18next";
import { RoutePath } from "./model/RoutePath.enum";
import { OnlineStatusProvider } from "./hooks/useOnlineStatus";
import { setupIonicReact } from "@ionic/react";

setupIonicReact({
  mode: "md",
  scrollAssist: false,
});

createStore(initialState);

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}
const App: React.FC = () => {
  return (
    <OnlineStatusProvider>
      <StateMachineProvider>
        <ScrollToTopOnMount />
        <Suspense fallback="loading">
          <I18nextProvider i18n={i18n}>
            <IonApp>
              <IonReactRouter>
                <Menu />
                <IonRouterOutlet id="main">
                  <Route path="/" exact={true}>
                    <Redirect
                      to={`/${RoutePath.REQUEST}/${RoutePath.REQUEST_AUTHENTICATION}`}
                    />
                  </Route>
                  <Route path={`/${RoutePath.REQUEST}`}>
                    <Home />
                  </Route>
                </IonRouterOutlet>
              </IonReactRouter>
            </IonApp>
          </I18nextProvider>
        </Suspense>
      </StateMachineProvider>
    </OnlineStatusProvider>
  );
};

export default App;
