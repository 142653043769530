import { Brand } from "../../model/VehicleDetail";

const carBrands: Brand[] = [
  {
    deleted: false,
    key: "552",
    values: {
      de: "Aiways",
      en: "Aiways",
      it: "Aiways",
      fr: "Aiways",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "1",
    values: {
      de: "Aixam",
      en: "Aixam",
      it: "Aixam",
      fr: "Aixam",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "2",
    values: {
      de: "Alfa Romeo",
      en: "Alfa Romeo",
      it: "Alfa Romeo",
      fr: "Alfa Romeo",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "4",
    values: {
      de: "American Motors",
      en: "American Motors",
      it: "American Motors",
      fr: "American Motors",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "5",
    values: {
      de: "Aprilia (Moto)",
      en: "Aprilia (Moto)",
      it: "Aprilia (Moto)",
      fr: "Aprilia (Moto)",
    },
    active: true,
    sortOrder: 0,
    additional: "315002",
  },
  {
    deleted: false,
    key: "6",
    values: {
      de: "Aston Martin",
      en: "Aston Martin",
      it: "Aston Martin",
      fr: "Aston Martin",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "7",
    values: {
      de: "Audi",
      en: "Audi",
      it: "Audi",
      fr: "Audi",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "8",
    values: {
      de: "Austin",
      en: "Austin",
      it: "Austin",
      fr: "Austin",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "9",
    values: {
      de: "Bedford",
      en: "Bedford",
      it: "Bedford",
      fr: "Bedford",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "10",
    values: {
      de: "Bentley",
      en: "Bentley",
      it: "Bentley",
      fr: "Bentley",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "11",
    values: {
      de: "Bertone",
      en: "Bertone",
      it: "Bertone",
      fr: "Bertone",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "12",
    values: {
      de: "BMW",
      en: "BMW",
      it: "BMW",
      fr: "BMW",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "13",
    values: {
      de: "BMW MOTORRAD",
      en: "BMW MOTORRAD",
      it: "BMW MOTORRAD",
      fr: "BMW MOTORRAD",
    },
    active: true,
    sortOrder: 0,
    additional: "315002",
  },
  {
    deleted: false,
    key: "283",
    values: {
      de: "Bucher",
      en: "Bucher",
      it: "Bucher",
      fr: "Bucher",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "14",
    values: {
      de: "Bugatti",
      en: "Bugatti",
      it: "Bugatti",
      fr: "Bugatti",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "15",
    values: {
      de: "Buick",
      en: "Buick",
      it: "Buick",
      fr: "Buick",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "238",
    values: {
      de: "Bürstner",
      en: "Bürstner",
      it: "Bürstner",
      fr: "Bürstner",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "563",
    values: {
      de: "BYD",
      en: "BYD",
      it: "BYD",
      fr: "BYD",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "16",
    values: {
      de: "Cadillac",
      en: "Cadillac",
      it: "Cadillac",
      fr: "Cadillac",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "17",
    values: {
      de: "Cagiva (Moto)",
      en: "Cagiva (Moto)",
      it: "Cagiva (Moto)",
      fr: "Cagiva (Moto)",
    },
    active: true,
    sortOrder: 0,
    additional: "315002",
  },
  {
    deleted: false,
    key: "18",
    values: {
      de: "Caterham",
      en: "Caterham",
      it: "Caterham",
      fr: "Caterham",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "150",
    values: {
      de: "Chevrolet",
      en: "Chevrolet",
      it: "Chevrolet",
      fr: "Chevrolet",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "20",
    values: {
      de: "Chrysler",
      en: "Chrysler",
      it: "Chrysler",
      fr: "Chrysler",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "21",
    values: {
      de: "Citroën",
      en: "Citroën",
      it: "Citroën",
      fr: "Citroën",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "223",
    values: {
      de: "Dacia",
      en: "Dacia",
      it: "Dacia",
      fr: "Dacia",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "22",
    values: {
      de: "Daewoo",
      en: "Daewoo",
      it: "Daewoo",
      fr: "Daewoo",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "23",
    values: {
      de: "Daihatsu",
      en: "Daihatsu",
      it: "Daihatsu",
      fr: "Daihatsu",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "24",
    values: {
      de: "Daimler",
      en: "Daimler",
      it: "Daimler",
      fr: "Daimler",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "26",
    values: {
      de: "De Tomaso",
      en: "De Tomaso",
      it: "De Tomaso",
      fr: "De Tomaso",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "27",
    values: {
      de: "Dodge",
      en: "Dodge",
      it: "Dodge",
      fr: "Dodge",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "28",
    values: {
      de: "Donkervoort",
      en: "Donkervoort",
      it: "Donkervoort",
      fr: "Donkervoort",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "565",
    values: {
      de: "Drohne",
      en: "Drohne",
      it: "Drohne",
      fr: "Drohne",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "379",
    values: {
      de: "DS Automobiles",
      en: "DS Automobiles",
      it: "DS Automobiles",
      fr: "DS Automobiles",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "29",
    values: {
      de: "Ducati (Moto)",
      en: "Ducati (Moto)",
      it: "Ducati (Moto)",
      fr: "Ducati (Moto)",
    },
    active: true,
    sortOrder: 0,
    additional: "315002",
  },
  {
    deleted: false,
    key: "31",
    values: {
      de: "Ferrari",
      en: "Ferrari",
      it: "Ferrari",
      fr: "Ferrari",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "32",
    values: {
      de: "Fiat",
      en: "Fiat",
      it: "Fiat",
      fr: "Fiat",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "33",
    values: {
      de: "Ford",
      en: "Ford",
      it: "Ford",
      fr: "Ford",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "556",
    values: {
      de: "GASGAS",
      en: "GASGAS",
      it: "GASGAS",
      fr: "GASGAS",
    },
    active: true,
    sortOrder: 0,
    additional: "315002",
  },
  {
    deleted: false,
    key: "555",
    values: {
      de: "Genesis",
      en: "Genesis",
      it: "Genesis",
      fr: "Genesis",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "318",
    values: {
      de: "Gilera (Moto)",
      en: "Gilera (Moto)",
      it: "Gilera (Moto)",
      fr: "Gilera (Moto)",
    },
    active: true,
    sortOrder: 0,
    additional: "315002",
  },
  {
    deleted: false,
    key: "34",
    values: {
      de: "GMC",
      en: "GMC",
      it: "GMC",
      fr: "GMC",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "288",
    values: {
      de: "Gottwald",
      en: "Gottwald",
      it: "Gottwald",
      fr: "Gottwald",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "35",
    values: {
      de: "Harley-Davidson (Moto)",
      en: "Harley-Davidson (Moto)",
      it: "Harley-Davidson (Moto)",
      fr: "Harley-Davidson (Moto)",
    },
    active: true,
    sortOrder: 0,
    additional: "315002",
  },
  {
    deleted: false,
    key: "36",
    values: {
      de: "Honda",
      en: "Honda",
      it: "Honda",
      fr: "Honda",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "37",
    values: {
      de: "Honda (Moto)",
      en: "Honda (Moto)",
      it: "Honda (Moto)",
      fr: "Honda (Moto)",
    },
    active: true,
    sortOrder: 0,
    additional: "315002",
  },
  {
    deleted: false,
    key: "178",
    values: {
      de: "Hummer",
      en: "Hummer",
      it: "Hummer",
      fr: "Hummer",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "512",
    values: {
      de: "Husqvarna (Moto)",
      en: "Husqvarna (Moto)",
      it: "Husqvarna (Moto)",
      fr: "Husqvarna (Moto)",
    },
    active: true,
    sortOrder: 0,
    additional: "315002",
  },
  {
    deleted: false,
    key: "39",
    values: {
      de: "Hyundai",
      en: "Hyundai",
      it: "Hyundai",
      fr: "Hyundai",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "263",
    values: {
      de: "Infiniti",
      en: "Infiniti",
      it: "Infiniti",
      fr: "Infiniti",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "41",
    values: {
      de: "Isuzu",
      en: "Isuzu",
      it: "Isuzu",
      fr: "Isuzu",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "42",
    values: {
      de: "Italjet (Moto)",
      en: "Italjet (Moto)",
      it: "Italjet (Moto)",
      fr: "Italjet (Moto)",
    },
    active: true,
    sortOrder: 0,
    additional: "315002",
  },
  {
    deleted: false,
    key: "43",
    values: {
      de: "Iveco",
      en: "Iveco",
      it: "Iveco",
      fr: "Iveco",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "44",
    values: {
      de: "Jaguar",
      en: "Jaguar",
      it: "Jaguar",
      fr: "Jaguar",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "45",
    values: {
      de: "Jeep",
      en: "Jeep",
      it: "Jeep",
      fr: "Jeep",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "46",
    values: {
      de: "Kawasaki (Moto)",
      en: "Kawasaki (Moto)",
      it: "Kawasaki (Moto)",
      fr: "Kawasaki (Moto)",
    },
    active: true,
    sortOrder: 0,
    additional: "315002",
  },
  {
    deleted: false,
    key: "48",
    values: {
      de: "Kia",
      en: "Kia",
      it: "Kia",
      fr: "Kia",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "218",
    values: {
      de: "Knaus",
      en: "Knaus",
      it: "Knaus",
      fr: "Knaus",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "49",
    values: {
      de: "KTM (Moto)",
      en: "KTM (Moto)",
      it: "KTM (Moto)",
      fr: "KTM (Moto)",
    },
    active: true,
    sortOrder: 0,
    additional: "315002",
  },
  {
    deleted: false,
    key: "193",
    values: {
      de: "Kymco (Moto)",
      en: "Kymco (Moto)",
      it: "Kymco (Moto)",
      fr: "Kymco (Moto)",
    },
    active: true,
    sortOrder: 0,
    additional: "315002",
  },
  {
    deleted: false,
    key: "50",
    values: {
      de: "Lada",
      en: "Lada",
      it: "Lada",
      fr: "Lada",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "51",
    values: {
      de: "Lamborghini",
      en: "Lamborghini",
      it: "Lamborghini",
      fr: "Lamborghini",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "52",
    values: {
      de: "Lancia",
      en: "Lancia",
      it: "Lancia",
      fr: "Lancia",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "53",
    values: {
      de: "Land-Rover",
      en: "Land-Rover",
      it: "Land-Rover",
      fr: "Land-Rover",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "55",
    values: {
      de: "Lexus",
      en: "Lexus",
      it: "Lexus",
      fr: "Lexus",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "57",
    values: {
      de: "Lotus",
      en: "Lotus",
      it: "Lotus",
      fr: "Lotus",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "566",
    values: {
      de: "Lucid",
      en: "Lucid",
      it: "Lucid",
      fr: "Lucid",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "557",
    values: {
      de: "Lynk and Co",
      en: "Lynk and Co",
      it: "Lynk and Co",
      fr: "Lynk and Co",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "248",
    values: {
      de: "Malaguti (Moto)",
      en: "Malaguti (Moto)",
      it: "Malaguti (Moto)",
      fr: "Malaguti (Moto)",
    },
    active: true,
    sortOrder: 0,
    additional: "315002",
  },
  {
    deleted: false,
    key: "58",
    values: {
      de: "Maserati",
      en: "Maserati",
      it: "Maserati",
      fr: "Maserati",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "59",
    values: {
      de: "Mazda",
      en: "Mazda",
      it: "Mazda",
      fr: "Mazda",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "208",
    values: {
      de: "MBK (Moto)",
      en: "MBK (Moto)",
      it: "MBK (Moto)",
      fr: "MBK (Moto)",
    },
    active: true,
    sortOrder: 0,
    additional: "315002",
  },
  {
    deleted: false,
    key: "513",
    values: {
      de: "McLaren",
      en: "McLaren",
      it: "McLaren",
      fr: "McLaren",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "60",
    values: {
      de: "Mega",
      en: "Mega",
      it: "Mega",
      fr: "Mega",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "61",
    values: {
      de: "Mer (Moto)",
      en: "Mer (Moto)",
      it: "Mer (Moto)",
      fr: "Mer (Moto)",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "62",
    values: {
      de: "MG",
      en: "MG",
      it: "MG",
      fr: "MG",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "63",
    values: {
      de: "MINI",
      en: "MINI",
      it: "MINI",
      fr: "MINI",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "64",
    values: {
      de: "Mitsubishi",
      en: "Mitsubishi",
      it: "Mitsubishi",
      fr: "Mitsubishi",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "516",
    values: {
      de: "Mofas",
      en: "Mofas",
      it: "Mofas",
      fr: "Mofas",
    },
    active: true,
    sortOrder: 0,
    additional: "315002",
  },
  {
    deleted: false,
    key: "65",
    values: {
      de: "Morgan",
      en: "Morgan",
      it: "Morgan",
      fr: "Morgan",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "66",
    values: {
      de: "Moto Guzzi (Moto)",
      en: "Moto Guzzi (Moto)",
      it: "Moto Guzzi (Moto)",
      fr: "Moto Guzzi (Moto)",
    },
    active: true,
    sortOrder: 0,
    additional: "315002",
  },
  {
    deleted: false,
    key: "245",
    values: {
      de: "MV Agusta (Moto)",
      en: "MV Agusta (Moto)",
      it: "MV Agusta (Moto)",
      fr: "MV Agusta (Moto)",
    },
    active: true,
    sortOrder: 0,
    additional: "315002",
  },
  {
    deleted: false,
    key: "213",
    values: {
      de: "MZ (Moto)",
      en: "MZ (Moto)",
      it: "MZ (Moto)",
      fr: "MZ (Moto)",
    },
    active: true,
    sortOrder: 0,
    additional: "315002",
  },
  {
    deleted: false,
    key: "67",
    values: {
      de: "Nissan",
      en: "Nissan",
      it: "Nissan",
      fr: "Nissan",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "110",
    values: {
      de: "Norton (Moto)",
      en: "Norton (Moto)",
      it: "Norton (Moto)",
      fr: "Norton (Moto)",
    },
    active: true,
    sortOrder: 0,
    additional: "315002",
  },
  {
    deleted: false,
    key: "68",
    values: {
      de: "Oldsmobile",
      en: "Oldsmobile",
      it: "Oldsmobile",
      fr: "Oldsmobile",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "69",
    values: {
      de: "Opel",
      en: "Opel",
      it: "Opel",
      fr: "Opel",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "71",
    values: {
      de: "Peugeot",
      en: "Peugeot",
      it: "Peugeot",
      fr: "Peugeot",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "72",
    values: {
      de: "Peugeot (Moto)",
      en: "Peugeot (Moto)",
      it: "Peugeot (Moto)",
      fr: "Peugeot (Moto)",
    },
    active: true,
    sortOrder: 0,
    additional: "315002",
  },
  {
    deleted: false,
    key: "74",
    values: {
      de: "Piaggio",
      en: "Piaggio",
      it: "Piaggio",
      fr: "Piaggio",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "73",
    values: {
      de: "Piaggio (Moto)",
      en: "Piaggio (Moto)",
      it: "Piaggio (Moto)",
      fr: "Piaggio (Moto)",
    },
    active: true,
    sortOrder: 0,
    additional: "315002",
  },
  {
    deleted: false,
    key: "228",
    values: {
      de: "Plymouth",
      en: "Plymouth",
      it: "Plymouth",
      fr: "Plymouth",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "188",
    values: {
      de: "Polaris (Moto)",
      en: "Polaris (Moto)",
      it: "Polaris (Moto)",
      fr: "Polaris (Moto)",
    },
    active: true,
    sortOrder: 0,
    additional: "315002",
  },
  {
    deleted: false,
    key: "551",
    values: {
      de: "Polestar",
      en: "Polestar",
      it: "Polestar",
      fr: "Polestar",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "75",
    values: {
      de: "Pontiac",
      en: "Pontiac",
      it: "Pontiac",
      fr: "Pontiac",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "76",
    values: {
      de: "Porsche",
      en: "Porsche",
      it: "Porsche",
      fr: "Porsche",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "77",
    values: {
      de: "Proton",
      en: "Proton",
      it: "Proton",
      fr: "Proton",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "78",
    values: {
      de: "Puch",
      en: "Puch",
      it: "Puch",
      fr: "Puch",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "79",
    values: {
      de: "Puch (Moto)",
      en: "Puch (Moto)",
      it: "Puch (Moto)",
      fr: "Puch (Moto)",
    },
    active: true,
    sortOrder: 0,
    additional: "315002",
  },
  {
    deleted: false,
    key: "81",
    values: {
      de: "Reliant",
      en: "Reliant",
      it: "Reliant",
      fr: "Reliant",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "82",
    values: {
      de: "Renault",
      en: "Renault",
      it: "Renault",
      fr: "Renault",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "84",
    values: {
      de: "Rolls-Royce",
      en: "Rolls-Royce",
      it: "Rolls-Royce",
      fr: "Rolls-Royce",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "85",
    values: {
      de: "Rover",
      en: "Rover",
      it: "Rover",
      fr: "Rover",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "511",
    values: {
      de: "Royal-Enfield (Moto)",
      en: "Royal-Enfield (Moto)",
      it: "Royal-Enfield (Moto)",
      fr: "Royal-Enfield (Moto)",
    },
    active: true,
    sortOrder: 0,
    additional: "315002",
  },
  {
    deleted: false,
    key: "86",
    values: {
      de: "Saab",
      en: "Saab",
      it: "Saab",
      fr: "Saab",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "284",
    values: {
      de: "Saurer",
      en: "Saurer",
      it: "Saurer",
      fr: "Saurer",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "554",
    values: {
      de: "SAIC MG",
      en: "SAIC MG",
      it: "SAIC MG",
      fr: "SAIC MG",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "88",
    values: {
      de: "Seat",
      en: "Seat",
      it: "Seat",
      fr: "Seat",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "89",
    values: {
      de: "Skoda",
      en: "Skoda",
      it: "Skoda",
      fr: "Skoda",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "90",
    values: {
      de: "Smart",
      en: "Smart",
      it: "Smart",
      fr: "Smart",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "91",
    values: {
      de: "SsangYong",
      en: "SsangYong",
      it: "SsangYong",
      fr: "SsangYong",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "253",
    values: {
      de: "Steckmobil",
      en: "Steckmobil",
      it: "Steckmobil",
      fr: "Steckmobil",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "285",
    values: {
      de: "Steyr",
      en: "Steyr",
      it: "Steyr",
      fr: "Steyr",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "92",
    values: {
      de: "Subaru",
      en: "Subaru",
      it: "Subaru",
      fr: "Subaru",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "93",
    values: {
      de: "Suzuki",
      en: "Suzuki",
      it: "Suzuki",
      fr: "Suzuki",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "94",
    values: {
      de: "Suzuki (Moto)",
      en: "Suzuki (Moto)",
      it: "Suzuki (Moto)",
      fr: "Suzuki (Moto)",
    },
    active: true,
    sortOrder: 0,
    additional: "315002",
  },
  {
    deleted: false,
    key: "373",
    values: {
      de: "SYM (Moto)",
      en: "SYM (Moto)",
      it: "SYM (Moto)",
      fr: "SYM (Moto)",
    },
    active: true,
    sortOrder: 0,
    additional: "315002",
  },
  {
    deleted: false,
    key: "333",
    values: {
      de: "Tesla",
      en: "Tesla",
      it: "Tesla",
      fr: "Tesla",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "298",
    values: {
      de: "Think",
      en: "Think",
      it: "Think",
      fr: "Think",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "96",
    values: {
      de: "Toyota",
      en: "Toyota",
      it: "Toyota",
      fr: "Toyota",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "224",
    values: {
      de: "Triumph",
      en: "Triumph",
      it: "Triumph",
      fr: "Triumph",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "98",
    values: {
      de: "Triumph (Moto)",
      en: "Triumph (Moto)",
      it: "Triumph (Moto)",
      fr: "Triumph (Moto)",
    },
    active: true,
    sortOrder: 0,
    additional: "315002",
  },
  {
    deleted: false,
    key: "203",
    values: {
      de: "Unbekannt / Andere",
      en: "Unknown / Other",
      it: "Sconosciuto / Altro",
      fr: "Inconnu / Autre",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "101",
    values: {
      de: "Vauxhall",
      en: "Vauxhall",
      it: "Vauxhall",
      fr: "Vauxhall",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "174",
    values: {
      de: "Vermeiren",
      en: "Vermeiren",
      it: "Vermeiren",
      fr: "Vermeiren",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "104",
    values: {
      de: "Volvo",
      en: "Volvo",
      it: "Volvo",
      fr: "Volvo",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "559",
    values: {
      de: "VRBikes (Moto)",
      en: "VRBikes (Moto)",
      it: "VRBikes (Moto)",
      fr: "VRBikes (Moto)",
    },
    active: true,
    sortOrder: 0,
    additional: "315002",
  },
  {
    deleted: false,
    key: "105",
    values: {
      de: "VW",
      en: "VW",
      it: "VW",
      fr: "VW",
    },
    active: true,
    sortOrder: 0,
    additional: "315001",
  },
  {
    deleted: false,
    key: "106",
    values: {
      de: "Yamaha (Moto)",
      en: "Yamaha (Moto)",
      it: "Yamaha (Moto)",
      fr: "Yamaha (Moto)",
    },
    active: true,
    sortOrder: 0,
    additional: "315002",
  },
  {
    deleted: true,
    key: "381",
    values: {
      de: "Nissan Electric",
      en: "Nissan Electric",
      it: "Nissan Electric",
      fr: "Nissan Electric",
    },
    active: false,
    sortOrder: 0,
    additional: "315001",
  },
];

export default carBrands;
