import { IonButton, IonLabel } from "@ionic/react";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

interface VinModalProps {
  onClose: () => void;
}

const VinModal: FC<VinModalProps> = ({ onClose }) => {
  const { t } = useTranslation();
  return (
    <div className="vin-info-modal">
      <h2>{t("Vehicle identification number")}</h2>
      <div>{t("The 17-character vehicle identification number")}</div>
      <img src="" alt="" />
      <IonButton
        id="incident-picker-back"
        className="button-tcs full-width"
        expand="block"
        onClick={onClose}
      >
        <div className="wrapper">
          <IonLabel>{t("Close")}</IonLabel>
          <div />
        </div>
      </IonButton>
    </div>
  );
};

export default VinModal;
