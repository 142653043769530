export class DateUtils {
  public static readonly DATEFNS_DATE_TIME = "yyyy-MM-dd HH:mm:ss";
  public static readonly DATEFNS_DATE_TIME_ISO_8601 = "yyyy-MM-dd'T'HH:mm:ss";

  public static isValidDate(date: any): boolean {
    return (
      date &&
      Object.prototype.toString.call(date) === "[object Date]" &&
      !isNaN(date)
    );
  }
}
