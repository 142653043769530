import { Country } from "../../model/Country";

const countries: Country[] = [
  {
    country_it: "Svizzera",
    country_fr: "Suisse",
    country_de: "Schweiz",
    country_en: "Switzerland",
    police: "117",
  },
  {
    country_it: "Francia",
    country_fr: "France",
    country_de: "Frankreich",
    country_en: "France",
    police: "17",
  },
  {
    country_it: "Germania",
    country_fr: "Allemagne",
    country_de: "Deutschland",
    country_en: "Germany",
    police: "110",
  },
  {
    country_it: "Italia",
    country_fr: "Italie",
    country_de: "Italien",
    country_en: "Italy",
    police: "112",
  },
  {
    country_it: "Austria",
    country_fr: "Autriche",
    country_de: "Österreich",
    country_en: "Austria",
    police: "112",
  },
  {
    country_it: "Liechtenstein",
    country_fr: "Liechtenstein",
    country_de: "Liechtenstein",
    country_en: "Liechtenstein",
    police: "117",
  },
  {
    country_it: "Afghanistan",
    country_fr: "Afghanistan",
    country_de: "Afghanistan",
    country_en: "Afghanistan",
    police: "119",
  },
  {
    country_it: "Albania",
    country_fr: "Albanie",
    country_de: "Albanien",
    country_en: "Albania",
    police: "129",
  },
  {
    country_it: "Algeria",
    country_fr: "Algérie",
    country_de: "Algerien",
    country_en: "Algeria",
    police: "1548",
  },
  {
    country_it: "Samoa americane",
    country_fr: "Samoa américaines",
    country_de: "Amerikanisch-Samoa",
    country_en: "American Samoa",
    police: "911",
  },
  {
    country_it: "Andorra",
    country_fr: "Andorre",
    country_de: "Andorra",
    country_en: "Andorra",
    police: "110",
  },
  {
    country_it: "Angola",
    country_fr: "Angola",
    country_de: "Angola",
    country_en: "Angola",
    police: "113",
  },
  {
    country_it: "Anguilla",
    country_fr: "Anguilla",
    country_de: "Anguilla",
    country_en: "Anguilla",
    police: "911",
  },
  {
    country_it: "Antigua e Barbuda",
    country_fr: "Antigua et Barbuda",
    country_de: "Antigua und Barbuda",
    country_en: "Antigua and Barbuda",
    police: "911",
  },
  {
    country_it: "Argentina",
    country_fr: "Argentine",
    country_de: "Argentinien",
    country_en: "Argentina",
    police: "911",
  },
  {
    country_it: "Armenia",
    country_fr: "Arménie",
    country_de: "Armenien",
    country_en: "Armenia",
    police: "112",
  },
  {
    country_it: "Aruba",
    country_fr: "Aruba",
    country_de: "Aruba",
    country_en: "Aruba",
    police: "911",
  },
  {
    country_it: "Australia",
    country_fr: "Australie",
    country_de: "Australien",
    country_en: "Australia",
    police: "000",
  },
  {
    country_it: "Azerbaijan",
    country_fr: "Azerbaïdjan",
    country_de: "Aserbaidschan",
    country_en: "Azerbaijan",
    police: "102",
  },
  {
    country_it: "Bahamas",
    country_fr: "Bahamas",
    country_de: "Bahamas",
    country_en: "Bahamas",
    police: "911",
  },
  {
    country_it: "Bahrain",
    country_fr: "Bahreïn",
    country_de: "Bahrain",
    country_en: "Bahrain",
    police: "999",
  },
  {
    country_it: "Bangladesh",
    country_fr: "Bangladesh",
    country_de: "Bangladesch",
    country_en: "Bangladesh",
    police: "999",
  },
  {
    country_it: "Barbados",
    country_fr: "Barbade",
    country_de: "Barbados",
    country_en: "Barbados",
    police: "911",
  },
  {
    country_it: "Bielorussia",
    country_fr: "Bélarus",
    country_de: "Weißrussland",
    country_en: "Belarus",
    police: "102",
  },
  {
    country_it: "Belgio",
    country_fr: "Belgique",
    country_de: "Belgien",
    country_en: "Belgium",
    police: "101",
  },
  {
    country_it: "Belize",
    country_fr: "Belize",
    country_de: "Belize",
    country_en: "Belize",
    police: "911",
  },
  {
    country_it: "Benin",
    country_fr: "Bénin",
    country_de: "Benin",
    country_en: "Benin",
    police: "117",
  },
  {
    country_it: "Bermuda",
    country_fr: "Bermudes",
    country_de: "Bermuda",
    country_en: "Bermuda",
    police: "911",
  },
  {
    country_it: "Bhutan",
    country_fr: "Bhoutan",
    country_de: "Bhutan",
    country_en: "Bhutan",
    police: "113",
  },
  {
    country_it: "Bolivia",
    country_fr: "Bolivie",
    country_de: "Bolivien",
    country_en: "Bolivia",
    police: "911",
  },
  {
    country_it: "Bosnia ed Erzegovina",
    country_fr: "Bosnie-Herzégovine",
    country_de: "Bosnien und Herzegowina",
    country_en: "Bosnia and Herzegovina",
    police: "122",
  },
  {
    country_it: "Botswana",
    country_fr: "Botswana",
    country_de: "Botswana",
    country_en: "Botswana",
    police: "999",
  },
  {
    country_it: "Brasile",
    country_fr: "Brésil",
    country_de: "Brasilien",
    country_en: "Brazil",
    police: "190",
  },
  {
    country_it: "Isole Vergini Britanniche",
    country_fr: "British Virgin Islands",
    country_de: "Britische Jungferninseln",
    country_en: "British Virgin Islands",
    police: "911",
  },
  {
    country_it: "Brunei",
    country_fr: "Brunei",
    country_de: "Brunei",
    country_en: "Brunei",
    police: "993",
  },
  {
    country_it: "Bulgaria",
    country_fr: "Bulgarie",
    country_de: "Bulgarien",
    country_en: "Bulgaria",
    police: "112",
  },
  {
    country_it: "Burkina Faso",
    country_fr: "Burkina Faso",
    country_de: "Burkina Faso",
    country_en: "Burkina Faso",
    police: "17",
  },
  {
    country_it: "Burundi",
    country_fr: "Burundi",
    country_de: "Burundi",
    country_en: "Burundi",
    police: "117",
  },
  {
    country_it: "Cambogia",
    country_fr: "Cambodge",
    country_de: "Kambodscha",
    country_en: "Cambodia",
    police: "117",
  },
  {
    country_it: "Camerun",
    country_fr: "Cameroun",
    country_de: "Kamerun",
    country_en: "Cameroon",
    police: "117",
  },
  {
    country_it: "Canada",
    country_fr: "Canada",
    country_de: "Kanada",
    country_en: "Canada",
    police: "911",
  },
  {
    country_it: "Capo Verde",
    country_fr: "Cap-Vert",
    country_de: "Kap Verde",
    country_en: "Cape Verde",
    police: "132",
  },
  {
    country_it: "Isole Cayman",
    country_fr: "Caïmans (îles)",
    country_de: "Cayman-Inseln",
    country_en: "Cayman Islands",
    police: "911",
  },
  {
    country_it: "Repubblica Centrafricana",
    country_fr: "République centrafricaine",
    country_de: "Zentralafrikanische Republik",
    country_en: "Central African Republic",
    police: "117",
  },
  {
    country_it: "Ciad",
    country_fr: "Tchad",
    country_de: "Tschad",
    country_en: "Chad",
    police: "17",
  },
  {
    country_it: "Cile",
    country_fr: "Chili",
    country_de: "Chile",
    country_en: "Chile",
    police: "133",
  },
  {
    country_it: "Cina",
    country_fr: "Chine",
    country_de: "China",
    country_en: "China",
    police: "110",
  },
  {
    country_it: "Colombia",
    country_fr: "Colombie",
    country_de: "Kolumbien",
    country_en: "Colombia",
    police: "123",
  },
  {
    country_it: "Comore",
    country_fr: "Comores",
    country_de: "Komoren",
    country_en: "Comoros",
    police: "17",
  },
  {
    country_it: "Isole Cook",
    country_fr: "Cook (îles)",
    country_de: "Cook-Inseln",
    country_en: "Cook Islands",
    police: "999",
  },
  {
    country_it: "Costa Rica",
    country_fr: "Costa Rica",
    country_de: "Costa Rica",
    country_en: "Costa Rica",
    police: "911",
  },
  {
    country_it: "Croazia",
    country_fr: "Croatie",
    country_de: "Kroatien",
    country_en: "Croatia",
    police: "192",
  },
  {
    country_it: "Cuba",
    country_fr: "Cuba",
    country_de: "Kuba",
    country_en: "Cuba",
    police: "106",
  },
  {
    country_it: "Curacao",
    country_fr: "Curaçao",
    country_de: "Curaçao",
    country_en: "Curacao",
    police: "911",
  },
  {
    country_it: "Cipro",
    country_fr: "Chypre",
    country_de: "Zypern",
    country_en: "Cyprus",
    police: "112",
  },
  {
    country_it: "Repubblica Ceca",
    country_fr: "République Tchèque",
    country_de: "Tschechische Republik",
    country_en: "Czech Republic",
    police: "158",
  },
  {
    country_it: "Danimarca",
    country_fr: "Danemark",
    country_de: "Dänemark",
    country_en: "Denmark",
    police: "112",
  },
  {
    country_it: "Gibuti",
    country_fr: "Djibouti",
    country_de: "Dschibuti",
    country_en: "Djibouti",
    police: "17",
  },
  {
    country_it: "Dominica",
    country_fr: "Dominique",
    country_de: "Dominica",
    country_en: "Dominica",
    police: "999",
  },
  {
    country_it: "Repubblica Dominicana",
    country_fr: "République dominicaine",
    country_de: "Dominikanische Republik",
    country_en: "Dominican Republic",
    police: "911",
  },
  {
    country_it: "DR Congo",
    country_fr: "RD Congo",
    country_de: "DR Kongo",
    country_en: "DR Congo",
    police: "112",
  },
  {
    country_it: "Ecuador",
    country_fr: "Équateur",
    country_de: "Ecuador",
    country_en: "Ecuador",
    police: "911",
  },
  {
    country_it: "Egitto",
    country_fr: "Égypte",
    country_de: "Ägypten",
    country_en: "Egypt",
    police: "122",
  },
  {
    country_it: "El Salvador",
    country_fr: "El Salvador",
    country_de: "El Salvador",
    country_en: "El Salvador",
    police: "911",
  },
  {
    country_it: "Guinea Equatoriale",
    country_fr: "Guinée équatoriale",
    country_de: "Aquatorialguinea",
    country_en: "Equatorial Guinea",
    police: "114",
  },
  {
    country_it: "Eritrea",
    country_fr: "Érythrée",
    country_de: "Eritrea",
    country_en: "Eritrea",
    police: "113",
  },
  {
    country_it: "Estonia",
    country_fr: "Estonie",
    country_de: "Estland",
    country_en: "Estonia",
    police: "112",
  },
  {
    country_it: "Eswatini",
    country_fr: "Eswatini",
    country_de: "Eswatini",
    country_en: "Eswatini",
    police: "999",
  },
  {
    country_it: "Etiopia",
    country_fr: "Éthiopie",
    country_de: "Äthiopien",
    country_en: "Ethiopia",
    police: "911",
  },
  {
    country_it: "Isole Falkland",
    country_fr: "Falkland (îles)",
    country_de: "Falkland-Inseln",
    country_en: "Falkland Islands",
    police: "112",
  },
  {
    country_it: "Isole Faroe",
    country_fr: "Îles Féroé",
    country_de: "Färöer Inseln",
    country_en: "Faroe Islands",
    police: "112",
  },
  {
    country_it: "Fiji",
    country_fr: "Fidji",
    country_de: "Fidschi",
    country_en: "Fiji",
    police: "911",
  },
  {
    country_it: "Finlandia",
    country_fr: "Finlande",
    country_de: "Finnland",
    country_en: "Finland",
    police: "112",
  },
  {
    country_it: "Guiana Francese",
    country_fr: "Guyane française",
    country_de: "Französisch-Guayana",
    country_en: "French Guiana",
    police: "112",
  },
  {
    country_it: "Polinesia Francese",
    country_fr: "Polynésie française",
    country_de: "Französisch-Polynesien",
    country_en: "French Polynesia",
    police: "112",
  },
  {
    country_it: "Gabon",
    country_fr: "Gabon",
    country_de: "Gabun",
    country_en: "Gabon",
    police: "1730",
  },
  {
    country_it: "Gambia",
    country_fr: "Gambie",
    country_de: "Gambia",
    country_en: "Gambia",
    police: "117",
  },
  {
    country_it: "Georgia",
    country_fr: "Géorgie",
    country_de: "Georgien",
    country_en: "Georgia",
    police: "112",
  },
  {
    country_it: "Ghana",
    country_fr: "Ghana",
    country_de: "Ghana",
    country_en: "Ghana",
    police: "191",
  },
  {
    country_it: "Gibilterra",
    country_fr: "Gibraltar",
    country_de: "Gibraltar",
    country_en: "Gibraltar",
    police: "199",
  },
  {
    country_it: "Grecia",
    country_fr: "Grèce",
    country_de: "Griechenland",
    country_en: "Greece",
    police: "100",
  },
  {
    country_it: "Groenlandia",
    country_fr: "Groenland",
    country_de: "Grönland",
    country_en: "Greenland",
    police: "112",
  },
  {
    country_it: "Grenada",
    country_fr: "Grenade",
    country_de: "Grenada",
    country_en: "Grenada",
    police: "911",
  },
  {
    country_it: "Guadalupa",
    country_fr: "Guadeloupe",
    country_de: "Guadeloupe",
    country_en: "Guadeloupe",
    police: "112",
  },
  {
    country_it: "Guam",
    country_fr: "Guam",
    country_de: "Guam",
    country_en: "Guam",
    police: "911",
  },
  {
    country_it: "Guatemala",
    country_fr: "Guatemala",
    country_de: "Guatemala",
    country_en: "Guatemala",
    police: "110",
  },
  {
    country_it: "Guinea",
    country_fr: "Guinée",
    country_de: "Guinea",
    country_en: "Guinea",
    police: "117",
  },
  {
    country_it: "Guinea-Bissau",
    country_fr: "Guinée-Bissau",
    country_de: "Guinea-Bissau",
    country_en: "Guinea-Bissau",
    police: "117",
  },
  {
    country_it: "Guyana",
    country_fr: "Guyana",
    country_de: "Guyana",
    country_en: "Guyana",
    police: "999",
  },
  {
    country_it: "Haiti",
    country_fr: "Haïti",
    country_de: "Haiti",
    country_en: "Haiti",
    police: "114",
  },
  {
    country_it: "Honduras",
    country_fr: "Honduras",
    country_de: "Honduras",
    country_en: "Honduras",
    police: "911",
  },
  {
    country_it: "Hong Kong",
    country_fr: "Hong Kong",
    country_de: "Hongkong",
    country_en: "Hong Kong",
    police: "999",
  },
  {
    country_it: "Ungheria",
    country_fr: "Hongrie",
    country_de: "Ungarn",
    country_en: "Hungary",
    police: "107",
  },
  {
    country_it: "Islanda",
    country_fr: "Islande",
    country_de: "Island",
    country_en: "Iceland",
    police: "112",
  },
  {
    country_it: "India",
    country_fr: "Inde",
    country_de: "Indien",
    country_en: "India",
    police: "112",
  },
  {
    country_it: "Indonesia",
    country_fr: "Indonésie",
    country_de: "Indonesien",
    country_en: "Indonesia",
    police: "112",
  },
  {
    country_it: "Iran",
    country_fr: "Iran",
    country_de: "Iran",
    country_en: "Iran",
    police: "110",
  },
  {
    country_it: "Iraq",
    country_fr: "Irak",
    country_de: "Irak",
    country_en: "Iraq",
    police: "112",
  },
  {
    country_it: "Irlanda",
    country_fr: "Irlande",
    country_de: "Irland",
    country_en: "Ireland",
    police: "999",
  },
  {
    country_it: "Isola di Man",
    country_fr: "Ile de Man",
    country_de: "Isle of Man",
    country_en: "Isle of Man",
    police: "112",
  },
  {
    country_it: "Israele",
    country_fr: "Israël",
    country_de: "Israel",
    country_en: "Israel",
    police: "100",
  },
  {
    country_it: "Costa d'Avorio",
    country_fr: "Côte d'Ivoire",
    country_de: "Elfenbeinküste",
    country_en: "Ivory Coast",
    police: "110",
  },
  {
    country_it: "Giamaica",
    country_fr: "Jamaïque",
    country_de: "Jamaika",
    country_en: "Jamaica",
    police: "119",
  },
  {
    country_it: "Giappone",
    country_fr: "Japon",
    country_de: "Japan",
    country_en: "Japan",
    police: "110",
  },
  {
    country_it: "Giordania",
    country_fr: "Jordanie",
    country_de: "Jordanien",
    country_en: "Jordan",
    police: "911",
  },
  {
    country_it: "Kazakistan",
    country_fr: "Kazakhstan",
    country_de: "Kasachstan",
    country_en: "Kazakhstan",
    police: "112",
  },
  {
    country_it: "Kenya",
    country_fr: "Kenya",
    country_de: "Kenia",
    country_en: "Kenya",
    police: "112",
  },
  {
    country_it: "Kiribati",
    country_fr: "Kiribati",
    country_de: "Kiribati",
    country_en: "Kiribati",
    police: "999",
  },
  {
    country_it: "Kuwait",
    country_fr: "Koweït",
    country_de: "Kuwait",
    country_en: "Kuwait",
    police: "112",
  },
  {
    country_it: "Kirghizistan",
    country_fr: "Kirghizistan",
    country_de: "Kirgisistan",
    country_en: "Kyrgyzstan",
    police: "102",
  },
  {
    country_it: "Laos",
    country_fr: "Laos",
    country_de: "Laos",
    country_en: "Laos",
    police: "191",
  },
  {
    country_it: "Lettonia",
    country_fr: "Lettonie",
    country_de: "Lettland",
    country_en: "Latvia",
    police: "112",
  },
  {
    country_it: "Libano",
    country_fr: "Liban",
    country_de: "Libanon",
    country_en: "Lebanon",
    police: "999",
  },
  {
    country_it: "Lesotho",
    country_fr: "Lesotho",
    country_de: "Lesotho",
    country_en: "Lesotho",
    police: "123",
  },
  {
    country_it: "Liberia",
    country_fr: "Liberia",
    country_de: "Liberia",
    country_en: "Liberia",
    police: "911",
  },
  {
    country_it: "Libia",
    country_fr: "Libye",
    country_de: "Libyen",
    country_en: "Libya",
    police: "1515",
  },
  {
    country_it: "Lituania",
    country_fr: "Lituanie",
    country_de: "Litauen",
    country_en: "Lithuania",
    police: "112",
  },
  {
    country_it: "Lussemburgo",
    country_fr: "Luxembourg",
    country_de: "Luxemburg",
    country_en: "Luxembourg",
    police: "112",
  },
  {
    country_it: "Macao",
    country_fr: "Macao",
    country_de: "Macau",
    country_en: "Macau",
    police: "999",
  },
  {
    country_it: "Madagascar",
    country_fr: "Madagascar",
    country_de: "Madagaskar",
    country_en: "Madagascar",
    police: "117",
  },
  {
    country_it: "Malawi",
    country_fr: "Malawi",
    country_de: "Malawi",
    country_en: "Malawi",
    police: "997",
  },
  {
    country_it: "Malesia",
    country_fr: "Malaisie",
    country_de: "Malaysia",
    country_en: "Malaysia",
    police: "999",
  },
  {
    country_it: "Maldive",
    country_fr: "Maldives",
    country_de: "Malediven",
    country_en: "Maldives",
    police: "119",
  },
  {
    country_it: "Mali",
    country_fr: "Mali",
    country_de: "Mali",
    country_en: "Mali",
    police: "17",
  },
  {
    country_it: "Malta",
    country_fr: "Malte",
    country_de: "Malta",
    country_en: "Malta",
    police: "112",
  },
  {
    country_it: "Isole Marshall",
    country_fr: "Marshall (Îles)",
    country_de: "Marshall-Inseln",
    country_en: "Marshall Islands",
    police: "911",
  },
  {
    country_it: "Martinica",
    country_fr: "Martinique",
    country_de: "Martinique",
    country_en: "Martinique",
    police: "112",
  },
  {
    country_it: "Mauritania",
    country_fr: "Mauritanie",
    country_de: "Mauretanien",
    country_en: "Mauritania",
    police: "117",
  },
  {
    country_it: "Mauritius",
    country_fr: "Maurice",
    country_de: "Mauretanien",
    country_en: "Mauritius",
    police: "112",
  },
  {
    country_it: "Mayotte",
    country_fr: "Mayotte",
    country_de: "Mayotte",
    country_en: "Mayotte",
    police: "112",
  },
  {
    country_it: "Messico",
    country_fr: "Mexique",
    country_de: "Mexiko",
    country_en: "Mexico",
    police: "911",
  },
  {
    country_it: "Micronesia",
    country_fr: "Micronésie",
    country_de: "Mikronesien",
    country_en: "Micronesia",
    police: "911",
  },
  {
    country_it: "Moldova",
    country_fr: "Moldavie",
    country_de: "Moldawien",
    country_en: "Moldova",
    police: "112",
  },
  {
    country_it: "Monaco",
    country_fr: "Monaco",
    country_de: "Monaco",
    country_en: "Monaco",
    police: "17",
  },
  {
    country_it: "Mongolia",
    country_fr: "Mongolie",
    country_de: "Mongolei",
    country_en: "Mongolia",
    police: "105",
  },
  {
    country_it: "Montenegro",
    country_fr: "Monténégro",
    country_de: "Montenegro",
    country_en: "Montenegro",
    police: "122",
  },
  {
    country_it: "Montserrat",
    country_fr: "Montserrat",
    country_de: "Montserrat",
    country_en: "Montserrat",
    police: "911",
  },
  {
    country_it: "Marocco",
    country_fr: "Maroc",
    country_de: "Marokko",
    country_en: "Morocco",
    police: "19",
  },
  {
    country_it: "Mozambico",
    country_fr: "Mozambique",
    country_de: "Mosambik",
    country_en: "Mozambique",
    police: "119",
  },
  {
    country_it: "Myanmar",
    country_fr: "Myanmar",
    country_de: "Myanmar",
    country_en: "Myanmar",
    police: "999",
  },
  {
    country_it: "Namibia",
    country_fr: "Namibie",
    country_de: "Namibia",
    country_en: "Namibia",
    police: "10 111",
  },
  {
    country_it: "Nauru",
    country_fr: "Nauru",
    country_de: "Nauru",
    country_en: "Nauru",
    police: "110",
  },
  {
    country_it: "Nepal",
    country_fr: "Népal",
    country_de: "Nepal",
    country_en: "Nepal",
    police: "100",
  },
  {
    country_it: "Paesi Bassi",
    country_fr: "Pays-Bas",
    country_de: "Niederlande",
    country_en: "Netherlands",
    police: "112",
  },
  {
    country_it: "Nuova Caledonia",
    country_fr: "Nouvelle-Calédonie",
    country_de: "Neukaledonien",
    country_en: "New Caledonia",
    police: "112",
  },
  {
    country_it: "Nuova Zelanda",
    country_fr: "Nouvelle-Zélande",
    country_de: "Neuseeland",
    country_en: "New Zealand",
    police: "111",
  },
  {
    country_it: "Nicaragua",
    country_fr: "Nicaragua",
    country_de: "Nicaragua",
    country_en: "Nicaragua",
    police: "118",
  },
  {
    country_it: "Niger",
    country_fr: "Niger",
    country_de: "Niger",
    country_en: "Niger",
    police: "17",
  },
  {
    country_it: "Nigeria",
    country_fr: "Nigeria",
    country_de: "Nigeria",
    country_en: "Nigeria",
    police: "112",
  },
  {
    country_it: "Corea del Nord",
    country_fr: "Corée du Nord",
    country_de: "Nordkorea",
    country_en: "North Korea",
    police: "110",
  },
  {
    country_it: "Norvegia",
    country_fr: "Norvège",
    country_de: "Norwegen",
    country_en: "Norway",
    police: "112",
  },
  {
    country_it: "Oman",
    country_fr: "Oman",
    country_de: "Oman",
    country_en: "Oman",
    police: "9999",
  },
  {
    country_it: "Pakistan",
    country_fr: "Pakistan",
    country_de: "Pakistan",
    country_en: "Pakistan",
    police: "15",
  },
  {
    country_it: "Palau",
    country_fr: "Palau",
    country_de: "Palau",
    country_en: "Palau",
    police: "911",
  },
  {
    country_it: "Panama",
    country_fr: "Panama",
    country_de: "Panama",
    country_en: "Panama",
    police: "911",
  },
  {
    country_it: "Papua Nuova Guinea",
    country_fr: "Papouasie-Nouvelle-Guinée",
    country_de: "Papua-Neuguinea",
    country_en: "Papua New Guinea",
    police: "112",
  },
  {
    country_it: "Paraguay",
    country_fr: "Paraguay",
    country_de: "Paraguay",
    country_en: "Paraguay",
    police: "911",
  },
  {
    country_it: "Perù",
    country_fr: "Pérou",
    country_de: "Peru",
    country_en: "Peru",
    police: "911",
  },
  {
    country_it: "Filippine",
    country_fr: "Philippines",
    country_de: "Philippinen",
    country_en: "Philippines",
    police: "911",
  },
  {
    country_it: "Polonia",
    country_fr: "Pologne",
    country_de: "Polen",
    country_en: "Poland",
    police: "997",
  },
  {
    country_it: "Portogallo",
    country_fr: "Portugal",
    country_de: "Portugal",
    country_en: "Portugal",
    police: "112",
  },
  {
    country_it: "Puerto Rico",
    country_fr: "Puerto Rico",
    country_de: "Puerto Rico",
    country_en: "Puerto Rico",
    police: "911",
  },
  {
    country_it: "Qatar",
    country_fr: "Qatar",
    country_de: "Katar",
    country_en: "Qatar",
    police: "999",
  },
  {
    country_it: "Repubblica del Congo",
    country_fr: "République du Congo",
    country_de: "Republik Kongo",
    country_en: "Republic of the Congo",
    police: "117",
  },
  {
    country_it: "Reunion",
    country_fr: "Réunion",
    country_de: "Wiedervereinigung",
    country_en: "Reunion",
    police: "112",
  },
  {
    country_it: "Romania",
    country_fr: "Roumanie",
    country_de: "Rumänien",
    country_en: "Romania",
    police: "112",
  },
  {
    country_it: "Russia",
    country_fr: "Russie",
    country_de: "Russland",
    country_en: "Russia",
    police: "102",
  },
  {
    country_it: "Ruanda",
    country_fr: "Rwanda",
    country_de: "Ruanda",
    country_en: "Rwanda",
    police: "112",
  },
  {
    country_it: "Saint Kitts e Nevis",
    country_fr: "Saint-Kitts-et-Nevis",
    country_de: "St. Kitts und Nevis",
    country_en: "Saint Kitts and Nevis",
    police: "911",
  },
  {
    country_it: "Santa Lucia",
    country_fr: "Sainte-Lucie",
    country_de: "St. Lucia",
    country_en: "Saint Lucia",
    police: "911",
  },
  {
    country_it: "San Pietro e Miquelon",
    country_fr: "Saint Pierre et Miquelon",
    country_de: "St. Pierre und Miquelon",
    country_en: "Saint Pierre and Miquelon",
    police: "112",
  },
  {
    country_it: "Saint Vincent e Grenadine",
    country_fr: "Saint Vincent et les Grenadines",
    country_de: "St. Vincent und die Grenadinen",
    country_en: "Saint Vincent and the Grenadines",
    police: "911",
  },
  {
    country_it: "Samoa",
    country_fr: "Samoa",
    country_de: "Samoa",
    country_en: "Samoa",
    police: "999",
  },
  {
    country_it: "San Marino",
    country_fr: "Saint-Marin",
    country_de: "San Marino",
    country_en: "San Marino",
    police: "113",
  },
  {
    country_it: "Sao Tome e Principe",
    country_fr: "Sao Tomé et Principe",
    country_de: "Sao Tome und Principe",
    country_en: "Sao Tome and Principe",
    police: "112",
  },
  {
    country_it: "Arabia Saudita",
    country_fr: "Arabie Saoudite",
    country_de: "Saudi-Arabien",
    country_en: "Saudi Arabia",
    police: "911",
  },
  {
    country_it: "Senegal",
    country_fr: "Sénégal",
    country_de: "Senegal",
    country_en: "Senegal",
    police: "17",
  },
  {
    country_it: "Serbia",
    country_fr: "Serbie",
    country_de: "Serbien",
    country_en: "Serbia",
    police: "192",
  },
  {
    country_it: "Seychelles",
    country_fr: "Seychelles",
    country_de: "Seychellen",
    country_en: "Seychelles",
    police: "112",
  },
  {
    country_it: "Sierra Leone",
    country_fr: "Sierra Leone",
    country_de: "Sierra Leone",
    country_en: "Sierra Leone",
    police: "19",
  },
  {
    country_it: "Singapore",
    country_fr: "Singapour",
    country_de: "Singapur",
    country_en: "Singapore",
    police: "999",
  },
  {
    country_it: "Slovacchia",
    country_fr: "Slovaquie",
    country_de: "Slowakei",
    country_en: "Slovakia",
    police: "158",
  },
  {
    country_it: "Slovenia",
    country_fr: "Slovénie",
    country_de: "Slowenien",
    country_en: "Slovenia",
    police: "112",
  },
  {
    country_it: "Isole Salomone",
    country_fr: "Salomon (Îles)",
    country_de: "Salomonen",
    country_en: "Solomon Islands",
    police: "911",
  },
  {
    country_it: "Somalia",
    country_fr: "Somalie",
    country_de: "Somalia",
    country_en: "Somalia",
    police: "888",
  },
  {
    country_it: "Sudafrica",
    country_fr: "Afrique du Sud",
    country_de: "Süd-Afrika",
    country_en: "South Africa",
    police: "10 111",
  },
  {
    country_it: "Corea del Sud",
    country_fr: "Corée du Sud",
    country_de: "Südkorea",
    country_en: "South Korea",
    police: "112",
  },
  {
    country_it: "Sudan del Sud",
    country_fr: "Soudan du Sud",
    country_de: "Südsudan",
    country_en: "South Sudan",
    police: "999",
  },
  {
    country_it: "Spagna",
    country_fr: "Espagne",
    country_de: "Spanien",
    country_en: "Spain",
    police: "112",
  },
  {
    country_it: "Sri Lanka",
    country_fr: "Sri Lanka",
    country_de: "Sri Lanka",
    country_en: "Sri Lanka",
    police: "119",
  },
  {
    country_it: "Sudan",
    country_fr: "Soudan",
    country_de: "Sudan",
    country_en: "Sudan",
    police: "999",
  },
  {
    country_it: "Suriname",
    country_fr: "Suriname",
    country_de: "Surinam",
    country_en: "Suriname",
    police: "115",
  },
  {
    country_it: "Svezia",
    country_fr: "Suède",
    country_de: "Schweden",
    country_en: "Sweden",
    police: "112",
  },
  {
    country_it: "Siria",
    country_fr: "Syrie",
    country_de: "Syrien",
    country_en: "Syria",
    police: "112",
  },
  {
    country_it: "Taiwan",
    country_fr: "Taïwan",
    country_de: "Taiwan",
    country_en: "Taiwan",
    police: "110",
  },
  {
    country_it: "Tajikistan",
    country_fr: "Tadjikistan",
    country_de: "Tadschikistan",
    country_en: "Tajikistan",
    police: "112",
  },
  {
    country_it: "Tanzania",
    country_fr: "Tanzanie",
    country_de: "Tansania",
    country_en: "Tanzania",
    police: "112",
  },
  {
    country_it: "Thailandia",
    country_fr: "Thaïlande",
    country_de: "Thailand",
    country_en: "Thailand",
    police: "191",
  },
  {
    country_it: "Timor-Leste",
    country_fr: "Timor-Leste",
    country_de: "Timor-Leste",
    country_en: "Timor-Leste",
    police: "112",
  },
  {
    country_it: "Togo",
    country_fr: "Togo",
    country_de: "Togo",
    country_en: "Togo",
    police: "117",
  },
  {
    country_it: "Tonga",
    country_fr: "Tonga",
    country_de: "Tonga",
    country_en: "Tonga",
    police: "911",
  },
  {
    country_it: "Trinidad e Tobago",
    country_fr: "Trinité-et-Tobago",
    country_de: "Trinidad und Tobago",
    country_en: "Trinidad and Tobago",
    police: "999",
  },
  {
    country_it: "Tunisia",
    country_fr: "Tunisie",
    country_de: "Tunesien",
    country_en: "Tunisia",
    police: "197",
  },
  {
    country_it: "Turchia",
    country_fr: "Turquie",
    country_de: "Türkei",
    country_en: "Turkey",
    police: "112",
  },
  {
    country_it: "Turkmenistan",
    country_fr: "Turkménistan",
    country_de: "Turkmenistan",
    country_en: "Turkmenistan",
    police: "112",
  },
  {
    country_it: "Tuvalu",
    country_fr: "Tuvalu",
    country_de: "Tuvalu",
    country_en: "Tuvalu",
    police: "911",
  },
  {
    country_it: "Uganda",
    country_fr: "Ouganda",
    country_de: "Uganda",
    country_en: "Uganda",
    police: "112",
  },
  {
    country_it: "Emirati Arabi Uniti",
    country_fr: "Emirats Arabes Unis",
    country_de: "Vereinigte Arabische Emirate",
    country_en: "United Arab Emirates",
    police: "999",
  },
  {
    country_it: "Regno Unito",
    country_fr: "Royaume-Uni",
    country_de: "Vereinigtes Königreich",
    country_en: "United Kingdom",
    police: "999",
  },
  {
    country_it: "Stati Uniti d'America",
    country_fr: "États-Unis",
    country_de: "Vereinigte Staaten",
    country_en: "United States",
    police: "911",
  },
  {
    country_it: "Isole Vergini degli Stati Uniti",
    country_fr: "Îles Vierges des États-Unis",
    country_de: "Vereinigte Staaten Jungferninseln",
    country_en: "United States Virgin Islands",
    police: "911",
  },
  {
    country_it: "Uruguay",
    country_fr: "Uruguay",
    country_de: "Uruguay",
    country_en: "Uruguay",
    police: "911",
  },
  {
    country_it: "Uzbekistan",
    country_fr: "Ouzbékistan",
    country_de: "Usbekistan",
    country_en: "Uzbekistan",
    police: "102",
  },
  {
    country_it: "Vanuatu",
    country_fr: "Vanuatu",
    country_de: "Vanuatu",
    country_en: "Vanuatu",
    police: "112",
  },
  {
    country_it: "Città del Vaticano",
    country_fr: "Vatican City",
    country_de: "Vatikanstadt",
    country_en: "Vatican City",
    police: "112",
  },
  {
    country_it: "Venezuela",
    country_fr: "Venezuela",
    country_de: "Venezuela",
    country_en: "Venezuela",
    police: "171",
  },
  {
    country_it: "Vietnam",
    country_fr: "Vietnam",
    country_de: "Vietnam",
    country_en: "Vietnam",
    police: "113",
  },
  {
    country_it: "Sahara occidentale",
    country_fr: "Sahara occidental",
    country_de: "Westsahara",
    country_en: "Western Sahara",
    police: "150",
  },
  {
    country_it: "Yemen",
    country_fr: "Yémen",
    country_de: "Jemen",
    country_en: "Yemen",
    police: "194",
  },
  {
    country_it: "Zambia",
    country_fr: "Zambie",
    country_de: "Sambia",
    country_en: "Zambia",
    police: "999",
  },
  {
    country_it: "Zimbabwe",
    country_fr: "Zimbabwe",
    country_de: "Simbabwe",
    country_en: "Zimbabwe",
    police: "999",
  },
];

export default countries;
