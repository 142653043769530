export enum MofisColorsEnum {
  COLOR_01 = "Beige",
  COLOR_02 = "Brown",
  COLOR_03 = "Yellow",
  COLOR_04 = "Grey",
  COLOR_05 = "Green",
  COLOR_06 = "Red",
  COLOR_07 = "Purple",
  COLOR_08 = "Blue",
  COLOR_09 = "Orange",
  COLOR_10 = "White",
  COLOR_11 = "Black",
  COLOR_12 = "Multicoloured",
  COLOR_99 = "Unknown",
}
