import "./GeoLocation.scss";

import {
  IonAlert,
  IonButton,
  IonContent,
  IonIcon,
  IonInput,
  IonLabel,
  IonLoading,
  IonTextarea,
  IonToast,
  isPlatform,
} from "@ionic/react";
import { useHistory } from "react-router";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Geolocation } from "@ionic-native/geolocation";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { Libraries } from "@react-google-maps/api/dist/utils/make-load-script-url";
import { RoutePath } from "../../../model/RoutePath.enum";
import { useStateMachine } from "little-state-machine";
import {
  updateDisplayMode,
  updateHasSubmittedLocation,
  updateLocateOnLoad,
  updateSteps,
  updateTemporaryLocation,
  updateIsAbroadCase,
  forceUpdateSteps,
  updateLocationAdditionalInfo,
} from "../../../state/updateState";
import { FormikProvider, useFormik } from "formik";
import { FormValidation } from "../../../helpers/FormValidation";
import { MainFooter, MainHeader } from "../../Layout/Layout";
import { useTranslation } from "react-i18next";
import useOnlineStatus from "../../../hooks/useOnlineStatus";
import classNames from "classnames";
import { LocationUtils } from "../../../helpers/LocationUtils";
import { ScrollToError } from "../../shared/ScrollToError/scroll-to-error";
import { CountryUtils } from "../../../helpers/CountryUtils";

interface LocationError {
  showError: boolean;
  message?: string;
}

export interface LocationFields {
  refresh: boolean; // if true refresh the page (ios doest not load the map when backing from personal details
  address: string;
  additionalInformation?: string;
  location: { lat: number; lng: number } | undefined;
  googleResult: google.maps.GeocoderResult | undefined;
  placeName: string | undefined;
  autoComplete?: string;
  hasUpdatedAddress?: boolean;
}

const libraries: Libraries = ["places"];
const DEFAULT_ZOOM = 16;
const DEFAULT_LOCATION = {
  lat: 46.8258545,
  lng: 8.2956684,
};

const GeoLocation: React.FC = () => {
  const { t } = useTranslation();
  const { actions, state } = useStateMachine({
    forceUpdateSteps,
    updateSteps,
    updateDisplayMode,
    updateTemporaryLocation,
    updateLocateOnLoad,
    updateHasSubmittedLocation,
    updateIsAbroadCase,
    updateLocationAdditionalInfo,
  });
  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY
      ? process.env.REACT_APP_GOOGLE_API_KEY
      : "",
    libraries,
  });
  const isOnline = useOnlineStatus();
  const history = useHistory();
  const mapRef = useRef<any>();
  const [lastRequestId, setLastRequestId] = useState<NodeJS.Timeout | null>(
    null
  );
  const [lastInfoWindowTimeout, setLastInfoWindowTimeout] =
    useState<NodeJS.Timeout | null>(null);
  const locationInputRef = useRef<HTMLInputElement>(null);
  const addressOverlayRef = useRef<HTMLDivElement>(null);
  const additionInfoInputRef = useRef<HTMLIonTextareaElement>(null);
  const bottomMenu = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [showLocationDenied, setShowLocationDenied] = useState<boolean>(false);
  const [showAdditionalControls, setShowAdditionalControls] =
    useState<boolean>(false);
  const [
    hasChangePositionAfterDecliningGeolocation,
    setHasChangePositionAfterDecliningGeolocation,
  ] = useState<boolean | undefined>(undefined);
  const [addressWasEmpty, setAddressWasEmpty] = useState<boolean>(true);
  const [additionalInfosWasEmpty, setAdditionalInfosWasEmpty] =
    useState<boolean>(true);
  const [error, setError] = useState<LocationError>({ showError: false });
  const [map, setMap] = useState<google.maps.Map>();
  const [userMarker, setUserMarker] = useState<google.maps.Marker | null>(null);
  const [location, setMapLocation] =
    useState<google.maps.LatLngLiteral>(DEFAULT_LOCATION);
  const setLocation = (loc: google.maps.LatLngLiteral) => {
    setMapLocation(loc);
    formik.setFieldValue("location", loc);
  };
  const [infoWindow, setInfoWindow] = useState<google.maps.InfoWindow | null>(
    null
  );

  const onLoad = useCallback((map1) => {
    const bounds = new window.google.maps.LatLngBounds();
    map1.fitBounds(bounds);
    map1.setZoom(DEFAULT_ZOOM);
    setMap(map1);
  }, []);

  const updateViewportHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty("--viewport-height", `${window.innerHeight}px`);
  };

  window.addEventListener("resize", updateViewportHeight);
  updateViewportHeight();

  const onUnmount = useCallback(() => {
    setMap(undefined);
  }, []);

  const focusOn = () => {
    if (isPlatform("desktop")) {
      const isAddressEmpty =
        formik.values.address === "" || !formik.values.address;
      if (
        locationInputRef &&
        locationInputRef.current &&
        isAddressEmpty &&
        !state.temporaryLocation
      ) {
        setTimeout(() => locationInputRef.current!.focus(), 250);
      } else if (additionInfoInputRef && additionInfoInputRef.current) {
        setTimeout(() => additionInfoInputRef?.current?.setFocus(), 250);
      }
    }
  };
  useEffect(() => {
    focusOn();
  }, [isLoaded]);

  useEffect(() => {
    let timeoutid: NodeJS.Timeout;
    if (map && isOnline) {
      timeoutid = setTimeout(() => {
        setShowAdditionalControls(true);
        addLocateMeControl();
        setInfoWindow(currentGPSInfoWindow);
        if (state.locateOnLoad) {
          // If set to true always fetch the location
          actions.updateLocateOnLoad(false);
          getLocation(true, true);
        } else if (
          state.isInEditMode ||
          (state.steps.locationFields.location && !state.temporaryLocation)
        ) {
          // If is in edit mode or a location was already been searched without a temporary location(the state of the application hasn't been updated)
          getLocation(false, true);
        } else if (
          state.temporaryLocation &&
          state.temporaryLocation.location
        ) {
          // If exists a temporary location fill with the given data
          updateFormWithTemporaryLocation(state.temporaryLocation);
        } else {
          // Move to Switzerland
          moveMapToLocation(DEFAULT_LOCATION, 7);
        }
      }, 100);
    }
    return () => timeoutid && clearTimeout(timeoutid);
  }, [map, isOnline]);

  const currentGPSInfoWindow = () => {
    return new google.maps.InfoWindow({
      content: t("Current GPS Location"),
    });
  };

  const updateFormWithTemporaryLocation = (
    temporaryLocation: Partial<LocationFields>
  ) => {
    if (temporaryLocation.address) {
      formik.setFieldValue("address", temporaryLocation.address);
      updateLocationInputValue(temporaryLocation.address);
    }
    temporaryLocation.additionalInformation &&
      formik.setFieldValue(
        "additionalInformation",
        temporaryLocation.additionalInformation
      );
    getLocation(false, true, state.temporaryLocation);
  };

  useEffect(() => {
    if (isOnline && !state.displayMap) {
      // force the reload to display the map
      actions.updateDisplayMode(true);
      window.location.reload();
    }
  }, [isOnline]);

  useEffect(() => {
    if (state.steps.locationFields?.refresh) {
      actions.updateSteps({
        locationFields: { ...state.steps.locationFields, refresh: false },
      });
      window.location.reload(false);
    }
  }, []);

  const options: google.maps.MapOptions = {
    streetViewControl: false,
    fullscreenControl: false,
    gestureHandling: "cooperative",
  };

  const goToNextRoute = () => {
    if (state.isInEditMode) {
      if (
        CountryUtils.isInSwitzerlandOrLiechtenstein(
          state.temporaryLocation?.googleResult
        )
      ) {
        actions.updateIsAbroadCase(false);
        history.push(`${RoutePath.REQUEST}/${RoutePath.SUMMARY}`);
      } else {
        actions.updateIsAbroadCase(true);
        history.push(
          `${RoutePath.REQUEST}/${RoutePath.ABROAD_ADDITIONAL_INFOS}`,
          {
            from: RoutePath.LOCATION,
          }
        );
      }
    } else {
      if (state.temporaryLocation?.googleResult) {
        if (
          CountryUtils.isInSwitzerlandOrLiechtenstein(
            state.temporaryLocation?.googleResult
          )
        ) {
          actions.updateIsAbroadCase(false);
          history.push(`${RoutePath.REQUEST}/${RoutePath.PERSONAL_DETAILS}`, {
            from: RoutePath.LOCATION,
          });
        } else {
          actions.updateIsAbroadCase(true);
          history.push(
            `${RoutePath.REQUEST}/${RoutePath.ABROAD_ADDITIONAL_INFOS}`,
            {
              from: RoutePath.LOCATION,
            }
          );
        }
      } else {
        // No address set, only additional informations, so by default we consider it as a non abroad case
        actions.updateIsAbroadCase(false);
        history.push(`${RoutePath.REQUEST}/${RoutePath.PERSONAL_DETAILS}`, {
          from: RoutePath.LOCATION,
        });
      }
    }
  };

  const formik = useFormik({
    initialValues: state.isInEditMode
      ? state.summary.locationFields
      : state.steps.locationFields,
    validate: (data: LocationFields) => {
      let errors = {} as LocationFields;

      const isDrivingDirectionSummary =
        state.isInEditMode &&
        state.summary.immobilizedOnHighway &&
        !data.additionalInformation;

      const isDrivingDirectionSteps =
        !state.isInEditMode &&
        state.steps.immobilizedOnHighway &&
        !data.additionalInformation;

      if (isDrivingDirectionSummary || isDrivingDirectionSteps) {
        errors.additionalInformation = t("Driving direction is required.");
      }

      if (hasChangePositionAfterDecliningGeolocation === false) {
        setShowLocationDenied(true);
        errors.address = t("Please locate yourself.");
      }

      actions.updateTemporaryLocation({ ...data });

      if (addressWasEmpty && data.address) {
        setAddressWasEmpty(false);
      } else if (!addressWasEmpty && !data.address) {
        setAddressWasEmpty(true);
      }
      if (additionalInfosWasEmpty && data.additionalInformation) {
        setAdditionalInfosWasEmpty(false);
      } else if (!additionalInfosWasEmpty && !data.additionalInformation) {
        setAdditionalInfosWasEmpty(true);
      }

      if (
        isDrivingDirectionSteps &&
        additionInfoInputRef &&
        additionInfoInputRef.current
      ) {
        additionInfoInputRef.current.setFocus();
      }
      return errors;
    },
    onSubmit: (locationFields: LocationFields) => {
      if (
        !locationFields.autoComplete &&
        !locationFields.address &&
        addressWasEmpty &&
        !locationFields.location &&
        !locationFields.placeName &&
        !state.hasSubmittedLocation
      ) {
        if (!showAlert) {
          setShowAlert(true);
        }
        return;
      }

      actions.updateSteps({ locationFields });
      if (state.isInEditMode) {
        actions.forceUpdateSteps({ locationFields });
      }
      actions.updateTemporaryLocation(undefined);
      actions.updateHasSubmittedLocation(true);
      goToNextRoute();
    },
  });

  const getLocation = async (
    ignoreFetchedLocation: boolean = false,
    shouldReverseGeocoding: boolean = true,
    temporaryLocation?: Partial<LocationFields>
  ) => {
    try {
      let location;
      const isTemporaryLocationDefaultLocation =
        temporaryLocation?.location?.lat === DEFAULT_LOCATION.lat &&
        temporaryLocation?.location?.lng === DEFAULT_LOCATION.lng;

      // only consider restoring the temporary location if it's not the default location
      if (temporaryLocation && !isTemporaryLocationDefaultLocation) {
        location = temporaryLocation.location;
      } else {
        location =
          state.steps.locationFields && state.steps.locationFields.location
            ? state.steps.locationFields.location
            : undefined;
      }
      let isDefaultLocation = false;
      if (!location || ignoreFetchedLocation) {
        location = await getCurrentLocation();
        isDefaultLocation =
          DEFAULT_LOCATION.lat === location.lat &&
          DEFAULT_LOCATION.lng === location.lng;
      }
      setError({ showError: false });
      setLocation(location);
      if (map) {
        if (isDefaultLocation) {
          moveMapToLocation(location, 7);
          updateLocationInputValue("");
        } else {
          moveMapToLocation(location);
          if (shouldReverseGeocoding) {
            setLoading(true);
            reverseGeocoding(location as any);
          } else {
            updateLocationInputValue(state.steps.locationFields.address);
          }
        }
      }
      focusOn();
    } catch (e: any) {
      if (e.code === 1) {
        displayErrorMessage(
          t(
            "To allow fetching your location, please change the settings of your browser!"
          )
        );
        moveMapToLocation(DEFAULT_LOCATION, 7);
      }
      setLoading(false);
    }
  };

  const getCurrentLocation = async () => {
    try {
      setLoading(true);
      const currentPosition = await Geolocation.getCurrentPosition();
      let location;
      location = {
        lat: currentPosition.coords.latitude,
        lng: currentPosition.coords.longitude,
      };
      setLocation(location);
      displayUserCurrentPositionMarker(location);
      reverseGeocoding(location as any);
      bottomMenu.current?.scrollIntoView({ behavior: "smooth" });
      return location;
    } catch (e) {
      // User has denied geolocation prompt
      if (e.code == 1) {
        setHasChangePositionAfterDecliningGeolocation(false);
        setShowLocationDenied(true);
        setLoading(false);
      }
      return DEFAULT_LOCATION;
    }
  };

  const addLocateMeControl = () => {
    const locationButton = document.getElementById("locateMeControlButton");
    locationButton &&
      map &&
      map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
        locationButton
      );
  };

  const displayUserCurrentPositionMarker = (
    location: google.maps.LatLngLiteral
  ) => {
    if (!userMarker) {
      const userMarkerPosition = new google.maps.Marker({
        position: location,
        map,
        animation: google.maps.Animation.DROP,
        title: t("Current GPS Location"),
        icon: {
          url: "assets/images/blue-dot.svg",
          anchor: new google.maps.Point(location.lat / 3, location.lng * 2),
          size: new google.maps.Size(30, 30),
        },
      });
      setUserMarker(userMarkerPosition);
      infoWindow?.open(map, userMarker);
    } else {
      userMarker.setPosition(location);
      infoWindow?.open(map, userMarker);
    }
    if (lastInfoWindowTimeout !== null) {
      clearTimeout(lastInfoWindowTimeout);
    }

    actions.updateSteps({
      locationFields: {
        ...state.steps.locationFields,
        hasUpdatedAddress: false,
      },
    });
    setLastInfoWindowTimeout(
      setTimeout(() => {
        infoWindow?.close();
      }, 5000)
    );
    map?.panTo(location);
    map?.setZoom(DEFAULT_ZOOM);
  };

  const geocode = async (loc: google.maps.LatLng) => {
    try {
      const geocoder = new google.maps.Geocoder();
      const resp = await geocoder.geocode({ location: loc });
      const results = resp.results;
      if (results && results.length > 0) {
        let result;
        const isOnHighway =
          (!state.isInEditMode && state.steps.immobilizedOnHighway) ||
          (state.isInEditMode && state.summary.immobilizedOnHighway);
        if (isOnHighway) {
          result = results.find((gr) =>
            gr.types.some((type) => ["postal_code"].includes(type))
          );
          // fallback in case no address with the type postal code
          result = result
            ? result
            : results.find((gr) =>
                gr.types.some((type) =>
                  ["locality", "political"].includes(type)
                )
              );
        } else {
          // The first element returned by googles maps is the more precise.
          // Just remove the results that contain plus_code since contains
          // unreadable info for the user ( indicates an encoded location reference)
          result = results.find((value) => !value.types.includes("plus_code"));
        }

        if (result) {
          await formik.setFieldValue("address", result.formatted_address);
          await formik.setFieldValue("googleResult", result);

          actions.updateSteps({
            locationFields: {
              ...state.steps.locationFields,
              hasUpdatedAddress: true,
            },
          });
          updateLocationInputValue(result.formatted_address);
          setLoading(false);
        } else {
          setLoading(false);
          console.log("No results");
        }
      } else {
        setLoading(false);
        console.log("No results");
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };
  const reverseGeocoding = (loc: google.maps.LatLng) => {
    // Cancel the previous request
    if (lastRequestId !== null) {
      clearTimeout(lastRequestId);
    }

    actions.updateSteps({
      locationFields: {
        ...state.steps.locationFields,
        hasUpdatedAddress: false,
      },
    });
    setLastRequestId(setTimeout(() => geocode(loc), 1000));
  };

  const displayErrorMessage = (message: string) => {
    setError({ showError: true, message });
  };

  const moveMapToLocation = (
    position: google.maps.LatLngLiteral,
    zoom?: number
  ) => {
    if (map) {
      map.panTo(position);
      map.setZoom(zoom ? zoom : DEFAULT_ZOOM);
    }
  };

  const isInfoWindowOpen = (infoWindow: google.maps.InfoWindow) => {
    var map = infoWindow.get("map");
    return map !== null && typeof map !== "undefined";
  };

  const handleOnDragEnd = () => {
    if (map) {
      actions.updateLocationAdditionalInfo(undefined);
      setHasChangePositionAfterDecliningGeolocation(undefined);

      const position = {
        lat: mapRef.current.state.map.center.lat(),
        lng: mapRef.current.state.map.center.lng(),
      };
      const distance = LocationUtils.calculateDistanceFromCoordinates(
        position.lat,
        position.lng,
        DEFAULT_LOCATION.lat,
        DEFAULT_LOCATION.lng
      );

      const errorRadiusDistance = Number(
        process.env.REACT_APP_LOCATE_ME_ERROR_RADIUS_KM
      );

      if (!isNaN(errorRadiusDistance) && distance <= errorRadiusDistance) {
        setHasChangePositionAfterDecliningGeolocation(false);
        return;
      }
      if (
        position.lat.toFixed(7) != location.lat &&
        position.lng.toFixed(7) != location.lng
      ) {
        // To know if it's a real drag to update position, compare it to default one
        if (
          position.lat.toFixed(7) != DEFAULT_LOCATION.lat &&
          position.lng.toFixed(7) != DEFAULT_LOCATION.lng
        ) {
          setLocation(position);
          setLoading(true);
          reverseGeocoding(position as google.maps.LatLng);
        }
      }
    }
  };

  const updateLocationInputValue = (value: string) => {
    const locationInput = locationInputRef.current;
    if (
      state.locationAdditionalInfo &&
      addressOverlayRef.current?.innerHTML?.includes(
        state.locationAdditionalInfo
      )
    ) {
      return;
    }
    if (!addressOverlayRef.current?.innerHTML) {
      value = state.locationAdditionalInfo
        ? `${state.locationAdditionalInfo}, ${value}`
        : value;
    }
    if (locationInput) {
      locationInput.value = value;
    }
    const addressOverlay = addressOverlayRef.current;
    if (addressOverlay) {
      addressOverlay.innerHTML = value;
    }
  };

  const getPlaceholderAdditionalInformation = (): string => {
    return LocationUtils.showDrivingDirection(
      state.isInEditMode,
      state.summary.immobilizedOnHighway,
      state.steps.immobilizedOnHighway
    )
      ? t("Going to Geneva")
      : t("Near a red barn and a windmill");
  };

  return (
    <IonContent>
      <FormikProvider value={formik}>
        <ScrollToError />
        <form
          className="form geolocationComponent"
          onSubmit={(e) => e.preventDefault()}
        >
          <MainHeader isBorderLess={true}>
            <section>
              {!isLoaded && loadError && (
                <>
                  <div className="sections">
                    <IonLabel className="tcs-label autocomplete-label">
                      {t("Address")}
                    </IonLabel>
                    <IonInput
                      className={classNames({
                        "tcs-invalid": FormValidation.getFormErrorMessage(
                          "address",
                          formik,
                          t
                        ),
                      })}
                      id="address"
                      placeholder={t("Type your current address")}
                      name="address"
                      value={formik.values.address}
                    />
                    {FormValidation.getFormErrorMessage("address", formik, t)}
                  </div>
                  <div className="maps-container-fallback" />
                </>
              )}

              {isLoaded && !loadError && (
                <>
                  {state.steps.immobilizedOnHighway && (
                    <IonButton
                      id="locateMeControlButtonForHighway"
                      className={"mapAdditionalControlButton button-tcs"}
                      color="primary"
                      onClick={getCurrentLocation}
                    >
                      {t("Locate me now")}
                    </IonButton>
                  )}
                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                      display: "block",
                    }}
                  >
                    <img
                      alt="marker"
                      className="marker"
                      src={`${process.env.PUBLIC_URL}${process.env.REACT_APP_PIN_ICON}`}
                    />
                    <GoogleMap
                      ref={mapRef}
                      onLoad={onLoad}
                      zoom={DEFAULT_ZOOM}
                      onUnmount={onUnmount}
                      center={location}
                      options={options}
                      onDragEnd={handleOnDragEnd}
                      mapContainerClassName="maps-container"
                    >
                      <Marker
                        position={location}
                        icon={{
                          url: `${process.env.PUBLIC_URL}/assets/images/1px-marker.png`,
                          scaledSize: new window.google.maps.Size(25, 25),
                        }}
                      />
                    </GoogleMap>
                  </div>
                </>
              )}
            </section>

            {showAdditionalControls && (
              <IonButton
                id="locateMeControlButton"
                className={"mapAdditionalControlButton cta"}
                onClick={getCurrentLocation}
              >
                <img src="assets/images/locate-me.png" />
              </IonButton>
            )}
          </MainHeader>
          <div ref={bottomMenu}>
            <div className="layout-footer-component layout-footer-actions layout-grid">
              <section>
                <div ref={addressOverlayRef} id={"addressOverlay"}></div>
              </section>
              <section className="additional-information-bottom-section">
                <div>
                  <IonLabel className="tcs-label">
                    {LocationUtils.getLabelAdditionalInformation(
                      state.isInEditMode,
                      state.summary.immobilizedOnHighway,
                      state.steps.immobilizedOnHighway,
                      t
                    )}
                  </IonLabel>
                  <IonTextarea
                    ref={additionInfoInputRef}
                    id="additionalInformation"
                    name="additionalInformation"
                    placeholder={getPlaceholderAdditionalInformation()}
                    rows={1}
                    autoGrow
                    value={formik.values.additionalInformation}
                    onIonChange={formik.handleChange}
                    className={classNames({
                      "tcs-invalid": FormValidation.getFormErrorMessage(
                        "additionalInformation",
                        formik,
                        t
                      ),
                    })}
                  />
                  {FormValidation.getFormErrorMessage(
                    "additionalInformation",
                    formik,
                    t
                  )}
                </div>
              </section>
            </div>
            <MainFooter
              style={{
                position: "absolute",
                bottom: 0,
                left: "50%",
                transform: "translateX(-50%)",
              }}
            >
              <section className={"button-section"}>
                {!state.isInEditMode && (
                  <IonButton
                    id="geolocation-back"
                    className="button-tcs outline back"
                    expand="block"
                    onClick={() => history.back()}
                  >
                    <div className="wrapper">
                      <IonIcon
                        size="large"
                        src={`${process.env.PUBLIC_URL}/assets/images/chevron-back.svg`}
                      />
                      <IonLabel>{t("Back")}</IonLabel>
                      <div />
                    </div>
                  </IonButton>
                )}
                {state.isInEditMode && (
                  <IonButton
                    id="geolocation-cancel"
                    className="button-tcs"
                    expand="block"
                    onClick={() =>
                      history.push(`${RoutePath.REQUEST}/${RoutePath.SUMMARY}`)
                    }
                  >
                    <IonLabel>{t("Cancel")}</IonLabel>
                  </IonButton>
                )}
                {!state.isInEditMode && (
                  <IonButton
                    id="geolocation-next"
                    className="button-tcs cta"
                    color="primary"
                    expand="block"
                    onClick={() => formik.handleSubmit()}
                  >
                    <div className="wrapper">
                      <div />
                      <IonLabel>{t("Next")}</IonLabel>
                      <IonIcon
                        slot="end"
                        size="large"
                        src={`${process.env.PUBLIC_URL}/assets/images/chevron-forward.svg`}
                      />
                    </div>
                  </IonButton>
                )}
                {state.isInEditMode && (
                  <IonButton
                    id="geolocation-save"
                    className="button-tcs cta"
                    color="primary"
                    expand="block"
                    onClick={() => formik.handleSubmit()}
                  >
                    <IonLabel>{t("Save")}</IonLabel>
                  </IonButton>
                )}
              </section>
            </MainFooter>
          </div>
        </form>
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          header={t("Empty location warning header")}
          message={t("Empty location warning message")}
          buttons={[
            {
              text: t("Empty location warning button"),
              cssClass: "button-tcs customAlertYellowButton",
            },
          ]}
        />
        <IonAlert
          isOpen={showLocationDenied}
          onDidDismiss={() => {
            setShowLocationDenied(false);
          }}
          header={t("Unavailable automatic geolocation")}
          message={t(
            "Automatic retrieval of your current GPS position has been unactivated by your browser. Please check your browser settings to enable this feature."
          )}
          buttons={[
            {
              text: t("Continue"),
              cssClass: "button-tcs customAlertYellowButton",
            },
          ]}
        />
      </FormikProvider>
      <IonLoading
        isOpen={loading}
        onDidDismiss={() => setLoading(false)}
        message={t("Getting GeoLocation...")}
      />
      <IonToast
        position="top"
        isOpen={error.showError}
        onDidDismiss={() => setError({ message: "", showError: false })}
        message={error.message}
        duration={3000}
      />
    </IonContent>
  );
};

export default GeoLocation;
