import "./IssueCard.scss";
import classNames from "classnames/bind";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
} from "@ionic/react";
import { CardProps } from "./SummaryCardPropsModel";
import { useTranslation } from "react-i18next";
import React from "react";
import { IncidentType } from "../../../model/IncidentType.enum";

const IssueCard: React.FC<CardProps> = ({
  onClick,
  isEditing,
  incident,
  customHtml,
}) => {
  const { t } = useTranslation();

  return (
    <IonCard className={"summary-card"}>
      <IonCardHeader className="card-header">
        <IonCardTitle>{t("Issue")}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent class="card-content">
        <div
          className={classNames("summary-card", "mb-10", {
            editing: isEditing,
          })}
          onClick={() => isEditing && onClick(incident.incidentPickerRoute)}
        >
          <div className="edit-icon">
            <img
              alt="pencil"
              src={`${process.env.PUBLIC_URL}/assets/images/${
                process.env.REACT_APP_ALT_ICONS_PATH || ""
              }pencil.svg`}
            />
          </div>

          <div
            className={classNames("sub-card-container", "pd-5", {
              "row-min-height": isEditing,
            })}
          >
            <p className="incident-text-title">{t("Issue")}:</p>
            <div className="incident-issue-container">
              <div className="sub-card-incident-icon">
                <img alt="img" src={incident.image} />
              </div>
              <p className="incident-description-text">{t(incident.type)}</p>
            </div>
            {isEditing && <p />}
          </div>
        </div>
        {incident.type !== IncidentType.ACCIDENT && (
          <div
            className={classNames("summary-card", { editing: isEditing })}
            onClick={() => isEditing && onClick(incident.incidentRoute)}
          >
            <div className="edit-icon">
              <img
                alt="pencil"
                src={`${process.env.PUBLIC_URL}/assets/images/${
                  process.env.REACT_APP_ALT_ICONS_PATH || ""
                }pencil.svg`}
              />
            </div>

            {incident.problem && (
              <div
                className={classNames("sub-card-container", "pd-5", {
                  "row-min-height":
                    !customHtml && !incident.additionalInformation,
                })}
              >
                <p className="problem-text-title">{t("Problem")}:</p>
                <p className="problem-description-text">
                  {t(incident.problem)}
                </p>
                {isEditing && <p />}
              </div>
            )}

            {customHtml}

            {incident.additionalInformation && (
              <div className={classNames("sub-card-container", "pd-5")}>
                <p className="problem-text-title">
                  {t("Additional information")}:
                </p>
                <p className="problem-description-text">
                  {incident.additionalInformation}
                </p>
                {isEditing && <p />}
              </div>
            )}
          </div>
        )}
      </IonCardContent>
    </IonCard>
  );
};

export default IssueCard;
