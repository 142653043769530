import "./RequestAuthentication.scss";
import "react-intl-tel-input/dist/main.css";

import {
  IonButton,
  IonContent,
  IonIcon,
  IonInput,
  IonLabel,
  IonLoading,
  IonToast,
  isPlatform,
} from "@ionic/react";
import { useHistory, useLocation } from "react-router";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { MainFooter, MainHeader } from "../../Layout/Layout";
import { RoutePath } from "../../../model/RoutePath.enum";
import classNames from "classnames/bind";

import jwt_decode from "jwt-decode";
import { useStateMachine } from "little-state-machine";
import {
  updateGlobalState,
  updateIsFromIVR,
  updateOneTimePassword,
  updateSteps,
  updateToken,
  updateInsertedMobilePhoneNumber,
} from "../../../state/updateState";
import {
  OneTimePassword,
  OneTimePasswordParams,
  TokenResponse,
} from "../../../model/OneTimePassword/one-time-password-params";

/*import 'react-phone-number-input/style.css'
import PhoneInput, { isPossiblePhoneNumber, Value } from 'react-phone-number-input'
import flags from 'react-phone-number-input/flags'*/
import IntlTelInput, { CountryData } from "react-intl-tel-input";

import { GeneralUtils } from "../../../helpers/general-utils";
import { Api } from "../../../api/api";
import { initialState } from "../../../state/initialState";
import { languages } from "../../../constants/Languages";

import TagManager from "react-gtm-module";
import { fromUnixTime } from "date-fns";

import * as Yup from "yup";
import { FormikProvider, useFormik } from "formik";
import { FormValidation } from "../../../helpers/FormValidation";
import { isValidPhoneNumber } from "react-phone-number-input";
import ConsentManagement from "../../ConsentManagement/ConsentManagement";

export const reusableToken: string | undefined = process.env.REACT_APP_TOKEN;

export interface DecodeToken {
  iss?: string;
  aud?: string;
  exp?: number;
  iat?: number;
  phoneNumber?: string;
}

export interface AuthenticationFields {
  phoneNumber: string;
}

const RequestAuthentication: React.FC = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const { state, actions } = useStateMachine({
    updateGlobalState,
    updateSteps,
    updateToken,
    updateOneTimePassword,
    updateIsFromIVR,
    updateInsertedMobilePhoneNumber,
  });
  const [error, setError] = useState<string>();
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [isFullInit, setIsFullInit] = useState<boolean>();
  const [isValidInputPhoneNumber, setIsValidInputPhoneNumber] =
    useState<boolean>(false);
  const [isFromIVR, setIsFromIVR] = useState<boolean>(false);
  const { search } = useLocation();
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>("");
  const phoneInputRef = useRef<HTMLIonInputElement>(null);
  const intlTelInputRef = useRef<IntlTelInput>(null);

  const TOKEN_VALID = "ivr_valid_access_assistance_online";
  const TOKEN_INVALID = "ivr_invalid_access_assistance_online";
  const TOKEN_EXPIRED = "ivr_expired_access_assistance_online";

  useEffect(() => {
    // Reset state on first screen
    actions.updateGlobalState(initialState);
    if (!GeneralUtils.isTokenValid(state.token)) {
      actions.updateToken(undefined);
      actions.updateOneTimePassword(undefined);
    } else {
      history.push(`${RoutePath.REQUEST}/${RoutePath.HIGHWAY_IMMOBILIZED}`);
    }
    setIsFullInit(true);
  }, []);

  useEffect(() => {
    if (isPlatform("desktop")) {
      setTimeout(() => phoneInputRef?.current?.setFocus(), 250);
    }
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(search);
    if (params) {
      setJwtByParam(params);
      setLanguageByParam(params);
    }
  }, []);

  useEffect(() => {
    formik.setFieldValue("phoneNumber", phoneNumber);
  }, [phoneNumber]);

  // Added for the case when the app reloads and we need to focus on
  // the input tel field when we have a phone number already inserted
  useEffect(() => {
    if (state.insertedPhoneNumber?.phoneNumber) {
      intlTelInputRef.current?.tel?.focus();
    }
  }, [state.insertedPhoneNumber]);

  const setJwtByParam = (params: URLSearchParams) => {
    const jwt = params.get("jwt");
    let eventLabel = "";
    try {
      if (jwt) {
        const payload = jwt_decode(jwt) as DecodeToken;
        actions.updateToken({ access_token: jwt });
        const otp = {
          phoneNumber: payload?.phoneNumber || "",
          expirationDate:
            payload && payload.exp ? payload.exp.toLocaleString() : "",
        } as OneTimePassword;
        actions.updateOneTimePassword(otp);

        if (payload.exp) {
          eventLabel = GeneralUtils.isDateExpired(fromUnixTime(payload.exp))
            ? TOKEN_EXPIRED
            : TOKEN_VALID;
          if (eventLabel === TOKEN_VALID) {
            history.push(
              `${RoutePath.REQUEST}/${RoutePath.HIGHWAY_IMMOBILIZED}`
            );
          }
        } else {
          eventLabel = TOKEN_INVALID;
        }
      }
    } catch (error: unknown) {
      eventLabel = TOKEN_INVALID;
    }
    if (jwt) {
      actions.updateIsFromIVR(true);
      // Add custom datalayer event in case of connection with a jwt coming from an IVR
      const tagManagerArgs = {
        dataLayer: {
          event: "IVR Online Assistance Access",
          jwt_status_ivr_access: eventLabel,
        },
      };
      TagManager.dataLayer(tagManagerArgs);
    }
  };

  const setLanguageByParam = (params: URLSearchParams) => {
    const lang = params.get("lang");
    if (lang && languages.some((l) => l.value === lang.trim().toLowerCase())) {
      i18n.changeLanguage(lang);
    }
  };
  const requiredPattern = t(
    "Phone number might start with '+' and must not exceed 14 characters"
  );

  const displayErrorMessage = (err: string) => {
    setDisplayError(true);
    setError(err);
  };

  const formik = useFormik({
    initialValues: { phoneNumber: phoneNumber! },
    validate: (values) => {
      const errors = {};
      if (
        !formik.values.phoneNumber &&
        phoneInputRef &&
        phoneInputRef.current
      ) {
        phoneInputRef.current.setFocus();
      }
      if (values.phoneNumber && isValidInputPhoneNumber) {
        if (!isValidPhoneNumber(values.phoneNumber)) {
          (errors as any).phoneNumber = requiredPattern;
        }
      } else {
        (errors as any).phoneNumber = requiredPattern;
      }
      return errors;
    },
    onSubmit: (values: AuthenticationFields) => {
      sendOTP(values);
    },
  });

  const sendOTP = (values: AuthenticationFields) => {
    setLoading(true);
    const request = {
      language: i18n.language,
      phoneNumber: values.phoneNumber,
    } as OneTimePasswordParams;

    Api.sendPhoneNumber(request)
      .then((res) => {
        const otp = {
          ...res.data,
          phoneNumber: values.phoneNumber,
          hashCode: res.data.hashCode,
        } as OneTimePassword;
        actions.updateOneTimePassword(otp);
        setLoading(false);
        history.push(
          `${RoutePath.REQUEST}/${RoutePath.VALIDATE_AUTHENTICATION}`
        );
      })
      .catch((error) => {
        setLoading(false);
        if (error && error.response && error.response.data) {
          const message =
            error.response.data.httpMessage &&
            error.response.data.httpMessage !== ""
              ? error.response.data.httpMessage
              : t("Confirm your number and try submit again.");
          displayErrorMessage(message);
        }
      });
  };

  const handlePhoneNumberChange = (
    isValid: boolean,
    phoneNumber: string,
    selectedCountryData: CountryData,
    fullNumber: string
  ) => {
    actions.updateInsertedMobilePhoneNumber({
      phoneNumber: phoneNumber,
      countryData: selectedCountryData,
    });
    if (isValid) {
      fullNumber = fullNumber.replace(/\s/g, "");
      setPhoneNumber(fullNumber);
      formik.setFieldValue("phonenumber", fullNumber);
      formik.handleChange(fullNumber);
      setIsValidInputPhoneNumber(true);
    } else {
      if (fullNumber.slice(0, 2) === "00") {
        impactFormWithFormattedPhoneNumber("+");
      } else if (fullNumber.length > 25) {
        impactFormWithFormattedPhoneNumber(fullNumber.slice(0, 24));
      } else if (!hasOnlyDigitsAndPlus(phoneNumber)) {
        impactFormWithFormattedPhoneNumber(
          phoneNumber.replace(/[^\+?\d+$]/g, "")
        );
      }
      setIsValidInputPhoneNumber(false);
    }
  };

  const impactFormWithFormattedPhoneNumber = (phoneNumber: string) => {
    setPhoneNumber(phoneNumber);
    formik.setFieldValue("phonenumber", phoneNumber);
    formik.handleChange(phoneNumber);

    if (intlTelInputRef.current && intlTelInputRef.current.tel) {
      let intlTelInputValue = intlTelInputRef.current.tel;
      intlTelInputValue.value = phoneNumber;
      intlTelInputRef.current.setNumber(phoneNumber);
    }
  };

  const hasOnlyDigitsAndPlus = (phoneNumber: string) => {
    var regExp = /^\+?\d+$/g;
    return regExp.test(phoneNumber);
  };

  // Fake auth - only for local dev
  const byPassAuth = (): void => {
    const token: TokenResponse = {
      access_token: reusableToken!,
      expires_in: 1800,
      token_type: "Bearer",
    };
    const oneTimePassword = {
      hashCodes: ["asd"],
      expirationDate: "23-09-2029",
      phoneNumber: "+33612502829",
    } as OneTimePassword;
    actions.updateToken(token);
    actions.updateOneTimePassword(oneTimePassword);
    history.push(`${RoutePath.REQUEST}/${RoutePath.HIGHWAY_IMMOBILIZED}`);
  };
  return (
    <IonContent>
      <MainHeader
        title={t("Online Breakdown and Accident Report Form")}
        titleStyle={{ textAlign: "left" }}
      >
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <section className="request-authentication-container">
              <h6 className="information-txt">
                {t(
                  "To easily get roadside assistance for your vehicle in Switzerland, enter your mobile phone number in the field below and click on “Next”."
                )}
              </h6>

              <h6 className="information-txt">
                {t(
                  "A verification code will then be sent to your phone to proceed to the next step."
                )}
              </h6>

              <section>
                <h6>{t("Mobile")}</h6>
                <div
                  className={classNames(
                    {
                      "tcs-invalid": FormValidation.getFormErrorMessage(
                        "phoneNumber",
                        formik,
                        t
                      ),
                    },
                    "intlTelContainer"
                  )}
                >
                  <IntlTelInput
                    fieldName={"phonenumber"}
                    fieldId={"phonenumber"}
                    autoPlaceholder={true}
                    preferredCountries={["ch", "de", "fr", "it", "li"]}
                    defaultCountry={
                      state.insertedPhoneNumber?.countryData?.iso2 || "ch"
                    }
                    autoFocus={true}
                    format={true}
                    defaultValue={
                      phoneNumber! || state.insertedPhoneNumber?.phoneNumber
                    }
                    customPlaceholder={(placeholder) => {
                      return t("e.g.") + " " + placeholder;
                    }}
                    onPhoneNumberChange={handlePhoneNumberChange}
                    inputClassName={"intlTelInput"}
                    ref={intlTelInputRef}
                  />
                </div>
                {FormValidation.getFormErrorMessage("phoneNumber", formik, t)}
              </section>
              {GeneralUtils.isDev() && (
                <IonButton onClick={byPassAuth}>Bypass Auth</IonButton>
              )}
            </section>
          </form>
        </FormikProvider>
        {process.env.REACT_APP_DISPLAY_CONSENT_MANAGEMENT &&
          process.env.REACT_APP_DISPLAY_CONSENT_MANAGEMENT == "true" &&
          !state.isFromIVR && <ConsentManagement />}
      </MainHeader>
      <MainFooter>
        <IonButton
          id="ra-next"
          className="button-tcs cta"
          color="primary"
          expand="block"
          onClick={() => formik.handleSubmit()}
        >
          <div className="wrapper">
            <div />
            <IonLabel>{t("Next")}</IonLabel>
            <IonIcon
              slot="end"
              size="large"
              src={`${process.env.PUBLIC_URL}/assets/images/chevron-forward.svg`}
            />
          </div>
        </IonButton>
      </MainFooter>
      <IonLoading
        isOpen={loading}
        onDidDismiss={() => setLoading(false)}
        message={t("Sending SMS...")}
      />
      <IonToast
        position="top"
        isOpen={displayError}
        onDidDismiss={() => setDisplayError(false)}
        message={error}
        duration={3000}
      />
    </IonContent>
  );
};

export default RequestAuthentication;
