import "./ThankYou.scss";
import { useTranslation } from "react-i18next";
import React from "react";

const ThankYou: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="thank-you-component">
      <div className="icon-title">
        <img
          alt="logo"
          src={`${process.env.PUBLIC_URL}/assets/images/TCS.png`}
        />
        <h1 className="title">
          {t("Thank you for using TCS Online Assistance")}
        </h1>
      </div>
      <h1 className="sub-title">{t("TCS - always by your side.")}</h1>
    </div>
  );
};

export default ThankYou;
