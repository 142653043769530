import "./PersonalDetails.scss";

import * as Yup from "yup";
import {
  InputChangeEventDetail,
  IonButton,
  IonContent,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonRadio,
  IonRadioGroup,
  isPlatform,
} from "@ionic/react";
import { FormikProvider, useFormik } from "formik";
import { useHistory } from "react-router";
import { useStateMachine } from "little-state-machine";
import {
  updateHasSubmittedLocation,
  updateIsAbroadCase,
  updateSteps,
  updateTemporaryLocation,
} from "../../state/updateState";
import { RoutePath } from "../../model/RoutePath.enum";
import { MainFooter, MainHeader } from "../Layout/Layout";
import React, {
  FormEvent,
  FormEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { FormValidation } from "../../helpers/FormValidation";
import classNames from "classnames/bind";
import NumberFormat from "react-number-format";
import { useTranslation } from "react-i18next";
import { PersonDetail } from "../../model/PersonDetail";
import SearchModal from "../shared/SearchModal/SearchModal";
import { Country } from "../../model/Country";
import { IncidentType } from "../../model/IncidentType.enum";
import { CountryUtils } from "../../helpers/CountryUtils";
import { Api } from "../../api/api";
import { CountryCode } from "../../model/CountryCodes";
import { Address, Party } from "../../api/models/PersonalInfo";
import { CaseUtils, NameSize } from "../../helpers/CaseUtils";
import { ScrollToError } from "../shared/ScrollToError/scroll-to-error";
import countries from "../../assets/utils/emergency-country-codes";
import countryCodes from "../../assets/utils/country-code";
import Loading from "../Loading/Loading";
import { useJsApiLoader } from "@react-google-maps/api";
import { Libraries } from "@react-google-maps/api/dist/utils/make-load-script-url";
import useOnlineStatus from "../../hooks/useOnlineStatus";
import {
  PolicyHolderLabel,
  PolicyHolderType,
} from "../../model/PolicyHolderType.enum";
import { LocationFields } from "../Location/GeoLocation/GeoLocation";
import { OneTimePasswordParams } from "../../model/OneTimePassword/one-time-password-params";
import formatStringByPattern from "format-string-by-pattern";
const libraries: Libraries = ["places"];
const policyHolderOptions = [
  {
    label: PolicyHolderLabel.ME,
    value: PolicyHolderType.ME,
  },
  {
    label: PolicyHolderLabel.COMPANY,
    value: PolicyHolderType.COMPANY,
  },
  {
    label: PolicyHolderLabel.ANOTHER_PERSON,
    value: PolicyHolderType.ANOTHER_PERSON,
  },
];

const PersonalDetails: React.FC = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [isMemberNumberSFDC, setIsMemberNumberSFDC] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [formattedPolicyNumber, setFormattedPolicyNumber] =
    useState<string>("");
  const pageRef = useRef<HTMLElement>(null);
  /*const personalNumberInput = useRef<HTMLIonInputElement>(null);*/
  const mainCountry = countries.find((ec) => ec.country_en === "Switzerland");
  const radioStart =
    process.env.REACT_APP_RADIO_START &&
    process.env.REACT_APP_RADIO_START == "true"
      ? "start"
      : "end";

  const countryKey: string = `country_${i18n.language}`;

  const { actions, state } = useStateMachine({
    updateSteps,
    updateIsAbroadCase,
    updateTemporaryLocation,
    updateHasSubmittedLocation,
  });
  const [country, setCountry] = useState<string>();

  const callback = useCallback((value: string) => {
    setCountry(value);
  }, []);

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY
      ? process.env.REACT_APP_GOOGLE_API_KEY
      : "",
    libraries,
  });
  const isOnline = useOnlineStatus();
  const firstNameRequiredMsg = t("First name is a required field");
  const lastNameRequiredMsg = t("Last name is a required field");
  const streetAddressRequiredMsg = t("Street address is a required field");
  const streetNumberRequiredMsg = t("Street number is a required field");
  const postCodeRequiredMsg = t("Postal code is a required field");
  const cityRequiredMsg = t("City is a required field");
  const countryRequiredMsg = t("Country code is a required field");
  const phoneRequiredMsg = t("Phone is a required field");
  const requiredPattern = t("Phone number must start with '+' or '00'");
  const errMaxLength = t("Phone number must be less than or equal to 14");
  const particularPatternForPolicyNumber = ["S", "M"];
  const policyHolderRequiredMsg = t("Policy holder type is required");
  const personalReferenceError = t(
    "Personal reference must have the following format 111.111.111"
  );
  const personalReferenceRequired = t("Personal reference is required");

  /*const validationSchema = process.env.REACT_APP_SIMPLIFIED_PERSONAL_DETAILS && process.env.REACT_APP_SIMPLIFIED_PERSONAL_DETAILS === "true" ?
      Yup.object({
        firstName: Yup.string().required(firstNameRequiredMsg),
        lastName: Yup.string().required(lastNameRequiredMsg),
        phone: Yup.string()
            .required(phoneRequiredMsg)
            .matches(new RegExp("^00[0-9]*$|^\\+[0-9]*$"), requiredPattern)
            .max(14, errMaxLength),
      })
      : process.env.REACT_APP_PERSONAL_POLICE_NUMBER && process.env.REACT_APP_PERSONAL_POLICE_NUMBER === "true" ?
          Yup.object({
            personalReference: Yup.string()
                .trim()
                .required(personalReferenceRequired),
            firstName: Yup.string().required(firstNameRequiredMsg),
            lastName: Yup.string().required(lastNameRequiredMsg),
            streetAddress: Yup.string().required(streetAddressRequiredMsg),
            streetNumber: Yup.string().required(streetNumberRequiredMsg),
            postalCode: Yup.string().required(postCodeRequiredMsg),
            city: Yup.string().required(cityRequiredMsg),
            country: Yup.string().required(countryRequiredMsg),
            phone: Yup.string()
                .required(phoneRequiredMsg)
                .matches(new RegExp("^00[0-9]*$|^\\+[0-9]*$"), requiredPattern)
                .max(14, errMaxLength),
          }) :
            Yup.object({
              personalReference: Yup.string()
                .trim()
                .matches(
                  new RegExp("^[0-9]{3}.[0-9]{3}.[0-9]{3}$"),
                  personalReferenceError
                ),
              firstName: Yup.string().required(firstNameRequiredMsg),
              lastName: Yup.string().required(lastNameRequiredMsg),
              streetAddress: Yup.string().required(streetAddressRequiredMsg),
              streetNumber: Yup.string().required(streetNumberRequiredMsg),
              postalCode: Yup.string().required(postCodeRequiredMsg),
              city: Yup.string().required(cityRequiredMsg),
              country: Yup.string().required(countryRequiredMsg),
              phone: Yup.string()
                .required(phoneRequiredMsg)
                .matches(new RegExp("^00[0-9]*$|^\\+[0-9]*$"), requiredPattern)
                .max(14, errMaxLength),
            });*/

  const focusOn = () => {
    if (isPlatform("desktop")) {
      setTimeout(() => {
        const elements = document.getElementsByName("personalReference");
        if (elements && elements.length >= 0) {
          elements[0]?.focus();
        }
      }, 250);
    }
  };

  interface PolicyHolderErrors {
    policyHolderType?: string;
    policyHolderFirstName?: string;
    policyHolderLastName?: string;
    policyHolderCompanyName?: string;
  }

  const formik = useFormik({
    initialValues: {
      ...state.steps.personalDetails,
      phone: state.cachedPasswords!.phoneNumber,
    },
    validate: (data: PersonDetail) => {
      let errors = {
        ...({} as PersonDetail),
        ...({} as PolicyHolderErrors),
      };
      if (!data.firstName) {
        errors.firstName = t("First name is a required field");
      }
      if (!data.lastName) {
        errors.lastName = t("Last name is a required field");
      }
      if (!data.phone) {
        errors.phone = t("Phone is a required field");
      } else if (
        !/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(
          data.phone
        )
      ) {
        errors.phone = t("Phone number must start with '+' or '00'");
      } else if (data.phone.length > 14) {
        errors.phone = t("Phone number must be less than or equal to 14");
      }

      // For non simplified personal data, so with address
      if (
        !process.env.REACT_APP_SIMPLIFIED_PERSONAL_DETAILS ||
        process.env.REACT_APP_SIMPLIFIED_PERSONAL_DETAILS === "false"
      ) {
        if (!data.streetAddress) {
          errors.streetAddress = t("Street address is a required field");
        }
        if (!data.streetNumber) {
          errors.streetNumber = t("Street number is a required field");
        }
        if (!data.postalCode) {
          errors.postalCode = t("Postal code is a required field");
        }
        if (!data.city) {
          errors.city = t("City is a required field");
        }
        if (!data.country) {
          errors.country = t("Country code is a required field");
        }
      }

      // For policyHolder description
      if (
        process.env.REACT_APP_ADDITIONAL_POLICY_HOLDER_INFORMATION &&
        process.env.REACT_APP_ADDITIONAL_POLICY_HOLDER_INFORMATION === "true"
      ) {
        if (!data.policyHolderType) {
          errors.policyHolderType = t("Policy holder type is a required field");
        }
        switch (data.policyHolderType) {
          case PolicyHolderType.COMPANY:
            if (!data.policyHolderCompanyName) {
              errors.policyHolderCompanyName = t(
                "Company name is a required field"
              );
            }
            break;
          case PolicyHolderType.ANOTHER_PERSON:
            if (!data.policyHolderFirstName) {
              errors.policyHolderFirstName = t(
                "Policy holder first name is a required field"
              );
            }
            if (!data.policyHolderLastName) {
              errors.policyHolderLastName = t(
                "Policy holder last name is a required field"
              );
            }
            break;
          default:
            break;
        }
      }

      return errors;
    },
    onSubmit: (personalDetails: PersonDetail) => {
      actions.updateSteps({
        personalDetails: personalDetails,
      });
      goToNextRoute();
    },
  });

  useEffect(() => {
    if (
      process.env.REACT_APP_RETRIEVE_SFDC_INFOS &&
      process.env.REACT_APP_RETRIEVE_SFDC_INFOS == "true"
    ) {
      if (!state.cachedPasswords?.phoneNumber || !state.token?.access_token) {
        console.error("Phone number or access token don't exist");
        return;
      }
      if (
        process.env.REACT_APP_SIMPLIFIED_PERSONAL_DETAILS &&
        process.env.REACT_APP_SIMPLIFIED_PERSONAL_DETAILS == "true"
      ) {
        return;
      }

      const hState = history.location.state;
      if (
        !hState ||
        ((hState as any).from !== RoutePath.LOCATION &&
          (hState as any).from !== RoutePath.LOCATE_ME_NOW &&
          (hState as any).from !== RoutePath.ABROAD_ADDITIONAL_INFOS)
      ) {
        history.replace({ state: null });
        return;
      }

      setLoading(true);
      Api.getPersonalInfo(
        state.cachedPasswords.phoneNumber,
        state.token.access_token
      )
        .then((resp) => {
          fillUserData(resp.data);
          setLoading(false);
          focusOn();
        })
        .catch(() => {
          setLoading(false);
          if (!state.steps.immobilizedOnHighway && state.steps.parkedAtHome) {
            fillAddressFromLocation();
          }
          formik.setFieldValue("salesForceResponse", undefined);
          focusOn();
        });
    } else {
      setIsMemberNumberSFDC(false);
      if (!state.steps.immobilizedOnHighway && state.steps.parkedAtHome) {
        fillAddressFromLocation();
      }
      formik.setFieldValue("salesForceResponse", undefined);
      focusOn();
    }
  }, []);

  useEffect(() => {
    if (isLoaded && isOnline) {
      if (state.steps.locationFields?.hasUpdatedAddress == false) {
        geocode(state.temporaryLocation?.location as any);
      }
    }
  }, [isLoaded, isOnline]);
  const geocode = async (loc: google.maps.LatLng) => {
    try {
      const geocoder = new google.maps.Geocoder();
      const resp = await geocoder.geocode({ location: loc });
      const results = resp.results;
      if (results && results.length > 0) {
        let result;
        const isOnHighway =
          (!state.isInEditMode && state.steps.immobilizedOnHighway) ||
          (state.isInEditMode && state.summary.immobilizedOnHighway);
        if (isOnHighway) {
          result = results.find((gr) =>
            gr.types.some((type) => ["postal_code"].includes(type))
          );
          // fallback in case no address with the type postal code
          result = result
            ? result
            : results.find((gr) =>
                gr.types.some((type) =>
                  ["locality", "political"].includes(type)
                )
              );
        } else {
          // The first element returned by googles maps is the more precise.
          // Just remove the results that contain plus_code since contains
          // unreadable info for the user ( indicates an encoded location reference)
          result = results.find((value) => !value.types.includes("plus_code"));
        }

        if (result) {
          let locationFields = state.steps.locationFields;
          locationFields.address = result.formatted_address;
          locationFields.googleResult = result;
          actions.updateSteps({ locationFields });
          actions.updateTemporaryLocation(undefined);
          actions.updateHasSubmittedLocation(true);
          if (!CountryUtils.isInSwitzerlandOrLiechtenstein(result)) {
            actions.updateIsAbroadCase(true);
            history.push(
              `${RoutePath.REQUEST}/${RoutePath.ABROAD_ADDITIONAL_INFOS}`,
              {
                from: RoutePath.LOCATION,
              }
            );
          }
        } else {
          console.log("No results");
        }
      } else {
        console.log("No results");
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (formik.values.country === "" && mainCountry) {
      setCountry(mainCountry[countryKey as keyof Country]);
    } else {
      const foundCountry = CountryUtils.findCountryByFullName(
        formik.values.country
      );
      setCountry(foundCountry![countryKey as keyof Country]);
    }
  }, [i18n.language]);

  useEffect(() => {
    formik.setFieldValue("country", country);
  }, [country]);

  useEffect(() => {
    if (Object.keys(formik.errors).length > 0) {
      document
        .getElementsByName(Object.keys(formik.errors)[0])[0]
        .scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [formik.isSubmitting]);

  const goToNextRoute = () => {
    history.replace({ state: null });
    if (state.isInEditMode) {
      history.push(`${RoutePath.REQUEST}/${RoutePath.SUMMARY}`);
    } else {
      if (state.steps.incidentType === IncidentType.BICYCLE) {
        if (
          CountryUtils.isInSwitzerlandOrLiechtenstein(
            state.steps.locationFields.googleResult
          )
        ) {
          history.push(`${RoutePath.REQUEST}/${RoutePath.SCHEDULE_OPTIONS}`);
        } else {
          history.push(`${RoutePath.REQUEST}/${RoutePath.SUMMARY}`);
        }
      } else {
        history.push(`${RoutePath.REQUEST}/${RoutePath.VEHICLE_DETAILS}`);
      }
    }
  };

  const fillUserData = (parties: Party[]) => {
    if (parties?.length > 0) {
      formik.setFieldValue("salesForceResponse", parties[0]);

      if (parties[0].individual) {
        formik.setFieldValue("firstName", parties[0].individual.firstName);
        formik.setFieldValue("lastName", parties[0].individual.lastName);
      }

      if (parties[0].partyRoles?.some((value) => value.personalReference)) {
        setIsMemberNumberSFDC(true);
      } else {
        setIsMemberNumberSFDC(false);
      }

      if (parties[0].legalAddresses?.length > 0) {
        fillAddressFromSalesForce(parties[0].legalAddresses[0]);
      } else {
        fillAddressFromLocation();
      }
    }
  };

  const fillAddressFromSalesForce = (address: Address) => {
    formik.setFieldValue("streetAddress", address.streetName);
    formik.setFieldValue(
      "streetNumber",
      [address.houseNumber, address.cptHouseNumber].join("")
    );
    formik.setFieldValue("postalCode", address.npa);
    formik.setFieldValue("city", address.locality);

    if (address.country) {
      const result = countryCodes[address.country as keyof CountryCode];

      if (result) {
        setCountry(result);
      }
    }
  };

  const fillAddressFromLocation = () => {
    if (
      !state.steps.locationFields ||
      !state.steps.locationFields.googleResult
    ) {
      return;
    }

    const { googleResult } = state.steps.locationFields;
    if (googleResult) {
      formik.setFieldValue(
        "streetAddress",
        CaseUtils.getAddressComponentFromGoogleResult(
          googleResult,
          "route",
          NameSize.LONG_NAME
        )
      );

      formik.setFieldValue(
        "streetNumber",
        CaseUtils.getAddressComponentFromGoogleResult(
          googleResult,
          "street_number",
          NameSize.LONG_NAME
        )
      );

      formik.setFieldValue(
        "postalCode",
        CaseUtils.getAddressComponentFromGoogleResult(
          googleResult,
          "postal_code",
          NameSize.LONG_NAME
        )
      );

      formik.setFieldValue(
        "city",
        CaseUtils.getAddressComponentFromGoogleResult(
          googleResult,
          "locality",
          NameSize.LONG_NAME
        )
      );

      const countryResult = CaseUtils.getAddressComponentFromGoogleResult(
        googleResult,
        "country",
        NameSize.SHORT_NAME
      );

      if (countryResult) {
        const result = countryCodes[countryResult as keyof CountryCode];
        if (result) {
          setCountry(result);
        }
      }
    }
  };

  const goToPreviousPage = (personalDetails: PersonDetail) => {
    actions.updateSteps({
      personalDetails,
      locationFields: { ...state.steps.locationFields, refresh: true },
    });

    history.back();
  };

  const getCountriesOptions = (): string[] => {
    return countries
      .map((obj: Country) => obj[countryKey as keyof Country])
      .sort((a, b) => (a.toLowerCase() < b.toLowerCase() ? -1 : 1));
  };

  const formatPersonalNumber = (value: CustomEvent<InputChangeEventDetail>) => {
    if (value.detail.value) {
      const inputValue = value.detail.value;
      let formattedValue = "";
      console.log(inputValue);
      // Check if value starts with S or M for baloise
      if (
        particularPatternForPolicyNumber.includes(
          value.detail.value[0].toUpperCase()
        )
      ) {
        formattedValue =
          value.detail.value[0].toUpperCase() +
          formatStringByPattern(
            "S9999999999999",
            inputValue.slice(1, 12).replace(/[^\d]/g, "")
          );
        formik.setFieldValue("personalReference", formattedValue);
      } else {
        const onlyNumbers = inputValue.replace(/[^\d]/g, "").slice(0, 10);
        formattedValue = formatStringByPattern("99/9.999.999", onlyNumbers);
        formik.setFieldValue("personalReference", onlyNumbers);
      }
      setFormattedPolicyNumber(formattedValue);
      /*if (personalNumberInput.current) {
        personalNumberInput.current.value = formattedValue;
      }*/
    } else {
      setFormattedPolicyNumber("");
      formik.setFieldValue("personalReference", "");
    }
  };

  return (
    <IonContent>
      <FormikProvider value={formik}>
        <ScrollToError />
        <form onSubmit={formik.handleSubmit}>
          <MainHeader title={t("personal_details_title")}>
            <div className="personal-details-container">
              <p className="pd-disclaimer">
                {t(
                  "I hereby certify that the information below is correct and that I am personally present at the scene of the breakdown."
                )}
              </p>

              <section>
                <h6>{t("First Name")}</h6>
                <IonInput
                  className={classNames({
                    "tcs-invalid": FormValidation.getFormErrorMessage(
                      "firstName",
                      formik,
                      t
                    ),
                  })}
                  id="firstName"
                  placeholder={t("placeholder_firstname_personal_details")}
                  autocomplete="given-name"
                  inputmode="text"
                  name="firstName"
                  value={formik.values.firstName}
                  onIonChange={formik.handleChange}
                />
                {FormValidation.getFormErrorMessage("firstName", formik, t)}
              </section>

              <section>
                <h6>{t("Last Name")}</h6>
                <IonInput
                  className={classNames({
                    "tcs-invalid": FormValidation.getFormErrorMessage(
                      "lastName",
                      formik,
                      t
                    ),
                  })}
                  id="lastName"
                  placeholder={t("placeholder_lastname_personal_details")}
                  name="lastName"
                  inputmode="text"
                  autocomplete="family-name"
                  value={formik.values.lastName}
                  onIonChange={formik.handleChange}
                />
                {FormValidation.getFormErrorMessage("lastName", formik, t)}
              </section>

              <section>
                <h6>{t("Phone")}</h6>
                <IonInput
                  className={classNames({
                    "tcs-invalid": FormValidation.getFormErrorMessage(
                      "phone",
                      formik,
                      t
                    ),
                  })}
                  id="phone"
                  placeholder={t("placeholder_phone_personal_details")}
                  name="phone"
                  type="tel"
                  inputmode="tel"
                  maxlength={14}
                  autocomplete="tel"
                  value={formik.values.phone}
                  onIonChange={formik.handleChange}
                />
                {FormValidation.getFormErrorMessage("phone", formik, t)}
              </section>

              {/*For personal Ref from TCS*/}
              {!isMemberNumberSFDC &&
                (!process.env.REACT_APP_SIMPLIFIED_PERSONAL_DETAILS ||
                  process.env.REACT_APP_SIMPLIFIED_PERSONAL_DETAILS ===
                    "false") &&
                (!process.env.REACT_APP_PERSONAL_POLICE_NUMBER ||
                  process.env.REACT_APP_PERSONAL_POLICE_NUMBER === "false") && (
                  <section>
                    <h6>{t("Personal Reference (optional)")}</h6>
                    <div
                      className={classNames("personal-reference-container", {
                        "tcs-invalid": FormValidation.isFormFieldValid(
                          "personalReference",
                          formik
                        ),
                      })}
                    >
                      <NumberFormat
                        className={classNames("personal-reference-input")}
                        format="###.###.###"
                        placeholder={t(
                          "placeholder_personal_reference_personal_details"
                        )}
                        allowEmptyFormatting={false}
                        autoComplete="on"
                        inputMode="numeric"
                        type="text"
                        id="personalReference"
                        name="personalReference"
                        value={formik.values.personalReference}
                        onChange={formik.handleChange}
                      />
                    </div>
                    {FormValidation.getFormErrorMessage(
                      "personalReference",
                      formik,
                      t
                    )}
                  </section>
                )}
              {/*{!isMemberNumberSFDC
                  && process.env.REACT_APP_PERSONAL_POLICE_NUMBER
                  && process.env.REACT_APP_PERSONAL_POLICE_NUMBER === "true"
                  && (
                      <section>
                        <h6>{t("Policy number")}</h6>
                        <IonInput
                            className={classNames("personal-reference-container", {
                              "tcs-invalid": FormValidation.isFormFieldValid(
                                  "personalReference",
                                  formik
                              ),
                            })}
                            ref={personalNumberInput}
                            onIonChange={formatPersonalNumber}
                            value={formattedPolicyNumber}
                            placeholder={"e.g. XX/X.XXX.XXX or S/MXXXXXXXXXXXXXX"}
                            id={"personalReference"}
                            name={"personalReference"}
                        >
                        </IonInput>
                        {FormValidation.getFormErrorMessage(
                            "personalReference",
                            formik,
                            t
                        )}
                      </section>
                  )}*/}
              {(!process.env.REACT_APP_SIMPLIFIED_PERSONAL_DETAILS ||
                process.env.REACT_APP_SIMPLIFIED_PERSONAL_DETAILS ===
                  "false") && (
                <>
                  <h6>{t("Residential Address")}</h6>
                  <section className="personal-details-address">
                    <div>
                      <h6>{t("Street Address")}</h6>
                      <IonInput
                        className={classNames({
                          "tcs-invalid": FormValidation.getFormErrorMessage(
                            "streetAddress",
                            formik,
                            t
                          ),
                        })}
                        id="streetAddress"
                        placeholder={t("Street")}
                        name="streetAddress"
                        inputmode="text"
                        autocomplete="on"
                        value={formik.values.streetAddress}
                        onIonChange={formik.handleChange}
                      />
                      {FormValidation.getFormErrorMessage(
                        "streetAddress",
                        formik,
                        t
                      )}
                    </div>
                    <div>
                      <h6>{t("Street number")}</h6>
                      <IonInput
                        className={classNames({
                          "tcs-invalid": FormValidation.getFormErrorMessage(
                            "streetNumber",
                            formik,
                            t
                          ),
                        })}
                        id="streetNumber"
                        placeholder={t(
                          "placeholder_street_number_personal_details"
                        )}
                        name="streetNumber"
                        autocomplete="on"
                        value={formik.values.streetNumber}
                        onIonChange={formik.handleChange}
                      />
                      {FormValidation.getFormErrorMessage(
                        "streetNumber",
                        formik,
                        t
                      )}
                    </div>
                  </section>

                  <section className="personal-details-city">
                    <div>
                      <h6>{t("Postal Code")}</h6>
                      <IonInput
                        className={classNames({
                          "tcs-invalid": FormValidation.getFormErrorMessage(
                            "postalCode",
                            formik,
                            t
                          ),
                        })}
                        id="postalCode"
                        placeholder={t(
                          "placeholder_postal_code_personal_details"
                        )}
                        name="postalCode"
                        inputmode="numeric"
                        autocomplete="postal-code"
                        value={formik.values.postalCode}
                        onIonChange={formik.handleChange}
                      />
                      {FormValidation.getFormErrorMessage(
                        "postalCode",
                        formik,
                        t
                      )}
                    </div>

                    <div>
                      <h6>{t("City")}</h6>
                      <IonInput
                        className={classNames({
                          "tcs-invalid": FormValidation.getFormErrorMessage(
                            "city",
                            formik,
                            t
                          ),
                        })}
                        id="city"
                        placeholder={t("placeholder_city_personal_details")}
                        autocomplete="on"
                        inputmode="text"
                        name="city"
                        value={formik.values.city}
                        onIonChange={formik.handleChange}
                      />
                      {FormValidation.getFormErrorMessage("city", formik, t)}
                    </div>
                  </section>

                  <section>
                    <h6>{t("Country")}</h6>
                    <IonButton
                      id="pd-country"
                      className="button-tcs input"
                      expand="block"
                      onClick={() => setShowFilterModal(true)}
                    >
                      <div className="wrapper">
                        <IonLabel>{country}</IonLabel>
                        <IonIcon
                          slot="end"
                          src={`${process.env.PUBLIC_URL}/assets/images/chevron-down-outline.svg`}
                        />
                      </div>
                    </IonButton>
                    {FormValidation.getFormErrorMessage("country", formik, t)}
                  </section>
                </>
              )}
              {process.env.REACT_APP_ADDITIONAL_POLICY_HOLDER_INFORMATION &&
                process.env.REACT_APP_ADDITIONAL_POLICY_HOLDER_INFORMATION ===
                  "true" && (
                  <section>
                    <h3>{t("Who is the policy holder?")}</h3>
                    <IonRadioGroup
                      value={formik.values.policyHolderType}
                      name="policyHolderType"
                      onIonChange={(e) => {
                        formik.setFieldValue(
                          "policyHolderType",
                          e.detail.value
                        );
                      }}
                    >
                      {policyHolderOptions.map(({ label, value }, i) => {
                        return (
                          <IonItem key={i}>
                            <span className={"tab"}>{t(label)}</span>
                            <IonRadio slot={radioStart} value={value} />
                          </IonItem>
                        );
                      })}
                    </IonRadioGroup>
                    {FormValidation.getFormErrorMessage(
                      "policyHolderType",
                      formik,
                      t
                    )}
                  </section>
                )}
              {formik.values.policyHolderType === PolicyHolderType.COMPANY && (
                <section>
                  <h3>{t("Policy holder information")}</h3>
                  <section>
                    <h6>{t("Company Name")}</h6>
                    <IonInput
                      className={classNames({
                        "tcs-invalid": FormValidation.getFormErrorMessage(
                          "policyHolderCompanyName",
                          formik,
                          t
                        ),
                      })}
                      id="policyHolderCompanyName"
                      autocomplete="given-name"
                      inputmode="text"
                      name="policyHolderCompanyName"
                      value={formik.values.policyHolderCompanyName}
                      onIonChange={formik.handleChange}
                    />
                    {FormValidation.getFormErrorMessage(
                      "policyHolderCompanyName",
                      formik,
                      t
                    )}
                  </section>
                </section>
              )}
              {formik.values.policyHolderType ===
                PolicyHolderType.ANOTHER_PERSON && (
                <section>
                  <h3>{t("Policy holder information")}</h3>
                  <section>
                    <h6>{t("First Name")}</h6>
                    <IonInput
                      className={classNames({
                        "tcs-invalid": FormValidation.getFormErrorMessage(
                          "policyholderFirstName",
                          formik,
                          t
                        ),
                      })}
                      id="policyHolderFirstName"
                      autocomplete="given-name"
                      inputmode="text"
                      name="policyHolderFirstName"
                      value={formik.values.policyHolderFirstName}
                      onIonChange={formik.handleChange}
                    />
                    {FormValidation.getFormErrorMessage(
                      "policyHolderFirstName",
                      formik,
                      t
                    )}
                  </section>

                  <section>
                    <h6>{t("Last Name")}</h6>
                    <IonInput
                      className={classNames({
                        "tcs-invalid": FormValidation.getFormErrorMessage(
                          "policyHolderLastName",
                          formik,
                          t
                        ),
                      })}
                      id="policyHolderLastName"
                      name="policyHolderLastName"
                      inputmode="text"
                      autocomplete="family-name"
                      value={formik.values.policyHolderLastName}
                      onIonChange={formik.handleChange}
                    />
                    {FormValidation.getFormErrorMessage(
                      "policyHolderLastName",
                      formik,
                      t
                    )}
                  </section>
                </section>
              )}
            </div>
          </MainHeader>
          <MainFooter>
            {!state.isInEditMode && (
              <IonButton
                id="pd-back"
                className="button-tcs back"
                expand="block"
                onClick={() => goToPreviousPage(formik.values)}
              >
                <div className="wrapper">
                  <IonIcon
                    size="large"
                    src={`${process.env.PUBLIC_URL}/assets/images/chevron-back.svg`}
                  />
                  <IonLabel>{t("Back")}</IonLabel>
                  <div />
                </div>
              </IonButton>
            )}
            {state.isInEditMode && (
              <IonButton
                id="pd-cancel"
                className="button-tcs"
                expand="block"
                onClick={() => history.back()}
              >
                <IonLabel>{t("Cancel")}</IonLabel>
              </IonButton>
            )}
            {!state.isInEditMode && (
              <IonButton
                id="pd-next"
                className="button-tcs cta"
                color="primary"
                expand="block"
                onClick={() => formik.handleSubmit()}
              >
                <div className="wrapper">
                  <div />
                  <IonLabel>{t("Next")}</IonLabel>
                  <IonIcon
                    slot="end"
                    size="large"
                    src={`${process.env.PUBLIC_URL}/assets/images/chevron-forward.svg`}
                  />
                </div>
              </IonButton>
            )}
            {state.isInEditMode && (
              <IonButton
                id="pd-save"
                className="button-tcs cta"
                color="primary"
                expand="block"
                onClick={() => formik.handleSubmit()}
              >
                <IonLabel>{t("Save")}</IonLabel>
              </IonButton>
            )}
          </MainFooter>
        </form>
      </FormikProvider>
      {loading && <Loading />}
      <IonModal
        isOpen={showFilterModal}
        onDidDismiss={() => setShowFilterModal(false)}
        presentingElement={pageRef.current!}
      >
        <SearchModal
          title={t("Countries")}
          onDismissModal={() => setShowFilterModal(false)}
          elements={getCountriesOptions()}
          selectedValue={callback}
        />
      </IonModal>
    </IonContent>
  );
};

export default PersonalDetails;
