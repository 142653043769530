import { PersonDetail } from "../model/PersonDetail";
import CountryCodes from "../assets/utils/country-code";
import { CountryCode } from "../model/CountryCodes";
/**
 * DGTINT-19
 */
enum ModificationType {
  salesForceDataWithoutModification = "salesForceDataWithoutModification",
  salesForceDataNoModificationOnPersonalReference = "salesForceDataNoModificationOnPersonalReference",
  salesForceDataModificationOnPersonalReference = "salesForceDataModificationOnPersonalReference",
  noSalesForceData = "noSalesForceData",
  noSalesForceDataButUserIncludesPersonalReference = "noSalesForceDataButUserIncludesPersonalReference",
}

export class PersonalDetailsUtils {
  public static serializeCaseComment(data: PersonDetail): string | undefined {
    const type = PersonalDetailsUtils.compareSalesForceWithFilledData(data);

    switch (type) {
      case ModificationType.salesForceDataNoModificationOnPersonalReference: // Salesforce's data exists and user edits something
        return "Callback Deckung";
      case ModificationType.salesForceDataModificationOnPersonalReference:
        return "Kundeninfos in Salesforce aktualisieren";
      case ModificationType.noSalesForceData:
        return "Callback Deckung oder Preisinformationen";
      case ModificationType.noSalesForceDataButUserIncludesPersonalReference:
        return "Callback Deckung";
      default:
        return undefined;
    }
  }

  public static serializeCoverageComment(data: PersonDetail): string[] {
    const type = this.compareSalesForceWithFilledData(data);

    switch (type) {
      case ModificationType.salesForceDataWithoutModification: // Salesforce's data exists and user clicks "Next" - no coverage comment should be appended
        return [];
      case ModificationType.salesForceDataNoModificationOnPersonalReference: // Salesforce's data exists and user edits something
        return [
          ...this.mapSalesForceData(
            data,
            ModificationType.salesForceDataNoModificationOnPersonalReference
          ),
          "Callback Deckung",
        ];
      case ModificationType.salesForceDataModificationOnPersonalReference: {
        // Salesforce's data exists, user enters member number and edits something
        return [
          ...this.mapSalesForceData(
            data,
            ModificationType.salesForceDataModificationOnPersonalReference
          ),
          "Kundeninfos aktualisieren",
        ];
      }
      case ModificationType.noSalesForceData:
        return [
          ...this.mapSalesForceData(data, ModificationType.noSalesForceData),
          "Callback Deckung oder Preisinformation",
        ];
      case ModificationType.noSalesForceDataButUserIncludesPersonalReference: {
        return [
          ...this.mapSalesForceData(
            data,
            ModificationType.noSalesForceDataButUserIncludesPersonalReference
          ),
          "Callback Deckung",
        ];
      }
      default:
        return [];
    }
  }

  private static compareSalesForceWithFilledData(data: PersonDetail) {
    if (data.salesForceResponse) {
      return this.getSalesForceModification(data);
    } else {
      return this.getNoSalesForceModification(data);
    }
  }

  private static getSalesForceModification(
    data: PersonDetail
  ): ModificationType {
    if (data.personalReference && data.personalReference.trim() !== "") {
      // Salesforce's data exists, user enters member number and edits something
      return ModificationType.salesForceDataModificationOnPersonalReference;
    } else if (
      this.isAddressDifferent(data) ||
      this.isUserDetailsDifferent(data)
    ) {
      // Salesforce's data exists and user edits something
      return ModificationType.salesForceDataNoModificationOnPersonalReference;
    } else {
      // Salesforce's data exists and user clicks "Next":
      return ModificationType.salesForceDataWithoutModification;
    }
  }

  private static getNoSalesForceModification(
    data: PersonDetail
  ): ModificationType {
    if (data.personalReference && data.personalReference.trim() !== "") {
      //No Salesforce data found but user includes member number
      return ModificationType.noSalesForceDataButUserIncludesPersonalReference;
    } else {
      // No Salesforce data found
      return ModificationType.noSalesForceData;
    }
  }

  private static isAddressDifferent(data: PersonDetail): boolean {
    if (
      !data.salesForceResponse ||
      !data.salesForceResponse.legalAddresses ||
      !data.salesForceResponse.legalAddresses.length ||
      data.salesForceResponse.legalAddresses.length <= 0
    ) {
      return true;
    }

    const address = data.salesForceResponse.legalAddresses[0];

    const countryName = address.country
      ? (CountryCodes as CountryCode)[address.country as keyof CountryCode]
      : undefined;

    const countryDiff =
      data.country.trim() &&
      countryName &&
      countryName.trim() !== data.country.trim();
    const cityDiff =
      data.city.trim() &&
      address.locality &&
      address.locality.trim() !== data.city.trim();
    const postCodeDiff =
      data.postalCode.trim() &&
      address.npa &&
      address.npa.trim() !== data.postalCode.trim();

    const normalizedStreetNr = data.streetNumber
      ?.trim()
      ?.toLowerCase()
      ?.replace(/\s/g, ""); //e.g. 26A or 26 A -> 26a
    const salesForceHouseNr = address.houseNumber || " "; // e.g. 26
    const salesForceHouseCpt = address.cptHouseNumber || " "; // e.g. A
    const salesForceStreetNr = `${salesForceHouseNr}${salesForceHouseCpt}`;
    const salesForceNormalizedStreetNr = salesForceStreetNr
      ?.trim()
      ?.toLowerCase()
      ?.replace(/\s/g, ""); // 26A -> 26a

    const streetNumberDiff =
      normalizedStreetNr && normalizedStreetNr !== salesForceNormalizedStreetNr;

    const streetAddressDiff =
      data.streetAddress.trim() &&
      address.streetName &&
      address.streetName.trim() !== data.streetAddress.trim();

    return !!(
      streetAddressDiff ||
      streetNumberDiff ||
      postCodeDiff ||
      cityDiff ||
      countryDiff
    );
  }

  private static isUserDetailsDifferent(data: PersonDetail): boolean {
    const individual = data.salesForceResponse!.individual;

    const firstNameDiff =
      data.firstName.trim() &&
      individual.firstName &&
      individual.firstName.trim() !== data.firstName.trim();
    const lastNameDiff =
      data.lastName.trim() &&
      individual.lastName &&
      individual.lastName.trim() !== data.lastName.trim();

    return !!(firstNameDiff || lastNameDiff);
  }

  private static mapSalesForceData(
    data: PersonDetail,
    type: ModificationType
  ): string[] {
    const personalReference = this.getPersonalReferenceByType(data, type);

    const address = `Addresse: ${data.streetNumber} ${data.streetAddress}, ${data.postalCode} ${data.city}, ${data.country}`;

    const values = [
      personalReference,
      `Vorname: ${data.firstName}`,
      `Nachname: ${data.lastName}`,
      address,
    ].filter(Boolean);

    if (values.length > 0) {
      values[0] = `${this.getLabelComment(type)}${values[0]}`;
    }

    return values.filter(Boolean) as string[];
  }

  private static getLabelComment(type: ModificationType): string | undefined {
    switch (type) {
      case ModificationType.salesForceDataNoModificationOnPersonalReference: // Salesforce's data exists and user edits something
        return "Wohnadresse in der App aktualisiert: ";
      case ModificationType.salesForceDataModificationOnPersonalReference: // Salesforce's data exists, user enters member number and edits something
        return "Wohnadresse in der App aktualisiert: ";
      case ModificationType.noSalesForceData:
        return "Wohnadresse in der App hinzugefügt: ";
      case ModificationType.noSalesForceDataButUserIncludesPersonalReference:
        return "Referenznummer und Wohnadresse in der App hinzugefügt: ";
      default:
        return undefined;
    }
  }

  private static getPersonalReferenceByType(
    data: PersonDetail,
    type: ModificationType
  ): string | undefined {
    if (
      type === ModificationType.salesForceDataNoModificationOnPersonalReference // Salesforce's data exists and user edits something
    ) {
      return data.salesForceResponse?.partyRoles?.length &&
        data.salesForceResponse.partyRoles.length > 0
        ? `Salesforce Referenznummer: ${data.salesForceResponse?.partyRoles[0].personalReference}`
        : undefined;
    } else if (
      type ===
        ModificationType.noSalesForceDataButUserIncludesPersonalReference ||
      type === ModificationType.salesForceDataModificationOnPersonalReference
    ) {
      return data.personalReference && data.personalReference !== ""
        ? `Referenznummer: ${data.personalReference}`
        : undefined;
    } else {
      return undefined;
    }
  }

  private static serializeCoverageCommentSalesForceNoModification(
    data: PersonDetail
  ): string[] {
    const personalReference =
      data.salesForceResponse?.partyRoles?.length &&
      data.salesForceResponse.partyRoles.length > 0
        ? data.salesForceResponse?.partyRoles[0].personalReference
        : undefined;

    return personalReference ? [`Referenznummer: ${personalReference}`] : [];
  }
}
