import React, { useEffect, useRef, useState } from "react";
import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonRadio,
  IonRadioGroup,
} from "@ionic/react";
import { MainFooter, MainHeader } from "../../Layout/Layout";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useStateMachine } from "little-state-machine";
import {
  updateDisplayMode,
  updateHasSubmittedLocation,
  updateIsAbroadCase,
  updateLocateMeNow,
  updateLocateOnLoad,
  updateSteps,
  updateTemporaryLocation,
  updateLocationAdditionalInfo,
} from "../../../state/updateState";
import { RoutePath } from "../../../model/RoutePath.enum";
import "./LocateMeNow.scss";
import classNames from "classnames";
import { FormValidation } from "../../../helpers/FormValidation";
import { useFormik } from "formik";
import { LocationFields } from "../GeoLocation/GeoLocation";
import { CaseUtils, NameSize } from "../../../helpers/CaseUtils";
import { Libraries } from "@react-google-maps/api/dist/utils/make-load-script-url";

import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import { IncidentType } from "../../../model/IncidentType.enum";
import { BicycleDetails } from "../../../model/Incident/BicycleDetails";
import { Api } from "../../../api/api";
import Loading from "../../Loading/Loading";
import { Address, Party } from "../../../api/models/PersonalInfo";
import countryCodes from "../../../assets/utils/country-code";
import { CountryCode } from "../../../model/CountryCodes";
const libraries: Libraries = ["places"];

const LocateMeNow: React.FC = () => {
  const history = useHistory();
  const locationInputRef = useRef<HTMLInputElement>(null);
  const parkedAtHomeRadioGroupRef = useRef<HTMLIonRadioGroupElement>(null);
  const { t } = useTranslation();
  const [autoComplete, setAutoComplete] =
    useState<google.maps.places.Autocomplete>();
  const [loading, setLoading] = useState<boolean>(false);
  const { actions, state } = useStateMachine({
    updateSteps,
    updateDisplayMode,
    updateTemporaryLocation,
    updateLocateOnLoad,
    updateHasSubmittedLocation,
    updateIsAbroadCase,
    updateLocateMeNow,
    updateLocationAdditionalInfo,
  });
  const [parkedAtHome, setParkedAtHome] = useState<boolean | undefined>(
    state.summary.parkedAtHome
  );
  const [showLocationBlock, setShowLocationBlock] = useState<boolean>(false);
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  const [showConfirmLocationBlock, setShowConfirmLocationBlock] =
    useState<boolean>(false);
  const [salesforceAddress, setSalesforceAddress] = useState<
    string | undefined
  >(undefined);
  const [requiredAddress, setRequiredAddress] = useState(
    t("Location address is required")
  );
  const requiredAddressNotAtHome = t(
    "Location address is required not at home"
  );
  const requiredAtHome = t("Are you at home is required");
  const setLocation = (loc: google.maps.LatLngLiteral) => {
    formik.setFieldValue("location", loc);
  };
  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY
      ? process.env.REACT_APP_GOOGLE_API_KEY
      : "",
    libraries,
  });

  const onLoadAuto = (autocomplete: google.maps.places.Autocomplete) => {
    setAutoComplete(autocomplete);
  };

  useEffect(() => {
    if (!isFirstLoad) {
      formik.setTouched({ address: true });
      formik.validateForm();
    } else {
      setIsFirstLoad(false);
    }
  }, [t]);
  useEffect(() => {
    if (isLoaded && !loadError) {
      if (parkedAtHome !== undefined) {
        const parkedAtHomeRadioGroup = parkedAtHomeRadioGroupRef.current;
        if (parkedAtHomeRadioGroup) {
          parkedAtHomeRadioGroup.value = parkedAtHome;
        }
        //formik.setFieldValue("parkedAtHome", parkedAtHome);
        //if (parkedAtHome && state.steps.locationFields.address) {
        if (parkedAtHome) {
          setShowConfirmLocationBlock(true);
          setShowLocationBlock(false);
          retrievePersonalAddress();
        } else {
          setShowConfirmLocationBlock(false);
          setShowLocationBlock(true);
          retrieveInputedAddress();
        }
      }
    }
  }, [isLoaded, loadError]);

  const retrieveInputedAddress = () => {
    if (state.steps.locationFields.address) {
      if (locationInputRef.current) {
        const value = state.locationAdditionalInfo
          ? `${state.locationAdditionalInfo}, ${state.steps.locationFields.address}`
          : state.steps.locationFields.address;
        locationInputRef.current.value = value;
      }
    }
  };

  const searchPlace = () => {
    if (autoComplete) {
      const place = autoComplete.getPlace();
      if (place) {
        const local = place.geometry;
        if (local && local.location) {
          let locationFields = state.steps.locationFields;
          const formattedAddress = place.formatted_address;
          const position = {
            lat: local.location.lat(),
            lng: local.location.lng(),
          };
          locationFields.location = position;
          if (place.name) {
            formik.setFieldValue("placeName", place.name);
            locationFields.placeName = place.name;
            if (!formattedAddress?.includes(place.name)) {
              actions.updateLocationAdditionalInfo(place.name);
            } else {
              actions.updateLocationAdditionalInfo("");
            }
          }
          if (formattedAddress) {
            formik.setFieldValue("address", formattedAddress);
            formik.setFieldValue("googleResult", place);
            locationFields.address = formattedAddress;
          }
          actions.updateSteps({ locationFields });
          actions.updateTemporaryLocation({ ...locationFields });
          setLocation(position);
        }
      } else {
        searchGeocodingManually(false);
      }
    } else {
      searchGeocodingManually(false);
    }
  };

  const searchGeocodingManually = (withFieldUpdate: boolean) => {
    formik.setFieldValue("placeName", "");
    formik.setFieldValue("address", "");
    formik.setFieldValue("googleResult", "");
    if (locationInputRef.current) {
      let geocoder = new google.maps.Geocoder();
      geocoder.geocode(
        { address: locationInputRef.current.value },
        function (results, status) {
          if (status == "OK" && results) {
            let locationFields = state.steps.locationFields;
            locationFields.address = results[0].formatted_address;
            locationFields.location = {
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng(),
            };
            locationFields.googleResult = results[0];
            if (withFieldUpdate && locationInputRef.current) {
              locationInputRef.current.value = results[0].formatted_address;
            }
            formik.setFieldValue("address", results[0].formatted_address);
            actions.updateSteps({ locationFields });
            actions.updateTemporaryLocation({ ...locationFields });
          } else {
            console.log(
              "Geocode was not successful for the following reason: " + status
            );
          }
        }
      );
    }
  };

  const formik = useFormik({
    initialValues: state.isInEditMode
      ? { ...state.summary.locationFields, parkedAtHome: undefined }
      : { ...state.steps.locationFields, parkedAtHome: undefined },
    validate: (data: LocationFields) => {
      let errors = {} as LocationFields;
      if (parkedAtHome === undefined) {
        (errors as any).parkedAtHome = t("Are you at home is required");
      }

      if (!data.address) {
        if (parkedAtHome) {
          (errors as any).address = t("Are you at home is required");
        } else {
          (errors as any).address = t(
            "Location address is required not at home"
          );
        }
      }
      return errors;
    },
    onSubmit: (locationFields: LocationFields) => {
      searchPlace();
      actions.updateHasSubmittedLocation(true);
      actions.updateLocateOnLoad(false);
      actions.updateLocateMeNow(false);
      actions.updateSteps({ locationFields });
      /*actions.updateTemporaryLocation({ ...locationFields });*/
      if (state.isInEditMode && parkedAtHome) {
        history.push(`${RoutePath.REQUEST}/${RoutePath.SUMMARY}`);
      } else {
        showConfirmLocationBlock
          ? history.push(`${RoutePath.REQUEST}/${RoutePath.PERSONAL_DETAILS}`, {
              from: RoutePath.LOCATE_ME_NOW,
            })
          : history.push(`${RoutePath.REQUEST}/${RoutePath.LOCATION}`);
      }
    },
  });

  const handleLocateMeNow = () => {
    actions.updateLocateMeNow(true);
    actions.updateLocateOnLoad(true);
    history.push(`${RoutePath.REQUEST}/${RoutePath.LOCATION}`);
  };

  const handleOnNext = () => {
    // We check if user has typed something in the address bar, but simply didn't used the autocomplete function
    if (locationInputRef.current && locationInputRef.current.value != "") {
      searchGeocodingManually(true);
    }
    formik.handleSubmit();
  };

  const isBicycleIncident = (): boolean => {
    return (
      state.steps.incidentType === IncidentType.BICYCLE &&
      (state.steps.incidentDetails as BicycleDetails).description !== ""
    );
  };

  const fillAddressFromSalesForce = (address: Address) => {
    if (address.country) {
      const result = countryCodes[address.country as keyof CountryCode];
      if (result) {
        const userSalesforceAddress = [
          address.streetName,
          [address.houseNumber, address.cptHouseNumber].join(""),
          [address.npa, address.locality].join(" "),
          result,
        ].join(", ");
        formik.setFieldValue("address", userSalesforceAddress);
        setSalesforceAddress(userSalesforceAddress);
        const locationInput = locationInputRef.current;
        if (locationInput) {
          locationInput.value = userSalesforceAddress;
        }
        autoComplete?.setValues({ formatted_address: userSalesforceAddress });
        searchPlace();
      }
    }
  };

  const fillUserAddress = (parties: Party[]) => {
    if (parties?.length > 0) {
      if (parties[0].legalAddresses?.length > 0) {
        fillAddressFromSalesForce(parties[0].legalAddresses[0]);
        setShowConfirmLocationBlock(true);
        setShowLocationBlock(false);
      } else {
        setShowConfirmLocationBlock(false);
        setShowLocationBlock(true);
      }
    }
  };

  const retrievePersonalAddress = () => {
    if (!state.cachedPasswords?.phoneNumber || !state.token?.access_token) {
      console.error("Phone number or access token don't exist");
      return;
    }
    if (
      !salesforceAddress &&
      parkedAtHome &&
      formik.values.address === "" &&
      process.env.REACT_APP_USE_SALESFORCE_ADDRESS === "true"
    ) {
      setLoading(true);
      Api.getPersonalInfo(
        state.cachedPasswords.phoneNumber,
        state.token.access_token
      )
        .then((resp) => {
          fillUserAddress(resp.data);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };
  const handleParkedAtHome = (route: string) => {
    // SF request
    retrievePersonalAddress();
    if (state.isInEditMode) {
      //history.push(`${RoutePath.REQUEST}/${RoutePath.LOCATION}`);
    } else {
      //history.push(`${RoutePath.REQUEST}/${route}`);
    }
  };

  const handleNotParkedAtHome = (route: string) => {
    if (state.isInEditMode) {
      //history.push(`${RoutePath.REQUEST}/${RoutePath.LOCATION}`);
    } else if (isBicycleIncident()) {
      history.push(`${RoutePath.REQUEST}/${RoutePath.LOCATE_ME_NOW}`);
    } else {
      //history.push(`${RoutePath.REQUEST}/${route}`);
    }
  };

  const onOptionSelected = (isAtHome: boolean) => {
    setParkedAtHome(isAtHome);
    actions.updateSteps({ parkedAtHome: isAtHome });
    if (isAtHome === true) {
      setShowConfirmLocationBlock(true);
      setShowLocationBlock(false);
      handleParkedAtHome(RoutePath.LOCATE_ME_NOW);
    } else {
      setShowConfirmLocationBlock(false);
      setShowLocationBlock(true);
      handleNotParkedAtHome(RoutePath.LOCATE_ME_NOW);
      const locationInput = locationInputRef.current;
      if (locationInput) {
        locationInput.value = "";
      }
    }
  };

  return (
    <IonContent>
      <MainHeader
        title={t("Is your vehicle currently parked at your home?")}
        image={`${process.env.PUBLIC_URL}/assets/images/${
          process.env.REACT_APP_ALT_ICONS_PATH || ""
        }Home.svg`}
      >
        <section>
          <IonRadioGroup
            className="parked-home-vehicle-question"
            name="parkedAtHome" // should have the same name as the form
            onIonChange={(e) => {
              onOptionSelected(e.detail.value);
              actions.updateLocationAdditionalInfo("");
            }}
            ref={parkedAtHomeRadioGroupRef}
            value={parkedAtHome}
          >
            <IonItem key={"yes"} className="options radio-as-button">
              <span className={"tab"}>{t("Yes")}</span>
              <IonRadio slot="end" value={true} />
            </IonItem>
            <IonItem key={"no"} className="options radio-as-button">
              <span className={"tab"}>{t("No")}</span>
              <IonRadio slot="end" value={false} />
            </IonItem>
          </IonRadioGroup>
          {FormValidation.getFormErrorMessage("parkedAtHome", formik, t)}
        </section>
        {parkedAtHome !== undefined && !parkedAtHome && (
          <section id={"locationBlock"} title={t("Provide your GPS location")}>
            <div className="title-container">
              <h2 className="layout-title">{t("Provide your GPS location")}</h2>
            </div>
            <div>
              <IonButton
                id="locate-me-now"
                color="primary"
                className="button-tcs locate-me-now-button cta"
                expand="block"
                onClick={() => handleLocateMeNow()}
              >
                <IonLabel>{t("Locate me now")}</IonLabel>
              </IonButton>
            </div>
            <div className={"hr"}>
              <span>{t("Or")}</span>
            </div>
            {isLoaded && !loadError && (
              <>
                <div className="autocomplete-container">
                  <div className="title-container">
                    <h2 className="layout-title">
                      {t(
                        "Enter an address or a point of interest near your location"
                      )}
                    </h2>
                  </div>
                  <Autocomplete
                    onLoad={(autocomplete) => onLoadAuto(autocomplete)}
                    fields={[
                      "geometry",
                      "formatted_address",
                      "address_components",
                      "name",
                    ]}
                    className="autocomplete"
                    onPlaceChanged={searchPlace}
                  >
                    <input
                      ref={locationInputRef}
                      name="autoComplete"
                      type="text"
                      defaultValue={formik.values.address}
                      placeholder={t("Type your current address not at home")}
                      className={classNames(
                        {
                          "tcs-invalid": FormValidation.getFormErrorMessage(
                            "address",
                            formik,
                            t
                          ),
                        },
                        "autocomplete-input"
                      )}
                    />
                  </Autocomplete>
                  {FormValidation.getFormErrorMessage("address", formik, t)}
                </div>
              </>
            )}
          </section>
        )}
        {parkedAtHome && (
          <section
            id={"confirmLocationBlock"}
            title={t("Provide your GPS location")}
          >
            {isLoaded && !loadError && (
              <>
                <div className="autocomplete-container">
                  <div className="title-container">
                    <h2 className="layout-title">
                      {t(
                        "Please confirm your location. You can edit it if it is incorrect"
                      )}
                      :
                    </h2>
                  </div>
                  <Autocomplete
                    onLoad={(autocomplete) => onLoadAuto(autocomplete)}
                    fields={[
                      "geometry",
                      "formatted_address",
                      "address_components",
                      "name",
                    ]}
                    className="autocomplete"
                    onPlaceChanged={searchPlace}
                  >
                    <input
                      ref={locationInputRef}
                      name="address"
                      type="text"
                      defaultValue={formik.values.address}
                      onChange={searchPlace}
                      placeholder={t("Type your current address at home")}
                      className={classNames(
                        {
                          "tcs-invalid": FormValidation.getFormErrorMessage(
                            "address",
                            formik,
                            t
                          ),
                        },
                        "autocomplete-input"
                      )}
                    />
                  </Autocomplete>
                  {FormValidation.getFormErrorMessage("address", formik, t)}
                </div>
              </>
            )}
          </section>
        )}
      </MainHeader>
      <MainFooter>
        <IonButton
          id="locate-me-back"
          className="button-tcs back"
          expand="block"
          onClick={() => history.back()}
        >
          <div className="wrapper">
            <IonIcon
              size="large"
              src={`${process.env.PUBLIC_URL}/assets/images/chevron-back.svg`}
            />
            <IonLabel>{t("Back")}</IonLabel>
            <div />
          </div>
        </IonButton>
        <IonButton
          id="locate-me-next"
          className="button-tcs cta"
          color="secondary"
          expand="block"
          onClick={() => handleOnNext()}
        >
          <div className="wrapper">
            <div />
            <IonLabel>{t("Next")}</IonLabel>
            <IonIcon
              size="large"
              src={`${process.env.PUBLIC_URL}/assets/images/chevron-forward.svg`}
            />
          </div>
        </IonButton>
      </MainFooter>

      {loading && <Loading />}
    </IonContent>
  );
};

export default LocateMeNow;
