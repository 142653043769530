// t('Less power / Engine stutters')
// t('Overheated / Visible smoke')
// t('Unusual noise')
// t('Other')

export enum EngineIncidentOptions {
  LESS_POWER = "Less power / Engine stutters",
  MOTOR_OVERHEATED_SMOKE = "Overheated / Visible smoke",
  UNUSUAL_NOISE = "Unusual noise",
  WARNING_LIGHT = "Warning light",
  OTHER = "Other",
}

export interface EngineDetails {
  breakdownType: string;
  additionalInformation: string;
}
