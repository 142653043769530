export class FormValidation {
  public static isFormFieldValid(name: string, formik: any): boolean {
    return !!(formik.touched[name] && formik.errors[name]);
  }

  public static getFormErrorMessage(
    name: string,
    formik: any,
    trans?: any
  ): false | JSX.Element {
    return (
      FormValidation.isFormFieldValid(name, formik) && (
        <small className="ion-error">{trans(formik.errors[name])}</small>
      )
    );
  }

  public static isFormValid(errors: any): boolean {
    return Object.keys(errors).length === 0;
  }

  public static getGeneralErrorMessage(
    errors: any,
    message: string
  ): false | JSX.Element {
    return (
      !FormValidation.isFormValid(errors) && (
        <small className="ion-error">{message}</small>
      )
    );
  }
}
