import "./Confirmation.scss";

import React, { useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { MainHeader } from "../Layout/Layout";
import { useStateMachine } from "little-state-machine";
import { IonContent, getPlatforms, isPlatform } from "@ionic/react";
import { CountryUtils } from "../../helpers/CountryUtils";
import i18n from "i18next";
import { de, enUS, fr, it } from "date-fns/esm/locale";
import { AppDownload } from "../AppDownload/AppDownload";
import { AppStoreLink } from "../../model/AppStore";

const Confirmation: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [phone, setPhone] = useState<string>();
  const [isAbroad, setIsAbroad] = useState<boolean>(false);

  const { state } = useStateMachine();

  const history = useHistory();
  const getFormatCaseNumber = () => {
    const str = state.steps.caseNumber ?? "";
    const parts = str.match(/.{1,3}/g);
    return parts ? parts.join(" ") : "";
  };

  const isImmobilizedInHighway = state.steps.immobilizedOnHighway;

  useEffect(() => {
    setPhone(getPhoneNumberBasedOnCountry);
    history.replace({ state: null });
    localStorage.setItem("alreadySubmittedCase", new Date().toString());
  }, []);

  const getPhoneNumberBasedOnCountry = (): string => {
    if (
      process.env.REACT_APP_PHONE_NUMBER_LOCALIZED &&
      process.env.REACT_APP_PHONE_NUMBER_LOCALIZED == "true"
    ) {
      setIsAbroad(false);
      switch (i18n.language) {
        case "fr":
          return process.env.REACT_APP_PHONE_NUMBER_FR
            ? process.env.REACT_APP_PHONE_NUMBER_FR.toString()
            : "";
        case "it":
          return process.env.REACT_APP_PHONE_NUMBER_IT
            ? process.env.REACT_APP_PHONE_NUMBER_IT.toString()
            : "";
        case "de":
          return process.env.REACT_APP_PHONE_NUMBER_DE
            ? process.env.REACT_APP_PHONE_NUMBER_DE.toString()
            : "";
        default:
          return process.env.REACT_APP_PHONE_NUMBER_EN
            ? process.env.REACT_APP_PHONE_NUMBER_EN.toString()
            : "";
      }
    }
    if (
      CountryUtils.isInSwitzerlandOrLiechtenstein(
        state.steps.locationFields.googleResult
      )
    ) {
      setIsAbroad(false);
      return process.env.REACT_APP_PHONE_NUMBER
        ? process.env.REACT_APP_PHONE_NUMBER.toString()
        : "";
    } else {
      setIsAbroad(true);
      return process.env.REACT_APP_PHONE_NUMBER_ABROAD
        ? process.env.REACT_APP_PHONE_NUMBER_ABROAD.toString()
        : "";
    }
  };

  const createStoreUrl = (
    client: string,
    mobilePlatform: "android" | "ios" | "desktop"
  ) => {
    switch (true) {
      case isPlatform("ios"):
        return process.env[`REACT_APP_${client}_STORE_IOS`] || "";
      case isPlatform("android"):
        return process.env[`REACT_APP_${client}_STORE_ANDROID`] || "";
      default:
        const lang = i18n.language;
        return (
          process.env[
            `REACT_APP_${client}_STORE_DESKTOP_${lang.toUpperCase()}`
          ] || ""
        );
    }
  };

  const appRedirectLinks = useMemo(() => {
    let mobilePlatform: "android" | "ios" | "desktop";

    const clients: string[] =
      (process.env.REACT_APP_MOBILE_APP_CLIENTS &&
        process.env.REACT_APP_MOBILE_APP_CLIENTS.split(",")) ||
      [];
    if (clients.length === 0) {
      return null;
    }
    switch (true) {
      case isPlatform("ios"):
        mobilePlatform = "ios";
        break;
      case isPlatform("android"):
        mobilePlatform = "android";
        break;
      default:
        mobilePlatform = "desktop";
        break;
    }

    const brands = clients.reduce((acc, currClient) => {
      return {
        ...acc,
        [currClient]: {
          url: createStoreUrl(currClient, mobilePlatform),
          icon: `${process.env.PUBLIC_URL}${
            process.env[`REACT_APP_${currClient}_STORE_ICON`]
          }`,
        },
      };
    }, {});
    const info: AppStoreLink = {
      mobilePlatform,
      brands,
    };
    return (
      <section>
        <AppDownload appIconDetails={info} />
      </section>
    );
  }, [i18n.language]);

  const highwayWarning = (
    <section>
      <div className="warning-card warning-confirmation">
        <div className="warning-img">
          <img
            alt="warning"
            src={`${process.env.PUBLIC_URL}/assets/images/${
              process.env.REACT_APP_ALT_ICONS_PATH || ""
            }Warning.svg`}
          />
        </div>
        <div className="warning-txt-confirmation">
          <ul>
            <li>
              <Trans i18nKey={"Move behind or alongside the safety barrier"} />
            </li>
            <li>
              <Trans i18nKey={"Place the breakdown triangle"} />
            </li>
            <li>
              <Trans i18nKey={"If possible, wait behind the safety barrier."} />
            </li>
            <li>
              <Trans i18nKey={"Make sure you never walk on"} />
            </li>
          </ul>
        </div>
      </div>
    </section>
  );

  return (
    <IonContent>
      <MainHeader
        title={t("Thank you for using TCS Online Assistance")}
        titleStyle={{ textAlign: "left" }}
      >
        <section>
          {!state.isBCMFallBack && (
            <>
              <p className="confirmation-information-txt">
                {t("Your case number is:")}
              </p>

              <h2 className="confirmation-information-number">
                {getFormatCaseNumber()}
              </h2>
              <p className="confirmation-information-txt">
                {state.summary?.schedule?.date
                  ? t("We have recorded your preferred date and time.")
                  : t(
                      "In a few moments you will receive an SMS with your estimated waiting time."
                    )}
              </p>
              <p className="confirmation-information-txt">
                {state.summary?.schedule?.date
                  ? t(
                      "Kindly be informed that one of our agents will contact you about one hour before to coordinate your breakdown service."
                    )
                  : t(
                      "Further information will also follow shortly. Please stay close to your phone in case we need to reach you."
                    )}
              </p>
            </>
          )}

          {state.isBCMFallBack && (
            <>
              <p className="confirmation-information-txt">
                {t("BCM_confirmation_body_1")}
              </p>
              <p className="confirmation-information-txt">
                {t("BCM_confirmation_body_2")}
              </p>
              {isImmobilizedInHighway && highwayWarning}
              {process.env.REACT_APP_INCIDENT_ONLINE_CLAIM && (
                <section className="incident-online-claim">
                  <p>{t("Does your vehicle have an insured damage")}</p>
                  <p>
                    <a
                      href={`${process.env.REACT_APP_INCIDENT_ONLINE_CLAIM}?lang=${i18n.language}`}
                    >
                      {t("Report it directly to Baloise")}
                    </a>
                  </p>
                </section>
              )}
              <p className="confirmation-bottom-txt extra-confirmation">
                <Trans
                  i18nKey="confirmationPhoneNumberBCM"
                  values={{ phoneNumber: phone }}
                  defaults="If you are not contacted within the next 30 minutes, please call {{phoneNumber}} to speak to an agent."
                ></Trans>
              </p>
            </>
          )}
          {!state.isBCMFallBack && (
            <>
              {isImmobilizedInHighway && highwayWarning}
              {process.env.REACT_APP_INCIDENT_ONLINE_CLAIM && (
                <section className="incident-online-claim">
                  <p>{t("Does your vehicle have an insured damage")}</p>
                  <p>
                    <a
                      href={`${process.env.REACT_APP_INCIDENT_ONLINE_CLAIM}?lang=${i18n.language}`}
                    >
                      {t("Report it directly to Baloise")}
                    </a>
                  </p>
                </section>
              )}
              <p className="confirmation-bottom-txt">
                {!isAbroad &&
                  (i18n.language == "en" ||
                    !process.env.REACT_APP_PHONE_NUMBER_LOCALIZED ||
                    process.env.REACT_APP_PHONE_NUMBER_LOCALIZED ===
                      "false") && (
                    <Trans i18nKey="If you do not receive and SMS within 1 minute, please call REACT_APP_PHONE_NUMBER to speak to an agent directly.">
                      {" "}
                      <a href={`tel:` + phone}>{phone}</a>
                    </Trans>
                  )}
                {!isAbroad &&
                  process.env.REACT_APP_PHONE_NUMBER_LOCALIZED &&
                  process.env.REACT_APP_PHONE_NUMBER_LOCALIZED === "true" &&
                  i18n.language == "fr" && (
                    <Trans i18nKey="If you do not receive and SMS within 1 minute, please call REACT_APP_PHONE_NUMBER_FR to speak to an agent directly.">
                      {" "}
                      <a href={`tel:` + phone}>{phone}</a>
                    </Trans>
                  )}
                {!isAbroad &&
                  process.env.REACT_APP_PHONE_NUMBER_LOCALIZED &&
                  process.env.REACT_APP_PHONE_NUMBER_LOCALIZED === "true" &&
                  i18n.language == "de" && (
                    <Trans i18nKey="If you do not receive and SMS within 1 minute, please call REACT_APP_PHONE_NUMBER_DE to speak to an agent directly.">
                      {" "}
                      <a href={`tel:` + phone}>{phone}</a>
                    </Trans>
                  )}
                {!isAbroad &&
                  process.env.REACT_APP_PHONE_NUMBER_LOCALIZED &&
                  process.env.REACT_APP_PHONE_NUMBER_LOCALIZED === "true" &&
                  i18n.language == "it" && (
                    <Trans i18nKey="If you do not receive and SMS within 1 minute, please call REACT_APP_PHONE_NUMBER_IT to speak to an agent directly.">
                      {" "}
                      <a href={`tel:` + phone}>{phone}</a>
                    </Trans>
                  )}
                {isAbroad && (
                  <Trans i18nKey="If you do not receive and SMS within 1 minute, please call REACT_APP_PHONE_NUMBER_ABROAD to speak to an agent directly.">
                    {" "}
                    <a href={`tel:` + phone}>{phone}</a>
                  </Trans>
                )}
              </p>
            </>
          )}
        </section>

        {process.env.REACT_APP_MOBILE_APP_CLIENTS && appRedirectLinks}
      </MainHeader>
    </IonContent>
  );
};

export default Confirmation;
