import "./CaseAlreadySubmitted.scss";

import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { MainHeader } from "../Layout/Layout";
import { IonContent } from "@ionic/react";
import { CountryUtils } from "../../helpers/CountryUtils";
import i18n from "i18next";

const CaseAlreadySubmitted: React.FC = () => {
  const { t } = useTranslation();

  const history = useHistory();

  useEffect(() => {
    history.replace({ state: null });
    localStorage.setItem("appShouldReinitialize", "true");
  }, []);

  const getPhoneNumberForLocalizationCalls = (): string => {
    if (
      process.env.REACT_APP_PHONE_NUMBER_LOCALIZED &&
      process.env.REACT_APP_PHONE_NUMBER_LOCALIZED == "true"
    ) {
      switch (i18n.language) {
        case "fr":
          return process.env.REACT_APP_PHONE_NUMBER_FR
            ? process.env.REACT_APP_PHONE_NUMBER_FR.toString()
            : "";
        case "it":
          return process.env.REACT_APP_PHONE_NUMBER_IT
            ? process.env.REACT_APP_PHONE_NUMBER_IT.toString()
            : "";
        case "de":
          return process.env.REACT_APP_PHONE_NUMBER_DE
            ? process.env.REACT_APP_PHONE_NUMBER_DE.toString()
            : "";
        default:
          return process.env.REACT_APP_PHONE_NUMBER_EN
            ? process.env.REACT_APP_PHONE_NUMBER_EN.toString()
            : "";
      }
    } else {
      return process.env.REACT_APP_PHONE_NUMBER
        ? process.env.REACT_APP_PHONE_NUMBER.toString()
        : "";
    }
  };

  return (
    <IonContent>
      <MainHeader
        title={t("Your case has been submitted succesfully")}
        titleStyle={{ textAlign: "left" }}
      >
        <section>
          <p className="confirmation-information-txt">
            {process.env.REACT_APP_PHONE_NUMBER_LOCALIZED &&
              process.env.REACT_APP_PHONE_NUMBER_LOCALIZED == "true" &&
              i18n.language === "en" && (
                <Trans i18nKey="Help will be organized. If something has changed, you don't need help anymore or you have a new incident, please call REACT_APP_PHONE_NUMBER_EN and hold the line to speak to one of our agents.">
                  {" "}
                  <a
                    href={
                      `tel:` +
                      getPhoneNumberForLocalizationCalls().replace(/\s/g, "")
                    }
                  >
                    {getPhoneNumberForLocalizationCalls()}
                  </a>
                </Trans>
              )}
            {process.env.REACT_APP_PHONE_NUMBER_LOCALIZED &&
              process.env.REACT_APP_PHONE_NUMBER_LOCALIZED == "true" &&
              i18n.language === "fr" && (
                <Trans i18nKey="Help will be organized. If something has changed, you don't need help anymore or you have a new incident, please call REACT_APP_PHONE_NUMBER_FR and hold the line to speak to one of our agents.">
                  {" "}
                  <a
                    href={
                      `tel:` +
                      getPhoneNumberForLocalizationCalls().replace(/\s/g, "")
                    }
                  >
                    {getPhoneNumberForLocalizationCalls()}
                  </a>
                </Trans>
              )}
            {process.env.REACT_APP_PHONE_NUMBER_LOCALIZED &&
              process.env.REACT_APP_PHONE_NUMBER_LOCALIZED == "true" &&
              i18n.language === "de" && (
                <Trans i18nKey="Help will be organized. If something has changed, you don't need help anymore or you have a new incident, please call REACT_APP_PHONE_NUMBER_DE and hold the line to speak to one of our agents.">
                  {" "}
                  <a
                    href={
                      `tel:` +
                      getPhoneNumberForLocalizationCalls().replace(/\s/g, "")
                    }
                  >
                    {getPhoneNumberForLocalizationCalls()}
                  </a>
                </Trans>
              )}
            {process.env.REACT_APP_PHONE_NUMBER_LOCALIZED &&
              process.env.REACT_APP_PHONE_NUMBER_LOCALIZED == "true" &&
              i18n.language === "it" && (
                <Trans i18nKey="Help will be organized. If something has changed, you don't need help anymore or you have a new incident, please call REACT_APP_PHONE_NUMBER_IT and hold the line to speak to one of our agents.">
                  {" "}
                  <a
                    href={
                      `tel:` +
                      getPhoneNumberForLocalizationCalls().replace(/\s/g, "")
                    }
                  >
                    {getPhoneNumberForLocalizationCalls()}
                  </a>
                </Trans>
              )}
            {(!process.env.REACT_APP_PHONE_NUMBER_LOCALIZED ||
              process.env.REACT_APP_PHONE_NUMBER_LOCALIZED == "false") &&
              process.env.REACT_APP_PHONE_NUMBER &&
              process.env.REACT_APP_PHONE_NUMBER_ABROAD &&
              process.env.REACT_APP_PHONE_NUMBER ==
                process.env.REACT_APP_PHONE_NUMBER_ABROAD && (
                <Trans i18nKey="Help will be organized. If something has changed, you don't need help anymore or you have a new incident, please call REACT_APP_PHONE_NUMBER and hold the line to speak to one of our agents.">
                  {" "}
                  <a
                    href={
                      `tel:` +
                      process.env.REACT_APP_PHONE_NUMBER.replace(/\s/g, "")
                    }
                  >
                    {process.env.REACT_APP_PHONE_NUMBER}
                  </a>
                </Trans>
              )}
            {(!process.env.REACT_APP_PHONE_NUMBER_LOCALIZED ||
              process.env.REACT_APP_PHONE_NUMBER_LOCALIZED == "false") &&
              process.env.REACT_APP_PHONE_NUMBER &&
              process.env.REACT_APP_PHONE_NUMBER_ABROAD &&
              process.env.REACT_APP_PHONE_NUMBER !==
                process.env.REACT_APP_PHONE_NUMBER_ABROAD && (
                <Trans i18nKey="Help will be organized. If something has changed, you don't need help anymore or you have a new incident, please call REACT_APP_PHONE_NUMBER within Switzerland or REACT_APP_PHONE_NUMBER_ABROAD abroad and hold the line to speak to one of our agents.">
                  {" "}
                  <a
                    href={
                      `tel:` +
                      process.env.REACT_APP_PHONE_NUMBER.replace(/\s/g, "")
                    }
                  >
                    {process.env.REACT_APP_PHONE_NUMBER}
                  </a>{" "}
                  <a
                    href={
                      `tel:` +
                      process.env.REACT_APP_PHONE_NUMBER_ABROAD.replace(
                        /\s/g,
                        ""
                      )
                    }
                  >
                    {process.env.REACT_APP_PHONE_NUMBER_ABROAD}
                  </a>
                </Trans>
              )}
          </p>
        </section>
      </MainHeader>
    </IonContent>
  );
};

export default CaseAlreadySubmitted;
