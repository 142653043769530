import "./ValidateAuthentication.scss";

import {
  IonButton,
  IonContent,
  IonIcon,
  IonInput,
  IonLabel,
  IonLoading,
  IonToast,
  isPlatform,
} from "@ionic/react";
import { useHistory } from "react-router";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { MainFooter, MainHeader } from "../../Layout/Layout";
import { RoutePath } from "../../../model/RoutePath.enum";
import classNames from "classnames/bind";
import { FormValidation } from "../../../helpers/FormValidation";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useStateMachine } from "little-state-machine";
import {
  updateOneTimePassword,
  updateSteps,
  updateToken,
} from "../../../state/updateState";
import {
  OneTimePassword,
  OneTimePasswordParams,
  OneTimePasswordResponseError,
} from "../../../model/OneTimePassword/one-time-password-params";
import { Api } from "../../../api/api";

const RATE_LIMIT_TIMER_SECONDS = 60;

export interface ValidationAuthenticationFields {
  code: string;
}

const ValidateAuthentication: React.FC = () => {
  const { t, i18n } = useTranslation();
  const codeInputRef = useRef<HTMLIonInputElement>(null);
  const [error, setError] = useState<string>();
  const { actions, state } = useStateMachine({
    updateSteps,
    updateOneTimePassword,
    updateToken,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState<string>();
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [retries, setRetries] = useState<number>(0);
  const history = useHistory();
  const [isCounting, setIsCounting] = useState<boolean | null>();

  useEffect(() => {
    if (isPlatform("desktop")) {
      setTimeout(() => codeInputRef?.current?.setFocus(), 250);
    }
  }, []);

  const [seconds, setSeconds] = useState(RATE_LIMIT_TIMER_SECONDS);
  useEffect(() => {
    if (isCounting) {
      if (seconds > 0) {
        setTimeout(() => setSeconds(seconds - 1), 1000);
      } else {
        setIsCounting(false);
        setSeconds(RATE_LIMIT_TIMER_SECONDS);
      }
    }
  });

  const requiredMessage = t("Code is a required field");
  const validationSchema = Yup.object({
    code: Yup.string().required(requiredMessage),
  });

  const displayErrorMessage = (err: string) => {
    setError(err);
    setDisplayError(true);
  };

  useEffect(() => {
    if (retries >= 3) {
      displayErrorMessage(
        t("Please retry entering your phone number again or call the hotline")
      );
      setTimeout(
        () =>
          history.push(
            `${RoutePath.REQUEST}/${RoutePath.REQUEST_AUTHENTICATION}`
          ),
        3000
      );
    } else if (retries > 0) {
      displayErrorMessage(t("Please retry again"));
    }
  }, [retries]);

  const formik = useFormik({
    initialValues: { code: "" },
    validationSchema,
    validate: () => {
      if (!formik.values.code && codeInputRef && codeInputRef.current) {
        codeInputRef.current.setFocus();
      }
    },
    onSubmit: (values: ValidationAuthenticationFields) => {
      sendCode(values);
    },
  });

  const sendCode = (values: ValidationAuthenticationFields) => {
    setLoadingMessage(t("Validating..."));
    setLoading(true);
    const otp = {
      expirationDate: state.cachedPasswords?.expirationDate,
      phoneNumber: state.cachedPasswords?.phoneNumber,
      hashCode: state.cachedPasswords?.hashCodes,
      language: i18n.language,
      otpNumber: values.code,
    } as OneTimePasswordParams;

    Api.sendCode(otp)
      .then((res) => {
        actions.updateToken(res.data);
        setLoading(false);
        history.push(`${RoutePath.REQUEST}/${RoutePath.HIGHWAY_IMMOBILIZED}`);
      })
      .catch((error) => {
        setLoading(false);
        if (error && error.response && error.response.data) {
          const err = error.response.data as OneTimePasswordResponseError;
          console.error(err);
          if (err.httpCode === "500") {
            displayErrorMessage(t("Error on server otp"));
          }
        }
        setRetries((prevState) => prevState + 1);
      });
  };

  const resendCode = () => {
    if (!state.cachedPasswords?.phoneNumber) {
      history.push(`${RoutePath.REQUEST}/${RoutePath.REQUEST_AUTHENTICATION}`);
      return;
    }

    if (isCounting) {
      setError(t("Retry again in one minute"));
      setDisplayError(true);
      return;
    }

    setLoadingMessage(t("Resending code..."));
    setLoading(true);
    const params = {
      language: i18n.language,
      phoneNumber: state.cachedPasswords?.phoneNumber,
    } as OneTimePasswordParams;

    Api.sendPhoneNumber(params)
      .then((res) => {
        const otp = {
          ...res.data,
          phoneNumber: state.cachedPasswords!.phoneNumber,
          hashCode: res.data.hashCode,
        };
        actions.updateOneTimePassword(otp);
        setLoading(false);
        setIsCounting(true);
      })
      .catch((error) => {
        setLoading(false);
        if (error && error.response && error.response.data) {
          console.log(error.response.data);
          displayErrorMessage(error.response.data.httpMessage);
          setIsCounting(true);
        }
      });
  };
  return (
    <IonContent>
      <MainHeader
        title={t("Enter the code we sent you below")}
        titleStyle={{ textAlign: "left" }}
      >
        <div className="validate-authentication-container">
          <section>
            <h6>{t("Code")}</h6>
            <IonInput
              ref={codeInputRef}
              autocomplete="one-time-code"
              className={classNames({
                "tcs-invalid": FormValidation.getFormErrorMessage(
                  "code",
                  formik,
                  t
                ),
              })}
              inputmode="numeric"
              id="code"
              name="code"
              value={formik.values.code}
              onIonChange={formik.handleChange}
              autofocus
              onKeyPress={(e: any) => {
                if (e.code === "Enter") {
                  formik.handleSubmit();
                }
              }}
            />
            {FormValidation.getFormErrorMessage("code", formik, t)}
          </section>

          {!isCounting && (
            <section>
              <div className="code-information">{t("Didn't get a code?")}</div>
              <div className="code-information">
                <span className="resend-code" onClick={() => resendCode()}>
                  {t("Click here to try and send it again")}
                </span>
              </div>
            </section>
          )}

          {isCounting && (
            <section>
              <div className="code-information">
                <span>
                  {t("Try again in ")}
                  {seconds}
                  &nbsp;{t("seconds")}
                </span>
              </div>
            </section>
          )}
        </div>
      </MainHeader>
      <MainFooter>
        <IonButton
          id="va-back"
          className="button-tcs outline back"
          expand="block"
          onClick={() => history.back()}
        >
          <div className="wrapper">
            <IonIcon
              size="large"
              src={`${process.env.PUBLIC_URL}/assets/images/chevron-back.svg`}
            />
            <IonLabel>{t("Back")}</IonLabel>
            <div />
          </div>
        </IonButton>
        <IonButton
          id="va-next"
          className="button-tcs cta"
          color="primary"
          expand="block"
          onClick={() => formik.handleSubmit()}
        >
          <div className="wrapper">
            <div />
            <IonLabel>{t("Next")}</IonLabel>
            <IonIcon
              slot="end"
              size="large"
              src={`${process.env.PUBLIC_URL}/assets/images/chevron-forward.svg`}
            />
          </div>
        </IonButton>
      </MainFooter>
      <IonLoading
        isOpen={loading}
        onDidDismiss={() => setLoading(false)}
        message={loadingMessage}
      />
      <IonToast
        position="top"
        isOpen={displayError}
        onDidDismiss={() => setDisplayError(false)}
        message={error}
        duration={3000}
      />
    </IonContent>
  );
};

export default ValidateAuthentication;
