import { Country } from "../model/Country";
import { CaseUtils, NameSize } from "./CaseUtils";
import countries from "../assets/utils/emergency-country-codes";
import countryCodes from "../assets/utils/country-code";

export class CountryUtils {
  public static findCountryByFullName(
    countryFullName: string
  ): Country | undefined {
    return countries.find(
      (c) =>
        c.country_en === countryFullName ||
        c.country_de === countryFullName ||
        c.country_it === countryFullName ||
        c.country_fr === countryFullName
    );
  }

  public static isSwitzerlandCountry(
    countryFullName: string
  ): boolean | undefined {
    const foundCountry = this.findCountryByFullName(countryFullName);
    return foundCountry && foundCountry.country_en === "Switzerland";
  }

  public static isInSwitzerlandOrLiechtenstein(
    googleResult: google.maps.GeocoderResult | undefined
  ): boolean {
    const countryCode = this.getCountryCodeFromGoogleCode(googleResult);

    if (!countryCode) {
      return false;
    }

    return !!(countryCode && (countryCode === "LI" || countryCode === "CH"));
  }

  public static isInSwitzerland(
    googleResult: google.maps.GeocoderResult | undefined
  ): boolean {
    const countryCode = this.getCountryCodeFromGoogleCode(googleResult);

    if (!countryCode) {
      return false;
    }

    return !!(countryCode && countryCode === "CH");
  }

  public static getCountryCodeByName(country: string): string {
    return (
      Object.keys(countryCodes).find((key) => countryCodes[key] === country) ??
      ""
    );
  }

  public static getCountryCodeByNonEnglishName(country: string): string {
    const englishName = this.findCountryByFullName(country);
    if (englishName) {
      return (
        Object.keys(countryCodes).find(
          (key) => countryCodes[key] === englishName.country_en
        ) ?? ""
      );
    }
    return "";
  }

  private static getCountryCodeFromGoogleCode(
    googleResult: google.maps.GeocoderResult | undefined
  ): string | undefined {
    const googleCode = googleResult
      ? CaseUtils.getAddressComponentFromGoogleResult(
          googleResult,
          "country",
          NameSize.SHORT_NAME
        )
      : undefined;

    if (!googleCode) {
      return undefined;
    }

    return Object.keys(countryCodes).find((value) => value == googleCode);
  }
}
