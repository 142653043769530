import {
  Case,
  Client,
  Coordinate,
  Location,
  Person,
  RSAServiceDelivery,
} from "../api/models/Case";
import { DigitalIntakeSteps } from "../types/global";
import { PersonDetail } from "../model/PersonDetail";
import { format, parse, subHours } from "date-fns";
import { DateUtils } from "./DateUtils";
import { LocationFields } from "../components/Location/GeoLocation/GeoLocation";
import { Appointment } from "../model/Appointment";
import { IncidentUtils, SerializedIncidents } from "./IncidentUtils";
import { BicycleDetails } from "../model/Incident/BicycleDetails";
import { IncidentType } from "../model/IncidentType.enum";
import { StarterIssueDetails } from "../model/Incident/StarterIssueDetails";
import { FuelDetails } from "../model/Incident/FuelDetails";
import { FluidLossDetails } from "../model/Incident/FluidLossDetails";
import { EngineDetails } from "../model/Incident/EngineDetails";
import { WheelAndTireDetails } from "../model/Incident/WheelAndTireDetails";
import { DoorsAndKeysDetails } from "../model/Incident/DoorsAndKeysDetails";
import { StarterIssueUtils } from "./Incidents/StarterIssueUtils";
import { EngineUtils } from "./Incidents/EngineUtils";
import { DoorAndKeysUtils } from "./Incidents/DoorAndKeysUtils";
import { FuelUtils } from "./Incidents/FuelUtils";
import { FluidLossUtils } from "./Incidents/FluidLossUtils";
import { WheelAndTireUtils } from "./Incidents/WheelAndTireUtils";
import { OtherUtils } from "./Incidents/OtherUtils";
import { OtherDetails } from "../model/Incident/OtherDetails";
import { BicycleUtils } from "./Incidents/BicycleUtils";
import { VehicleDetailsUtils } from "./VehicleDetailsUtils";
import { AccidentUtils } from "./Incidents/AccidentUtils";
import { ImmobilizationType } from "../model/ImmobilizationType.enum";
import { PersonalDetailsUtils } from "./PersonalDetailsUtils";
import { CountryUtils } from "./CountryUtils";
import { LocationUtils } from "./LocationUtils";
import { OneTimePassword } from "../model/OneTimePassword/one-time-password-params";
import { AbroadCaseDetail } from "../model/AbroadCaseDetail";
import { VehicleDetail } from "../model/VehicleDetail";
import { PolicyHolderType } from "../model/PolicyHolderType.enum";
import { VehicleInfos } from "../api/models/VehicleInfos";
import { IncidentLocationAddressType } from "../model/IncidentLocationAddressType.enum";

export enum NameSize {
  LONG_NAME = "long_name",
  SHORT_NAME = "short_name",
}
const charactersToReplace = {
  "’": "'",
  Ā: "A",
  ā: "a",
  Ē: "E",
  ē: "e",
  Ė: "E",
  ė: "e",
  Ī: "I",
  ī: "i",
  İ: "I",
  Ō: "O",
  ō: "o",
  Ū: "U",
  ū: "u",
};

export class CaseUtils {
  public static getSerializedCase(
    type: IncidentType | undefined,
    incident: SerializedIncidents | undefined,
    immobilizationType: ImmobilizationType,
    appointment: Appointment | undefined,
    personalDetails: PersonDetail,
    immobilizedOnHighway: boolean | undefined
  ): Partial<Case> | undefined {
    //If is an accident the incident is empty
    if (!type) {
      return undefined;
    }

    switch (type) {
      case IncidentType.START_ISSUE:
        return StarterIssueUtils.serialize(
          incident as StarterIssueDetails,
          appointment,
          personalDetails,
          immobilizationType,
          immobilizedOnHighway
        );
      case IncidentType.ENGINE:
        return EngineUtils.serialize(
          incident as EngineDetails,
          appointment,
          personalDetails,
          immobilizationType,
          immobilizedOnHighway
        );
      case IncidentType.DOOR_AND_KEYS:
        return DoorAndKeysUtils.serialize(
          incident as DoorsAndKeysDetails,
          immobilizationType,
          appointment,
          personalDetails,
          immobilizedOnHighway
        );
      case IncidentType.FUEL:
        return FuelUtils.serialize(
          incident as FuelDetails,
          appointment,
          personalDetails,
          immobilizationType,
          immobilizedOnHighway
        );
      case IncidentType.FLUID_LOSS:
        return FluidLossUtils.serialize(
          incident as FluidLossDetails,
          appointment,
          personalDetails,
          immobilizationType,
          immobilizedOnHighway
        );
      case IncidentType.WHEEL_AND_TIRE:
        return WheelAndTireUtils.serialize(
          incident as WheelAndTireDetails,
          appointment,
          personalDetails,
          immobilizationType,
          immobilizedOnHighway
        );
      case IncidentType.OTHER:
        return OtherUtils.serialize(
          incident as OtherDetails,
          appointment,
          personalDetails,
          immobilizationType,
          immobilizedOnHighway
        );
      case IncidentType.BICYCLE:
        return BicycleUtils.serialize(
          incident as BicycleDetails,
          appointment,
          personalDetails,
          immobilizationType
        );
      case IncidentType.ACCIDENT:
        return AccidentUtils.serialize(
          personalDetails,
          appointment,
          immobilizedOnHighway,
          immobilizationType
        );
      default:
        return undefined;
    }
  }

  private static hasAppointment(appointment: Appointment): boolean {
    return !!(appointment && appointment.date && appointment.time);
  }

  private static appendAbroadCaseComments(
    finalCase: Partial<Case> | undefined,
    abroadCaseDetail: AbroadCaseDetail,
    vehicleDetails: VehicleDetail
  ): Partial<Case> | undefined {
    if (finalCase?.Comment) {
      finalCase.Comment = finalCase.Comment.concat("; ");
      if (abroadCaseDetail.noReturnDate === false) {
        finalCase.Comment = finalCase.Comment.concat(
          "Geplantes Rückkehrdatum : " + abroadCaseDetail.returnDate + "; "
        );
      } else {
        finalCase.Comment = finalCase.Comment.concat(
          "Geplantes Rückkehrdatum : unbekannt; "
        );
      }
      finalCase.Comment = finalCase.Comment.concat(
        "Der Kunde hat eine Kreditkarte : " +
          (abroadCaseDetail.hasCreditCard ? "Ja" : "Nein") +
          "; "
      );
      finalCase.Comment = finalCase.Comment.concat(
        "Fahrgastzahlen : " + vehicleDetails.howManyPassengers + "; "
      );
      if (vehicleDetails.additionalInformations != "") {
        finalCase.Comment = finalCase.Comment.concat(
          "Zusätzliche Informationen : " +
            vehicleDetails.additionalInformations +
            "; "
        );
      }
    }
    return finalCase;
  }

  public static createCase(
    summary: DigitalIntakeSteps,
    lang: string,
    otp: OneTimePassword | undefined,
    isAbroadCase: boolean | undefined,
    MOFISVehicle: VehicleInfos | undefined,
    locationAdditionalInfo?: string
  ): Partial<Case> {
    let finalCase = this.getSerializedCase(
      summary.incidentType,
      summary.incidentDetails,
      summary.immobilizationType!,
      summary.schedule,
      summary.personalDetails,
      summary.immobilizedOnHighway
    );

    const serializedVehicle =
      summary.incidentType !== IncidentType.BICYCLE &&
      VehicleDetailsUtils.serializeVehicleDefaults(
        summary.vehicleDetails,
        lang
      );
    if (
      finalCase &&
      finalCase.ItemsUsedList &&
      finalCase.ItemsUsedList?.length > 0
    ) {
      finalCase.ItemsUsedList[0] = {
        ...finalCase.ItemsUsedList[0],
        ...serializedVehicle,
      };
    }
    if (
      finalCase &&
      finalCase.ItemsUsedList &&
      finalCase.ItemsUsedList?.length > 0 &&
      MOFISVehicle
    ) {
      finalCase.ItemsUsedList[1] = {
        TypeOfItem: "vehiclemofis",
        ItemType: "VehicleMofis",
        MatriculeNumber: MOFISVehicle.matriculeNumber,
        LicencePlate: MOFISVehicle.licensePlate,
        EngineTypeCode: MOFISVehicle.engineTypeCode,
        ...(finalCase.ItemsUsedList[0].VIN && {
          OfficialRegistrationNumber: finalCase.ItemsUsedList[0].VIN,
        }),
      };
    }

    if (isAbroadCase) {
      this.appendAbroadCaseComments(
        finalCase,
        summary.abroadCaseDetail,
        summary.vehicleDetails
      );
    }
    const localDate = new Date();
    const swissTimezone = "Europe/Zurich";

    const isAutoCoverageCheckServiceDelivery =
      process.env.REACT_APP_USE_AUTO_COVERAGE_CHECK === "true";

    const shouldReturnEmptyServiceDelivery =
      isAutoCoverageCheckServiceDelivery &&
      !this.hasAppointment(summary.schedule);

    const toSwissTime = (date: Date, timezone: string) => {
      const swissDateString = date.toLocaleString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hourCycle: "h23",
        timeZone: timezone,
      });
      const [month, day, year, time] = swissDateString.split(/[\s,/]+/);
      return `${year}-${month}-${day}T${time}`;
    };

    const UTCCaseDate = toSwissTime(localDate, swissTimezone);
    let rawCase = {
      ...finalCase,
      IncidentLocation: this.buildIncidentLocation(
        summary.locationFields,
        summary.immobilizedOnHighway,
        locationAdditionalInfo
      ),
      ServiceDeliveryList: shouldReturnEmptyServiceDelivery
        ? []
        : [
            this.serializeServiceDelivery(
              summary.schedule,
              summary.personalDetails,
              summary.locationFields,
              isAutoCoverageCheckServiceDelivery
            ),
          ],
      IncidentDateTime: UTCCaseDate,
      Caller: this.buildPerson(summary.personalDetails, lang, otp?.phoneNumber),
      CaseClient: this.buildCaseClient(summary.personalDetails, lang),
      ContextList: [
        {
          ContextElementTypeKey: "X-CONSUMER-PROCESS",
          ContextElementValue: "RSA",
        },
      ],
    };

    // For policyHolder description
    if (
      process.env.REACT_APP_ADDITIONAL_POLICY_HOLDER_INFORMATION &&
      process.env.REACT_APP_ADDITIONAL_POLICY_HOLDER_INFORMATION === "true"
    ) {
      rawCase = this.policyHolderManagement(rawCase, summary);
    }
    return JSON.parse(
      JSON.stringify(rawCase).replace(
        new RegExp("[" + Object.keys(charactersToReplace).join("") + "]", "g"),
        (m) => charactersToReplace[m as keyof typeof charactersToReplace]
      )
    );
  }
  public static policyHolderManagement(
    rawCase: any,
    summary: DigitalIntakeSteps
  ) {
    switch (summary.personalDetails.policyHolderType) {
      case PolicyHolderType.COMPANY:
        rawCase.CaseClient.Person.Name =
          summary.personalDetails.policyHolderCompanyName;
        rawCase.CaseClient.Person.Firstname = "";
        break;
      case PolicyHolderType.ANOTHER_PERSON:
        rawCase.CaseClient.Person.Name =
          summary.personalDetails.policyHolderLastName;
        rawCase.CaseClient.Person.Firstname =
          summary.personalDetails.policyHolderFirstName;
        break;
      default:
        break;
    }
    return rawCase;
  }

  public static serializeServiceDelivery(
    data: Appointment,
    personalDetails: PersonDetail,
    location: LocationFields,
    isAutoCoverageCheckServiceDelivery: boolean
  ): Partial<RSAServiceDelivery> {
    const date = this.hasAppointment(data)
      ? parse(`${data.date} ${data.time}`, "dd/MM/yyyy HH:mm", new Date())
      : undefined;

    if (isAutoCoverageCheckServiceDelivery) {
      return {
        TypeOfService: "",
        Appointment: date
          ? format(subHours(date, 1), DateUtils.DATEFNS_DATE_TIME_ISO_8601)
          : undefined,
      };
    }

    const comment =
      PersonalDetailsUtils.serializeCoverageComment(personalDetails);
    if (
      process.env.REACT_APP_PERSONAL_POLICE_NUMBER &&
      process.env.REACT_APP_PERSONAL_POLICE_NUMBER == "true" &&
      personalDetails.personalReference != ""
    ) {
      return {
        ...IncidentUtils.getServiceType(location),
        Appointment: date
          ? format(subHours(date, 1), DateUtils.DATEFNS_DATE_TIME_ISO_8601)
          : undefined,
        Coverage: {
          ClientNB: personalDetails.personalReference.trim().replace(/\./g, ""),
          Comment:
            comment.length > 0 ? comment.filter(Boolean).join("; ") : undefined,
        },
      };
    } else {
      return {
        ...IncidentUtils.getServiceType(location),
        Appointment: date
          ? format(subHours(date, 1), DateUtils.DATEFNS_DATE_TIME_ISO_8601)
          : undefined,
        Coverage: {
          Comment:
            comment.length > 0 ? comment.filter(Boolean).join("; ") : undefined,
        },
      };
    }
  }

  public static getAddressComponentFromGoogleResult(
    googleResult: google.maps.GeocoderResult,
    type: string,
    nameSize: NameSize
  ): string {
    const address = googleResult.address_components.find((value) =>
      value.types.includes(type)
    );
    return address ? address[nameSize] : "";
  }

  private static buildCaseClient(
    personalDetails: PersonDetail,
    lang: string
  ): Partial<Client> {
    return {
      ReferenceList: [
        {
          Reference: this.serializeReference(personalDetails),
          Source: "ONLINE_ASSISTANCE",
          Active: true,
        },
      ],
      Person: this.buildPerson(personalDetails, lang),
    };
  }

  private static serializeReference(data: PersonDetail): string | undefined {
    if (data.personalReference && data.personalReference.trim() !== "") {
      return data.personalReference.trim().replace(/\./g, "");
    } else {
      return data.salesForceResponse?.partyRoles?.length &&
        data.salesForceResponse.partyRoles.length > 0
        ? data.salesForceResponse?.partyRoles[0].personalReference
        : undefined;
    }
  }

  private static buildPerson(
    personalDetails: PersonDetail,
    lang: string,
    phoneFromToken?: string
  ): Partial<Person> {
    return {
      TypeOfPerson: "naturalperson",
      PersonType: "348001",
      BackOfficeInformation:
        process.env.REACT_APP_PERSONAL_POLICE_NUMBER &&
        process.env.REACT_APP_PERSONAL_POLICE_NUMBER == "true"
          ? undefined
          : personalDetails.personalReference
          ? {
              SalesforceReference: personalDetails.personalReference
                .trim()
                .replace(/\./g, ""),
            }
          : undefined,
      Name: personalDetails.lastName,
      Firstname: personalDetails.firstName,
      MobileNumber1: phoneFromToken ?? personalDetails.phone,
      Address: {
        Street: personalDetails.streetAddress,
        HouseNb: personalDetails.streetNumber,
        ZipCode: personalDetails.postalCode,
        City: personalDetails.city,
        Country: CountryUtils.getCountryCodeByName(personalDetails.country),
      },
      Language: lang,
    };
  }

  private static getCoordinates(
    location: LocationFields
  ): Coordinate | undefined {
    if (location.location && location.location.lat && location.location.lng) {
      return {
        Latitude: location.location.lat,
        Longitude: location.location.lng,
      };
    }
    return undefined;
  }

  private static buildIncidentLocation(
    locationFields: LocationFields,
    immobilizedOnHighway: boolean | undefined,
    locationAdditionalInfo?: string
  ): Partial<Location> {
    const { googleResult } = locationFields;

    const incidentLocation = {
      Comment: LocationUtils.serializeLocationComment(
        immobilizedOnHighway,
        locationFields,
        locationAdditionalInfo
      ),
      Address: {
        Country: googleResult
          ? this.getAddressComponentFromGoogleResult(
              googleResult,
              "country",
              NameSize.SHORT_NAME
            )
          : undefined,
        City: googleResult
          ? this.getAddressComponentFromGoogleResult(
              googleResult,
              "locality",
              NameSize.LONG_NAME
            )
          : undefined,
        ZipCode: googleResult
          ? this.getAddressComponentFromGoogleResult(
              googleResult,
              "postal_code",
              NameSize.LONG_NAME
            )
          : undefined,
        Street: googleResult
          ? this.getAddressComponentFromGoogleResult(
              googleResult,
              "route",
              NameSize.LONG_NAME
            )
          : undefined,
        HouseNb: googleResult
          ? this.getAddressComponentFromGoogleResult(
              googleResult,
              "street_number",
              NameSize.LONG_NAME
            )
          : undefined,
        Coordinate: this.getCoordinates(locationFields),
        Canton: CountryUtils.isInSwitzerland(googleResult)
          ? this.getAddressComponentFromGoogleResult(
              googleResult!,
              "administrative_area_level_1",
              NameSize.SHORT_NAME
            )
          : undefined,
      },
    };

    const isAutoCoverageCheck =
      process.env.REACT_APP_USE_AUTO_COVERAGE_CHECK === "true";

    if (!isAutoCoverageCheck) {
      return incidentLocation;
    }

    // build and add Address.Type to incidentLocation for auto coverage check
    let addressType = IncidentLocationAddressType.HIGHWAY;

    if (!immobilizedOnHighway) {
      const { Country, City, ZipCode, Street, HouseNb } =
        incidentLocation.Address;
      const isAddressComplete = [Country, City, ZipCode, Street, HouseNb].every(
        (el) => el !== null && el !== undefined && el !== ""
      );

      addressType = isAddressComplete
        ? IncidentLocationAddressType.ADDRESS
        : IncidentLocationAddressType.STREET;
    }

    const finalIncidentLocation: Partial<Location> = {
      ...incidentLocation,
      Address: {
        ...incidentLocation.Address,
        Type: addressType,
      },
    };

    return finalIncidentLocation;
  }
}
