import React, { FC } from "react";

interface AppStoreIconProps {
  url: string;
  icon: string;
}

export const AppStoreIcon: FC<AppStoreIconProps> = ({ url, icon }) => {
  return (
    <a href={url}>
      <img src={icon} alt="" width={80} height={80} />
    </a>
  );
};
