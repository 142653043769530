import "./ConsentManagement.scss";

import React from "react";
import { Trans, useTranslation } from "react-i18next";

const ConsentManagement: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section className={"consentManagementSection"}>
      <small>
        <Trans
          i18nKey={
            "By continuing this service, you agree to our data protection policy"
          }
        >
          By continuing this service, you agree to our
          <a href={t("data_protection_link")} target={"_blank"}></a>
        </Trans>
      </small>
    </section>
  );
};

export default ConsentManagement;
