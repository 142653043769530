import { Color } from "../../model/VehicleDetail";

const carColors: Color[] = [
  {
    deleted: false,
    key: "14012",
    values: {
      de: "Anthrazit",
      en: "Anthracite ",
      it: "Antracite",
      fr: "Anthracite",
    },
    active: true,
    sortOrder: 12,
  },
  {
    deleted: false,
    key: "14009",
    values: {
      de: "Silber",
      en: "Silver",
      it: "Argento",
      fr: "Argent",
    },
    active: true,
    sortOrder: 9,
  },
  {
    deleted: false,
    key: "14015",
    values: {
      de: "Beige",
      en: "Beige",
      it: "Beige ",
      fr: "Beige",
    },
    active: true,
    sortOrder: 15,
  },
  {
    deleted: false,
    key: "14004",
    values: {
      de: "Weiss",
      en: "White",
      it: "Bianco",
      fr: "Blanc",
    },
    active: true,
    sortOrder: 4,
  },
  {
    deleted: false,
    key: "14001",
    values: {
      de: "Blau",
      en: "Blue",
      it: "Blu",
      fr: "Bleu",
    },
    active: true,
    sortOrder: 1,
  },
  {
    deleted: false,
    key: "14013",
    values: {
      de: "Weinrot",
      en: "Bordeau",
      it: "Bordeau",
      fr: "Bordeau",
    },
    active: true,
    sortOrder: 13,
  },
  {
    deleted: false,
    key: "14008",
    values: {
      de: "Braun",
      en: "Brown",
      it: "Marrone",
      fr: "Brun",
    },
    active: true,
    sortOrder: 8,
  },
  {
    deleted: false,
    key: "14010",
    values: {
      de: "Grau",
      en: "Gray",
      it: "Grigio",
      fr: "Gris",
    },
    active: true,
    sortOrder: 10,
  },
  {
    deleted: false,
    key: "14006",
    values: {
      de: "Gelb",
      en: "Yellow",
      it: "Giallo",
      fr: "Jaune",
    },
    active: true,
    sortOrder: 6,
  },
  {
    deleted: false,
    key: "14017",
    values: {
      de: "Bunt",
      en: "Multicoloured",
      it: "Multicolore",
      fr: "Multicolore",
    },
    active: true,
    sortOrder: 17,
  },
  {
    deleted: false,
    key: "14005",
    values: {
      de: "Schwarz",
      en: "Black",
      it: "Nero",
      fr: "Noir",
    },
    active: true,
    sortOrder: 5,
  },
  {
    deleted: false,
    key: "14014",
    values: {
      de: "Gold",
      en: "Gold ",
      it: "Oro",
      fr: "Or",
    },
    active: true,
    sortOrder: 14,
  },
  {
    deleted: false,
    key: "14011",
    values: {
      de: "Orange",
      en: "Orange ",
      it: "Arancione",
      fr: "Orange ",
    },
    active: true,
    sortOrder: 11,
  },
  {
    deleted: false,
    key: "14003",
    values: {
      de: "Rot",
      en: "Red",
      it: "Rosso",
      fr: "Rouge",
    },
    active: true,
    sortOrder: 3,
  },
  {
    deleted: false,
    key: "14002",
    values: {
      de: "Grün",
      en: "Green",
      it: "Verde",
      fr: "Vert",
    },
    active: true,
    sortOrder: 2,
  },
  {
    deleted: false,
    key: "14007",
    values: {
      de: "Violett",
      en: "Purple",
      it: "Viola",
      fr: "Violet",
    },
    active: true,
    sortOrder: 7,
  },
];

export default carColors;
