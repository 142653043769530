import { ImmobilizationType } from "../model/ImmobilizationType.enum";

export class HighwayUtils {
  public static serializePriority(
    type: ImmobilizationType,
    appointmentPriority: number | undefined
  ): number {
    return appointmentPriority
      ? appointmentPriority
      : this.getHighwayPriority(type);
  }

  public static getHighwayPriority(type: ImmobilizationType): number {
    let highwayPriority = 3;

    switch (type) {
      case ImmobilizationType.EMERGENCY_LANE:
        highwayPriority = 2;
        break;
      case ImmobilizationType.ACTIVE_LANE:
        highwayPriority = 1;
        break;
      default:
        break;
    }

    return highwayPriority;
  }
}
