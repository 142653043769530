import { Appointment } from "../model/Appointment";
import { IncidentType } from "../model/IncidentType.enum";
import { SerializedIncidents } from "./IncidentUtils";
import { FluidLossDetails } from "../model/Incident/FluidLossDetails";
import { DoorsAndKeysDetails } from "../model/Incident/DoorsAndKeysDetails";
import { CountryUtils } from "./CountryUtils";
import { LocationFields } from "../components/Location/GeoLocation/GeoLocation";

export class AppointmentUtils {
  public static getPriority(
    appointment: Appointment | undefined
  ): number | undefined {
    return appointment && appointment.date && appointment.time ? 4 : undefined;
  }

  public static canMakeAnAppointment(
    incidentType: IncidentType | undefined,
    incidentDetails: SerializedIncidents | undefined,
    immobilizedOnHighway: boolean,
    locationFields: LocationFields
  ): boolean {
    if (!incidentType || !incidentDetails) {
      return true;
    }

    return (
      !(
        (IncidentType.FLUID_LOSS === incidentType &&
          (incidentDetails as FluidLossDetails).hasSmell) ||
        (IncidentType.DOOR_AND_KEYS === incidentType &&
          (incidentDetails as DoorsAndKeysDetails).isLocked) ||
        immobilizedOnHighway
      ) &&
      CountryUtils.isInSwitzerlandOrLiechtenstein(locationFields.googleResult)
    );
  }
}
