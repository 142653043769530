import {
  DoorsAndKeysDetails,
  DoorsAndKeysIncidentType,
} from "../../model/Incident/DoorsAndKeysDetails";
import { IncidentUtils } from "../IncidentUtils";
import { Case } from "../../api/models/Case";
import { ImmobilizationType } from "../../model/ImmobilizationType.enum";
import { AppointmentUtils } from "../AppointmentUtils";
import { Appointment } from "../../model/Appointment";
import { PersonDetail } from "../../model/PersonDetail";
import { PersonalDetailsUtils } from "../PersonalDetailsUtils";
import { HighwayUtils } from "../HighwayUtils";
import { LocationUtils } from "../LocationUtils";

export class DoorAndKeysUtils {
  public static serialize(
    data: DoorsAndKeysDetails,
    immobilizationType: ImmobilizationType,
    appointment: Appointment | undefined,
    personDetail: PersonDetail,
    immobilizedOnHighway: boolean | undefined
  ): Partial<Case> {
    return {
      MainCause: "12",
      Comment: this.getCaseComment(
        data,
        personDetail,
        immobilizedOnHighway,
        immobilizationType
      ),
      Priority: this.getPriority(
        data.isLocked,
        immobilizationType,
        appointment
      ),
      ItemsUsedList: [
        {
          ...IncidentUtils.getItemType(),
          LastVehicleStatus: {
            PrimaryFault: this.getPrimaryFault(data.breakdownType),
            Comment: this.getTCSDescription(data),
          },
        },
      ],
    };
  }

  public static getSummary(data: DoorsAndKeysDetails): string {
    return data.breakdownType ? data.breakdownType : "";
  }

  private static getPrimaryFault(breakdownType: string): string {
    switch (breakdownType) {
      case DoorsAndKeysIncidentType.KEY_REMOTE_PROBLEM:
        return "65097";
      case DoorsAndKeysIncidentType.KEY_REMOTE_LOCKED_IN_CAR:
        return "65096";
      case DoorsAndKeysIncidentType.FUEL_CAP_JAMMED:
        return "65059";
      default:
        // Lost key and Other have same code
        return "65097";
    }
  }

  private static getTCSDescription(data: DoorsAndKeysDetails): string {
    return [
      this.getCodeOfVehicleDescriptionByBreakdown(data.breakdownType),
      this.getIsAnyoneLockedCode(data.isLocked),
      IncidentUtils.formatAdditionalInformation(data.additionalInformation),
    ]
      .filter(Boolean)
      .join("; ");
  }

  private static getCodeOfVehicleDescriptionByBreakdown(
    breakdownType: string
  ): string | undefined {
    switch (breakdownType) {
      case DoorsAndKeysIncidentType.KEY_REMOTE_PROBLEM:
        return "Callback Fragebaum";
      case DoorsAndKeysIncidentType.KEY_REMOTE_LOCKED_IN_CAR:
        return "Schlüssel eingeschlossen";
      case DoorsAndKeysIncidentType.FUEL_CAP_JAMMED:
        return "Callback THD nach Vervollständigung Dossier durch FA";
      case DoorsAndKeysIncidentType.LOST_KEY_REMOTE:
        return "Schlüssel verloren";
      default:
        return undefined;
    }
  }

  private static getIsAnyoneLockedCode(isLocked: boolean): string | undefined {
    if (isLocked === false) {
      return "Keine Personen oder Tiere im Auto";
    } else if (isLocked) {
      return "Person oder Tier im Auto";
    } else {
      return undefined;
    }
  }

  private static getCaseComment(
    data: DoorsAndKeysDetails,
    personDetail: PersonDetail,
    immobilizedOnHighway: boolean | undefined,
    immobilizationType: ImmobilizationType
  ) {
    let comment: string[] = [];
    if (data.isLocked) {
      comment.push("Callback Person oder Tier in Auto");
    } else {
      switch (data.breakdownType) {
        case DoorsAndKeysIncidentType.KEY_REMOTE_PROBLEM:
          comment.push("Callback Fragebaum");
          break;
        case DoorsAndKeysIncidentType.KEY_REMOTE_LOCKED_IN_CAR:
          comment.push("Callback Pannenart");
          break;
        case DoorsAndKeysIncidentType.FUEL_CAP_JAMMED:
          comment.push("Callback THD nach Vervollständigung Dossier durch FA");
          break;
        case DoorsAndKeysIncidentType.LOST_KEY_REMOTE:
          comment.push("Callback Pannenart durch THD");
          break;
        case DoorsAndKeysIncidentType.OTHER:
          comment.push("Callback Pannenart");
          break;
        default:
          break;
      }
    }

    if (data.additionalInformation && data.additionalInformation !== "") {
      comment.push("S. Kommentar Pannenart");
    }

    return [
      LocationUtils.getCaseComment(immobilizedOnHighway, immobilizationType),
      ...comment,
      PersonalDetailsUtils.serializeCaseComment(personDetail),
      ...PersonalDetailsUtils.serializeCoverageComment(personDetail),
    ]
      .filter(Boolean)
      .join("; ");
  }

  private static getPriority(
    isLocked: boolean,
    type: ImmobilizationType,
    appointment: Appointment | undefined
  ): number {
    return isLocked
      ? 1
      : HighwayUtils.serializePriority(
          type,
          AppointmentUtils.getPriority(appointment)
        );
  }
}
