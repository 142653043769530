export enum PolicyHolderType {
  ME = "Me",
  COMPANY = "A company",
  ANOTHER_PERSON = "Another person",
}

export enum PolicyHolderLabel {
  ME = "policyLabelMe",
  COMPANY = "policyLabelCompany",
  ANOTHER_PERSON = "policyLabelAnotherPerson",
}
