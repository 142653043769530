import { Canton } from "../../model/Canton";

const cantons: Canton[] = [
  {
    abbreviation: "AG",
    canton_de: "Aargau",
    canton_en: "Aargau",
    canton_fr: "Argovie",
    canton_it: "Argovia",
  },
  {
    abbreviation: "AI",
    canton_de: "Appenzell Innerrhoden",
    canton_en: "Appenzell Inner-Rhodes",
    canton_fr: "Appenzell Rhodes-Intérieures",
    canton_it: "Appenzello Interno",
  },
  {
    abbreviation: "AR",
    canton_de: "Appenzell Ausserrhoden",
    canton_en: "Appenzell Outer-Rhodes",
    canton_fr: "Appenzell Rhodes-Exterieures",
    canton_it: "Appenzell Esterno",
  },
  {
    abbreviation: "BL",
    canton_de: "Basel-Landschaft",
    canton_en: "Basel-Country",
    canton_fr: "Bâle-Campagne",
    canton_it: "Basilea Camapagna",
  },
  {
    abbreviation: "BS",
    canton_de: "Basel-Stadt",
    canton_en: "Basel-City",
    canton_fr: "Bâle-Ville",
    canton_it: "Basilea Città",
  },
  {
    abbreviation: "BE",
    canton_de: "Bern",
    canton_en: "Bern",
    canton_fr: "Berne",
    canton_it: "Berna",
  },
  {
    abbreviation: "FR",
    canton_de: "Freiburg",
    canton_en: "Friburg",
    canton_fr: "Fribourg",
    canton_it: "Friburgo",
  },
  {
    abbreviation: "GE",
    canton_de: "Genf",
    canton_en: "Geneva",
    canton_fr: "Genève",
    canton_it: "Ginevra",
  },
  {
    abbreviation: "GL",
    canton_de: "Glarus",
    canton_en: "Glarus",
    canton_fr: "Glaris",
    canton_it: "Glarona",
  },
  {
    abbreviation: "GR",
    canton_de: "Graubünden",
    canton_en: "Grisons",
    canton_fr: "Grisons",
    canton_it: "Grigioni",
  },
  {
    abbreviation: "JU",
    canton_de: "Jura",
    canton_en: "Jura",
    canton_fr: "Jura",
    canton_it: "Giura",
  },
  {
    abbreviation: "LU",
    canton_de: "Luzern",
    canton_en: "Lucerne",
    canton_fr: "Lucerne",
    canton_it: "Lucerna",
  },
  {
    abbreviation: "NE",
    canton_de: "Neuenburg",
    canton_en: "Neuchâtel",
    canton_fr: "Neuchâtel",
    canton_it: "Neuchâtel",
  },
  {
    abbreviation: "NW",
    canton_de: "Nidwalden",
    canton_en: "Nidwald",
    canton_fr: "Nidwald",
    canton_it: "Nidvaldo",
  },
  {
    abbreviation: "OW",
    canton_de: "Obwalden",
    canton_en: "Obwald",
    canton_fr: "Obwald",
    canton_it: "Obvaldo",
  },
  {
    abbreviation: "SG",
    canton_de: "St. Gallen",
    canton_en: "St. Gall",
    canton_fr: "Saint-Gall",
    canton_it: "San Gallo",
  },
  {
    abbreviation: "SH",
    canton_de: "Schaffhausen",
    canton_en: "Schaffhouse",
    canton_fr: "Schaffouse",
    canton_it: "Sciaffusa",
  },
  {
    abbreviation: "SO",
    canton_de: "Solothurn",
    canton_en: "Soleure",
    canton_fr: "Soleure",
    canton_it: "Soletta",
  },
  {
    abbreviation: "TG",
    canton_de: "Thurgau",
    canton_en: "Thurgovia",
    canton_fr: "Thurgovie",
    canton_it: "Turgovia",
  },
  {
    abbreviation: "SZ",
    canton_de: "Schwyz",
    canton_en: "Schwyz",
    canton_fr: "Schwytz",
    canton_it: "Svitto",
  },
  {
    abbreviation: "TI",
    canton_de: "Ticino",
    canton_en: "Ticino",
    canton_fr: "Tessin",
    canton_it: "Ticino",
  },
  {
    abbreviation: "UR",
    canton_de: "Uri",
    canton_en: "Uri",
    canton_fr: "Uri",
    canton_it: "Uri",
  },
  {
    abbreviation: "VS",
    canton_de: "Wallis",
    canton_en: "Wallis",
    canton_fr: "Valais",
    canton_it: "Vallese",
  },
  {
    abbreviation: "VD",
    canton_de: "Waadt",
    canton_en: "Vaud",
    canton_fr: "Vaud",
    canton_it: "Vaud",
  },
  {
    abbreviation: "ZG",
    canton_de: "Zug",
    canton_en: "Zoug",
    canton_fr: "Zoug",
    canton_it: "Zugo",
  },
  {
    abbreviation: "ZH",
    canton_de: "Zürich",
    canton_en: "Zurich",
    canton_fr: "Zurich",
    canton_it: "Zurigo",
  },
  {
    abbreviation: "CD",
    canton_de: "Diplomatisches Fahrzeug",
    canton_en: "Diplomatic vehicle",
    canton_fr: "Véhicule diplomatique",
    canton_it: "Veicolo diplomatico",
  },
  {
    abbreviation: "CC",
    canton_de: "Konsularisches Fahrzeug",
    canton_en: "Consular vehicle",
    canton_fr: "Véhicule consulaire",
    canton_it: "Veicolo consolare",
  },
  {
    abbreviation: "AT",
    canton_de: "Verwaltungs-/Technisches Diplomatenfahrzeug",
    canton_en: "Administrative/Technical diplomatic vehicle",
    canton_fr: "Véhicule diplomatique administratif/technique",
    canton_it: "Veicolo diplomatico amministrativo/tecnico",
  },
  {
    abbreviation: "FL",
    canton_de: "Fürstentum Liechtenstein",
    canton_en: "Principality of Liechtenstein",
    canton_fr: "Principauté du Liechtenstein",
    canton_it: "Principato del Liechtenstein",
  },
];

export default cantons;
