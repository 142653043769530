import { CaseUtils, NameSize } from "./CaseUtils";
import { TFunction } from "react-i18next";
import { LocationFields } from "../components/Location/GeoLocation/GeoLocation";
import { ImmobilizationType } from "../model/ImmobilizationType.enum";

export class LocationUtils {
  public static getCaseComment(
    immobilizedOnHighway: boolean | undefined,
    immobilizationType: ImmobilizationType
  ): string | undefined {
    return immobilizedOnHighway
      ? immobilizationType == ImmobilizationType.ACTIVE_LANE
        ? "AUTOBAHN (Fahrspur)"
        : "AUTOBAHN"
      : undefined;
  }

  public static getLabelAdditionalInformation(
    isInEditMode: boolean,
    immobilizedOnHighwayOnSummary: boolean,
    immobilizedOnHighwayOnSteps: boolean | undefined,
    t: TFunction<"translation">
  ) {
    return LocationUtils.showDrivingDirection(
      isInEditMode,
      immobilizedOnHighwayOnSummary,
      immobilizedOnHighwayOnSteps
    )
      ? t("Driving Direction")
      : t("Additional information");
  }

  public static formattedAddressFromGoogleResult(
    googleResult: google.maps.GeocoderResult
  ): string | undefined {
    if (!googleResult) {
      return undefined;
    }

    const country = CaseUtils.getAddressComponentFromGoogleResult(
      googleResult,
      "country",
      NameSize.LONG_NAME
    );

    const countryCode = CaseUtils.getAddressComponentFromGoogleResult(
      googleResult,
      "country",
      NameSize.SHORT_NAME
    );

    const city = CaseUtils.getAddressComponentFromGoogleResult(
      googleResult,
      "locality",
      NameSize.LONG_NAME
    );

    const postalCode = CaseUtils.getAddressComponentFromGoogleResult(
      googleResult,
      "postal_code",
      NameSize.LONG_NAME
    );

    const street = CaseUtils.getAddressComponentFromGoogleResult(
      googleResult,
      "route",
      NameSize.LONG_NAME
    );

    const houseNumber = CaseUtils.getAddressComponentFromGoogleResult(
      googleResult,
      "street_number",
      NameSize.LONG_NAME
    );

    const returnAdress = () => {
      return countryCode == "FR"
        ? [
            houseNumber,
            street ? `${street},` : undefined,
            postalCode,
            `${city},`,
            country,
          ]
        : [
            street ? `${street}` : undefined,
            houseNumber ? ` ${houseNumber},` : undefined,
            postalCode,
            `${city},`,
            country,
          ];
    };

    return returnAdress().filter(Boolean).join(" ");
  }

  public static showDrivingDirection(
    isInEditMode: boolean,
    immobilizedOnHighwayOnSummary: boolean,
    immobilizedOnHighwayOnSteps: boolean | undefined
  ) {
    return isInEditMode
      ? immobilizedOnHighwayOnSummary
      : immobilizedOnHighwayOnSteps;
  }

  public static serializeLocationComment(
    immobilizedOnHighway: boolean | undefined,
    locationFields: LocationFields,
    locationAdditionalInfo?: string
  ): string {
    const comment = [locationFields.placeName || undefined];
    let additionalInfo;
    if (immobilizedOnHighway) {
      additionalInfo = locationFields.additionalInformation
        ? [
            "AUTOBAHN",
            `Fahrtrichtung : ${locationFields.additionalInformation}`,
          ].join("; ")
        : undefined;
    } else {
      additionalInfo = locationFields.additionalInformation
        ? locationFields.additionalInformation
        : undefined;
    }
    locationAdditionalInfo && comment.push(locationAdditionalInfo);
    comment.push(additionalInfo);

    return comment.filter(Boolean).join("; ");
  }

  public static calculateDistanceFromCoordinates = (
    lat1: number,
    lng1: number,
    lat2: number,
    lng2: number
  ): number => {
    const earthRadiusKm = 6371;
    const degToRad = (degrees: number) => degrees * (Math.PI / 180);

    const lat1Rad = degToRad(lat1);
    const lon1Rad = degToRad(lng1);
    const lat2Rad = degToRad(lat2);
    const lon2Rad = degToRad(lng2);

    const dLat = lat2Rad - lat1Rad;
    const dLon = lon2Rad - lon1Rad;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1Rad) *
        Math.cos(lat2Rad) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = earthRadiusKm * c;

    return distance;
  };
}
