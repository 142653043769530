import "./IssueCard.scss";
import classNames from "classnames/bind";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
} from "@ionic/react";
import { CardProps } from "./SummaryCardPropsModel";
import { RoutePath } from "../../../model/RoutePath.enum";
import { useTranslation } from "react-i18next";
import React from "react";

const ScheduleCard: React.FC<CardProps> = ({
  onClick,
  isEditing,
  schedule,
}) => {
  const { t } = useTranslation();
  const hasAppointment = schedule && schedule.date;
  const displayScheduledDate = (returnDate: string): string => {
    const splittedScheduledDate = returnDate.split("/");
    return splittedScheduledDate[0].concat(
      "." + splittedScheduledDate[1] + "." + splittedScheduledDate[2]
    );
  };
  return (
    <IonCard className={"summary-card"}>
      <IonCardHeader className="card-header">
        <IonCardTitle>{t("Schedule")}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent class="card-content">
        <div
          className={classNames("summary-card", "mb-10", {
            editing: isEditing,
          })}
          onClick={() => isEditing && onClick(RoutePath.SCHEDULE_OPTIONS)}
        >
          <div className="edit-icon">
            <img
              alt="pencil"
              src={`${process.env.PUBLIC_URL}/assets/images/${
                process.env.REACT_APP_ALT_ICONS_PATH || ""
              }pencil.svg`}
            />
          </div>

          <div
            className={classNames("sub-card-container", "pd-5", {
              "row-min-height": isEditing,
            })}
          >
            <p className="problem-text-title">{t("Appointment taken")}: </p>
            <p className="problem-description-text">
              {hasAppointment ? t("Yes") : t("No")}
            </p>
            {isEditing && <p />}
          </div>
        </div>

        {hasAppointment && (
          <div
            className={classNames("summary-card", "mb-10", {
              editing: isEditing,
            })}
            onClick={() => isEditing && onClick(RoutePath.SCHEDULE_SERVICE)}
          >
            <div className="edit-icon">
              <img
                alt="pencil"
                src={`${process.env.PUBLIC_URL}/assets/images/${
                  process.env.REACT_APP_ALT_ICONS_PATH || ""
                }pencil.svg`}
              />
            </div>

            <div
              className={classNames("sub-card-container", "pd-5", {
                "row-min-height": isEditing,
              })}
            >
              <p className="problem-text-title">{t("Date")}: </p>
              <p className="problem-description-text">
                {displayScheduledDate(schedule.date)} {t("at")} {schedule.time}
              </p>
              {isEditing && <p />}
            </div>
          </div>
        )}
      </IonCardContent>
    </IonCard>
  );
};

export default ScheduleCard;
