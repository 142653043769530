import "./VehicleDetails.scss";
import { useTranslation } from "react-i18next";
import React, { useEffect, useRef } from "react";
import { VehicleColor, VehicleInfos } from "../../api/models/VehicleInfos";
import { IonItem, IonRadio, IonRadioGroup } from "@ionic/react";
import { FormValidation } from "../../helpers/FormValidation";
import { MofisColorsEnum } from "../../model/MofisColors.enum";
import { VehicleDetailsUtils } from "../../helpers/VehicleDetailsUtils";
import { useStateMachine } from "little-state-machine";

export type VehicleDetailsProps = {
  vehicles: VehicleInfos[] | undefined;
  selectedVehicle: any;
  pickedVehicle: any;
};

const VehicleDetails: React.FC<VehicleDetailsProps> = ({
  vehicles,
  selectedVehicle,
  pickedVehicle,
}) => {
  const { t } = useTranslation();
  const { state } = useStateMachine();
  const radioStart =
    process.env.REACT_APP_RADIO_START &&
    process.env.REACT_APP_RADIO_START == "true"
      ? "start"
      : "end";

  return (
    <section className="vehicle-options">
      <h3>{t("Which vehicle is concerned by this license plate number?")}</h3>
      <IonRadioGroup
        value={pickedVehicle}
        name="associatedVehicleToLicensePlate" // should have the same name as the form
        onIonChange={(e) => {
          selectedVehicle(e.detail.value);
        }}
      >
        {vehicles?.map((vehicle, index) => {
          if (vehicle) {
            return (
              <IonItem key={vehicle.matriculeNumber}>
                <span className={"tab"}>
                  {vehicle.completeBrandModel},{" "}
                  {VehicleDetailsUtils.translateVehicleColor(vehicle.color)},{" "}
                  {vehicle.firstDateOfRegistration.slice(0, 4)}
                </span>
                <IonRadio slot={radioStart} value={vehicle.matriculeNumber} />
              </IonItem>
            );
          }
        })}
        <IonItem key={"other"}>
          <span className={"tab"}>{t("Other vehicle")}</span>
          <IonRadio slot={radioStart} value={"other"} />
        </IonItem>
      </IonRadioGroup>
      {/*{FormValidation.getFormErrorMessage("breakdownType", formik, t)}*/}
    </section>
  );
};

export default VehicleDetails;
