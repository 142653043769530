import "./LaneWarning.scss";

import { IonButton, IonContent, IonIcon, IonLabel } from "@ionic/react";
import { useHistory } from "react-router";

import { RoutePath } from "../../model/RoutePath.enum";
import React, { useMemo } from "react";
import { MainFooter, MainHeader } from "../Layout/Layout";
import { Trans, useTranslation } from "react-i18next";
import { useStateMachine } from "little-state-machine";
import { ImmobilizationType } from "../../model/ImmobilizationType.enum";

const LaneWarning: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { state } = useStateMachine();

  const displayText = useMemo(() => {
    if (state.steps.immobilizationType === ImmobilizationType.EMERGENCY_LANE) {
      return (
        <>
          {t("Before continuing")}
          <ul>
            <li>
              <Trans i18nKey={"Turn on your hazard lights."} />
            </li>
            <li>
              <Trans
                i18nKey={
                  "Get out of your vehicle wearing your high visibility jacket."
                }
              />
            </li>
            <li>
              <Trans i18nKey={"Get behind the safety barrier."} />
            </li>
          </ul>
          <p>
            <Trans i18nKey={"Your safety is our top priority."} />
          </p>
        </>
      );
    }
    return (
      <>
        {t("Before continuing")}
        <ul>
          <li>
            <Trans i18nKey={"Turn on your hazard lights."} />
          </li>
          <li>
            <Trans
              i18nKey={
                "Get out of your vehicle wearing your high visibility jacket."
              }
            />
          </li>
          <li>
            <Trans i18nKey={"If possible, get behind the safety barrier"} />
          </li>
          <li>
            <Trans i18nKey={"Never enter or cross the roadway"} />
          </li>
        </ul>
        <p>
          <Trans i18nKey={"Your safety is our top priority."} />
        </p>
      </>
    );
  }, [state.steps.immobilizationType, t]);

  return (
    <IonContent>
      <MainHeader title={t("Warning!")} titleStyle={{ textAlign: "center" }}>
        <section>
          <div className="warning-card">
            <div className="warning-img">
              <img
                alt="warning"
                src={`${process.env.PUBLIC_URL}/assets/images/${
                  process.env.REACT_APP_ALT_ICONS_PATH || ""
                }Warning.svg`}
              />
            </div>
            <div className="warning-txt">{displayText}</div>
          </div>
        </section>
      </MainHeader>
      <MainFooter>
        <IonButton
          id="lane-warning-back"
          className="button-tcs outline back"
          expand="block"
          onClick={() => history.back()}
        >
          <div className="wrapper">
            <IonIcon
              size="large"
              src={`${process.env.PUBLIC_URL}/assets/images/chevron-back.svg`}
            />
            <IonLabel>{t("Back")}</IonLabel>
            <div />
          </div>
        </IonButton>
        <IonButton
          id="lane-warning-next"
          className="button-tcs cta"
          color="primary"
          expand="block"
          onClick={() =>
            history.push(`${RoutePath.REQUEST}/${RoutePath.INCIDENT_TYPE}`)
          }
        >
          <div className="wrapper">
            <div />
            <IonLabel>{t("Next")}</IonLabel>
            <IonIcon
              size="large"
              src={`${process.env.PUBLIC_URL}/assets/images/chevron-forward.svg`}
            />
          </div>
        </IonButton>
      </MainFooter>
    </IonContent>
  );
};

export default LaneWarning;
