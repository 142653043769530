import React from "react";
import "./Loading.scss";

const Loading: React.FC = () => {
  return (
    <div className="loading-c-content">
      <img
        alt="pencil"
        src={`${process.env.PUBLIC_URL}/assets/images/loader.svg`}
      />
    </div>
  );
};

export default Loading;
