import { GlobalState } from "little-state-machine";
import { DigitalIntakeSteps, InsertedPhoneNumberData } from "../types/global";
import { LocationFields } from "../components/Location/GeoLocation/GeoLocation";
import { IncidentType } from "../model/IncidentType.enum";
import { OtherDetails } from "../model/Incident/OtherDetails";
import { StarterIssueDetails } from "../model/Incident/StarterIssueDetails";
import { FuelDetails } from "../model/Incident/FuelDetails";
import { DoorsAndKeysDetails } from "../model/Incident/DoorsAndKeysDetails";
import { EngineDetails } from "../model/Incident/EngineDetails";
import { FluidLossDetails } from "../model/Incident/FluidLossDetails";
import { WheelAndTireDetails } from "../model/Incident/WheelAndTireDetails";
import { BicycleDetails } from "../model/Incident/BicycleDetails";
import {
  OneTimePassword,
  TokenResponse,
} from "../model/OneTimePassword/one-time-password-params";
import { VehicleInfos } from "../api/models/VehicleInfos";

export function updateGlobalState(state: GlobalState, payload: any) {
  return {
    ...state,
    ...payload,
  };
}

export function updateInsertedMobilePhoneNumber(
  state: GlobalState,
  payload: InsertedPhoneNumberData
) {
  return {
    ...state,
    insertedPhoneNumber: payload,
  };
}

export function updateEditMode(state: GlobalState, payload: boolean) {
  return {
    ...state,
    isInEditMode: payload,
  };
}

export function updateDisplayMode(state: GlobalState, payload: boolean) {
  return {
    ...state,
    displayMap: payload,
  };
}

export function updateLocateOnLoad(state: GlobalState, payload: boolean) {
  return {
    ...state,
    locateOnLoad: payload,
  };
}

export function updateToken(
  state: GlobalState,
  payload: TokenResponse | undefined
) {
  return {
    ...state,
    token: payload,
  };
}

function updateHashCodes(newHashCode: string, currentHashCodes?: string[]) {
  if (!currentHashCodes) {
    return [newHashCode];
  }
  let newHashCodes = [...currentHashCodes];

  if (currentHashCodes.length === 5) {
    newHashCodes.shift();
    newHashCodes.push(newHashCode);
    return newHashCodes;
  }
  return [...currentHashCodes, newHashCode];
}

export function updateOneTimePassword(
  state: GlobalState,
  payload: OneTimePassword | undefined
) {
  return {
    ...state,
    cachedPasswords: payload && {
      expirationDate: payload.expirationDate,
      phoneNumber: payload.phoneNumber,
      hashCodes: payload.hashCode
        ? updateHashCodes(payload.hashCode, state.cachedPasswords?.hashCodes)
        : [],
    },
  };
}

export function updateTemporaryLocation(
  state: GlobalState,
  payload: Partial<LocationFields> | undefined
) {
  return {
    ...state,
    temporaryLocation: payload ? { ...payload } : undefined,
  };
}

export function updateHasSubmittedLocation(
  state: GlobalState,
  payload: boolean
) {
  return {
    ...state,
    hasSubmittedLocation: payload,
  };
}
export function updateIsBCMFallBack(state: GlobalState, payload: boolean) {
  return {
    ...state,
    isBCMFallBack: true,
  };
}
export function updateIsAbroadCase(state: GlobalState, payload: boolean) {
  return {
    ...state,
    isAbroadCase: payload,
  };
}
export function updateLocateMeNow(state: GlobalState, payload: boolean) {
  return {
    ...state,
    locateMeNow: payload,
  };
}
export function updateIsFromIVR(state: GlobalState, payload: boolean) {
  return {
    ...state,
    isFromIVR: payload,
  };
}
export function updateMOFISVehicle(
  state: GlobalState,
  payload: VehicleInfos | undefined
) {
  return {
    ...state,
    MOFISVehicle: payload,
  };
}

export function updateHasRetrievedVehicles(
  state: GlobalState,
  payload: boolean
) {
  return {
    ...state,
    hasRetrievedVehicles: payload,
  };
}
export function updateRetrievedVehicles(
  state: GlobalState,
  payload: VehicleInfos[] | undefined
) {
  return {
    ...state,
    retrievedVehicles: payload,
  };
}
export function updateRetrievedVehicle(
  state: GlobalState,
  payload: string | number | undefined
) {
  return {
    ...state,
    retrievedVehicle: payload,
  };
}

export function updateTemporaryIncidentSummary(
  state: GlobalState,
  payload: {
    incidentDetails:
      | OtherDetails
      | StarterIssueDetails
      | FuelDetails
      | DoorsAndKeysDetails
      | EngineDetails
      | FluidLossDetails
      | WheelAndTireDetails
      | BicycleDetails;
    incidentType: IncidentType;
  }
) {
  return {
    ...state,
    temporaryIncidentSummary: { ...payload },
  };
}

export function updateSteps(
  state: GlobalState,
  payload: Partial<DigitalIntakeSteps>
) {
  if (state.isInEditMode) {
    return updateSummary(state, payload);
  }

  return {
    ...state,
    steps: {
      ...state.steps,
      ...payload,
    },
    summary: {
      ...state.steps,
      ...payload,
    },
  };
}

export function forceUpdateSteps(
  state: GlobalState,
  payload: Partial<DigitalIntakeSteps>
) {
  return {
    ...state,
    steps: {
      ...state.steps,
      ...payload,
    },
  };
}

export function updateLocationAdditionalInfo(
  state: GlobalState,
  payload: string | undefined
) {
  return {
    ...state,
    locationAdditionalInfo: payload,
  };
}

export function updateSummary(
  state: GlobalState,
  payload: Partial<DigitalIntakeSteps>
) {
  const summary = state.summary;
  if (summary) {
    const final = {
      ...state,
      summary: {
        ...summary,
        ...payload,
      },
    };

    if ("incidentDetails" in payload) {
      // override all properties of incident details, to avoid properties that
      // don't belong to the interface
      final.summary["incidentDetails"] = payload["incidentDetails"];
    }

    return final;
  } else {
    return {
      ...state,
      summary: {
        ...payload,
      },
    };
  }
}
