import { useFormikContext } from "formik";
import React, { useEffect } from "react";

export function ScrollToError() {
  const formik = useFormikContext();
  const submitting = formik?.isSubmitting;

  useEffect(() => {
    if (formik.errors) {
      const fieldName = Object.keys(formik.errors)[0];
      const elements = document.getElementsByName(fieldName);
      elements[0]?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
      elements[1]?.focus({ preventScroll: true });
    }
  }, [submitting]);
  return null;
}
