import "./WheelsAndTiresIncident.scss";

import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonRadio,
  IonRadioGroup,
  IonTextarea,
  isPlatform,
} from "@ionic/react";
import { useStateMachine } from "little-state-machine";
import { useHistory } from "react-router";
import * as Yup from "yup";
import { FormikProvider, useFormik } from "formik";
import { FormValidation } from "../../../helpers/FormValidation";
import React, { useEffect, useRef, useState } from "react";
import { updateSteps } from "../../../state/updateState";
import { MainFooter, MainHeader } from "../../Layout/Layout";
import { WheelAndTireDetails } from "../../../model/Incident/WheelAndTireDetails";
import { IncidentType } from "../../../model/IncidentType.enum";
import { useTranslation } from "react-i18next";
import { IncidentUtils } from "../../../helpers/IncidentUtils";
import { ScrollToError } from "../../shared/ScrollToError/scroll-to-error";

const hasSpareWheel = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];

const numberOfWheelsDamaged = [
  {
    label: "One",
    value: "One",
  },
  {
    label: "Many",
    value: "Many",
  },
];

const tireStatus = [
  {
    label: "Visible tire deflation",
    value: "Visible tire deflation",
  },
  {
    label: "Tire pressure indicator",
    value: "Tire pressure indicator",
  },
  {
    label: "Other",
    value: "Other",
  },
];

const WheelsAndTiresIncident: React.FC = () => {
  const { t } = useTranslation();
  t("Number of wheels is a required field");
  t("Spare wheels a required field");
  t("Tire Status is a required field");
  const history = useHistory();
  const { actions, state } = useStateMachine({ updateSteps });
  const damageWheelsRef = useRef<HTMLIonInputElement>(null);
  const radioStart =
    process.env.REACT_APP_RADIO_START &&
    process.env.REACT_APP_RADIO_START == "true"
      ? "start"
      : "end";

  const textArea = useRef<HTMLIonTextareaElement>(null);

  const validationSchema = Yup.object({
    numberOfWheelsDamaged: Yup.string().required(
      "Number of wheels is a required field"
    ),
    tireStatus: Yup.string().required("Tire Status is a required field"),
    hasSpareWheel: Yup.boolean().required("Spare wheels a required field"),
  });

  const getInitialValues = (): WheelAndTireDetails => {
    const initialValues =
      state.steps.incidentDetails &&
      state.steps.incidentType === IncidentType.WHEEL_AND_TIRE
        ? state.steps.incidentDetails
        : {
            numberOfWheelsDamaged: undefined,
            hasSpareWheel: undefined,
            tireStatus: undefined,
            additionalInformation: "",
          };
    return initialValues as WheelAndTireDetails;
  };

  const formik = useFormik({
    initialValues: getInitialValues(),
    validationSchema,
    onSubmit: (data: WheelAndTireDetails) => handleSubmit(data),
    validate: () => {
      const shouldScroll =
        formik.values.numberOfWheelsDamaged &&
        formik.values.hasSpareWheel !== undefined &&
        !formik.values.additionalInformation;
      if (shouldScroll && textArea && textArea.current) {
        textArea.current.scrollIntoView();
      }
    },
  });

  const handleSubmit = (wheelsAndTiresIncident: WheelAndTireDetails) => {
    actions.updateSteps({ incidentDetails: wheelsAndTiresIncident });
    IncidentUtils.goToNextRoute(
      state.isInEditMode,
      state.steps.immobilizedOnHighway,
      history,
      state.summary
    );
  };

  useEffect(() => {
    if (isPlatform("desktop")) {
      damageWheelsRef?.current?.setFocus();
    }
  }, []);

  return (
    <IonContent>
      <FormikProvider value={formik}>
        <ScrollToError />
        <form
          className="wheels-and-tires-component"
          onSubmit={formik.handleSubmit}
        >
          <MainHeader
            imageTitle={t("Wheel & Tire")}
            image={`${process.env.PUBLIC_URL}/assets/images/${
              process.env.REACT_APP_ALT_ICONS_PATH || ""
            }${IncidentType.WHEEL_AND_TIRE}.svg`}
          >
            <section>
              <h3>{t("How many wheels are damaged?")}</h3>
              <IonRadioGroup
                name="numberOfWheelsDamaged" // should have the same name as the form
                value={formik.values.numberOfWheelsDamaged}
                onIonChange={(e) => {
                  formik.setFieldValue("numberOfWheelsDamaged", e.detail.value);
                }}
              >
                {numberOfWheelsDamaged.map(({ label, value }, i) => {
                  return (
                    <IonItem key={i} className="options">
                      <span className={"tab capitalize"}>{t(label)}</span>
                      <IonRadio slot={radioStart} value={value} />
                    </IonItem>
                  );
                })}
              </IonRadioGroup>
              {FormValidation.getFormErrorMessage(
                "numberOfWheelsDamaged",
                formik,
                t
              )}
            </section>
            <section>
              <h3>
                {t(
                  "Which of the following statements matches your issue the most?"
                )}
              </h3>
              <IonRadioGroup
                name="tireStatus" // should have the same name as the form
                value={formik.values.tireStatus}
                onIonChange={(e) => {
                  formik.setFieldValue("tireStatus", e.detail.value);
                }}
              >
                {tireStatus.map(({ label, value }, i) => {
                  return (
                    <IonItem key={i}>
                      <span className={"tab capitalize"}>{t(label)}</span>
                      <IonRadio slot={radioStart} value={value} />
                    </IonItem>
                  );
                })}
              </IonRadioGroup>
              {FormValidation.getFormErrorMessage("tireStatus", formik, t)}
            </section>
            <section>
              <h3>{t("Is a spare wheel available on site?")}</h3>
              <IonRadioGroup
                name="hasSpareWheel" // should have the same name as the form
                value={formik.values.hasSpareWheel}
                onIonChange={(e) => {
                  formik.setFieldValue("hasSpareWheel", e.detail.value);
                }}
              >
                {hasSpareWheel.map(({ label, value }, i) => {
                  return (
                    <IonItem key={i} className="options">
                      <span className={"tab capitalize"}>{t(label)}</span>
                      <IonRadio slot={radioStart} value={value} />
                    </IonItem>
                  );
                })}
              </IonRadioGroup>
              {FormValidation.getFormErrorMessage("hasSpareWheel", formik, t)}
            </section>
            <section>
              <h6>
                {t("Additional information")}
                {` (${t("optional")})`}
              </h6>
              <IonTextarea
                rows={5}
                ref={textArea}
                id="additionalInformation"
                name="additionalInformation"
                value={formik.values.additionalInformation}
                onIonChange={formik.handleChange}
              />
              {FormValidation.getFormErrorMessage(
                "additionalInformation",
                formik,
                t
              )}
            </section>
          </MainHeader>
          <MainFooter>
            {!state.isInEditMode && (
              <IonButton
                id="wheels-tires-back"
                className="button-tcs back"
                expand="block"
                onClick={() => history.back()}
              >
                <div className="wrapper">
                  <IonIcon
                    size="large"
                    src={`${process.env.PUBLIC_URL}/assets/images/chevron-back.svg`}
                  />
                  <IonLabel>{t("Back")}</IonLabel>
                  <div />
                </div>
              </IonButton>
            )}
            {state.isInEditMode && (
              <IonButton
                id="wheels-tires-cancel"
                className="button-tcs"
                expand="block"
                onClick={() => history.back()}
              >
                <IonLabel>{t("Cancel")}</IonLabel>
              </IonButton>
            )}
            {!state.isInEditMode && (
              <IonButton
                id="wheels-tires-next"
                className="button-tcs cta"
                color="primary"
                expand="block"
                onClick={() => formik.handleSubmit()}
              >
                <div className="wrapper">
                  <div />
                  <IonLabel>{t("Next")}</IonLabel>
                  <IonIcon
                    slot="end"
                    size="large"
                    src={`${process.env.PUBLIC_URL}/assets/images/chevron-forward.svg`}
                  />
                </div>
              </IonButton>
            )}
            {state.isInEditMode && (
              <IonButton
                id="wheels-tires-save"
                className="button-tcs cta"
                color="primary"
                expand="block"
                onClick={() => formik.handleSubmit()}
              >
                <IonLabel>{t("Save")}</IonLabel>
              </IonButton>
            )}
          </MainFooter>
        </form>
      </FormikProvider>
    </IonContent>
  );
};

export default WheelsAndTiresIncident;
