import "./ScheduleService.scss";
import "react-datepicker/dist/react-datepicker.css";

import { IonButton, IonContent, IonIcon, IonLabel } from "@ionic/react";
import { useStateMachine } from "little-state-machine";
import DatePicker from "react-datepicker";

import { useHistory } from "react-router";
import { RoutePath } from "../../model/RoutePath.enum";
import { updateSteps } from "../../state/updateState";
import { MainFooter, MainHeader } from "../Layout/Layout";
import React, { useState } from "react";
import {
  addHours,
  addMinutes,
  format,
  getHours,
  isToday,
  parse,
  setHours,
  setMinutes,
} from "date-fns";
import Calendar from "../shared/Calendar/Calendar";
import * as Yup from "yup";
import { useFormik } from "formik";
import { FormValidation } from "../../helpers/FormValidation";
import { useTranslation } from "react-i18next";
import { Appointment } from "../../model/Appointment";

const dateFormat = "dd/MM/yyyy";
const hourFormat = "HH:mm";

const ScheduleService: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { actions, state } = useStateMachine({ updateSteps });
  const [time, setTime] = useState(new Date());

  const validationSchema = Yup.object({
    date: Yup.string().required(t("Date is a required field")),
    time: Yup.string()
      .required(t("Time is a required field"))
      .test(
        "future_date_test",
        "Please select the date and time of your choice",
        function (value): boolean {
          let isInFuture = true;
          if (value && formik.values.date) {
            const minDate = setMinTimeDatePicker();
            const dateArray = formik.values.date.split("/");
            const timeArray = value.split(":");
            const currentPickedDate = new Date(
              Number(dateArray[2]),
              Number(dateArray[1]) - 1,
              Number(dateArray[0]),
              Number(timeArray[0]),
              Number(timeArray[1])
            );
            isInFuture = minDate < currentPickedDate;
          }
          return isInFuture;
        }
      ),
  });

  const formik = useFormik({
    initialValues: state.steps.schedule,
    validationSchema,
    onSubmit: (schedule: Appointment) => {
      actions.updateSteps({ schedule });
      history.push(`${RoutePath.REQUEST}/${RoutePath.SUMMARY}`);
    },
  });

  const handleTimeChange = (date: Date) => {
    setTime(date);
    formik.setFieldValue("time", format(date, hourFormat));
  };

  const handleDateChange = (result: string) => {
    formik.setFieldValue("date", result);
  };

  const parseSelectedTime = (): Date | undefined => {
    if (formik.values.time) {
      const date = new Date();
      const timeArray = formik.values.time.split(":");
      date.setHours(Number(timeArray[0]));
      date.setMinutes(Number(timeArray[1]));
      return date;
    }
    return undefined;
  };

  const setMinTimeDatePicker = (): Date => {
    const currentDate = new Date();
    if (isToday(parse(formik.values.date, dateFormat, currentDate))) {
      if (getHours(currentDate) < 7) {
        return setHours(setMinutes(currentDate, 0), 7);
      }
      return addHours(addMinutes(currentDate, 0), 2);
    } else {
      return setHours(setMinutes(currentDate, 0), 7);
    }
  };

  const setMaxTimeDatePicker = (): Date => {
    return setHours(setMinutes(new Date(), 0), 22);
  };

  const computeTimeClassName = (time: Date) => {
    return time.getHours() === 7 ||
      time.getHours() === 17 ||
      (time.getHours() === 18 && time.getMinutes() === 0)
      ? "react-datepicker__time-list-item--disabled"
      : "";
  };

  return (
    <IonContent>
      <form className="fluid-loss-component" onSubmit={formik.handleSubmit}>
        <MainHeader title={t("Please select the date and time of your choice")}>
          <div className="schedule-service-container">
            <div>
              <Calendar
                name="date"
                id="date"
                currentDate={parse(formik.values.date, dateFormat, new Date())}
                onDateChange={(result: string) => handleDateChange(result)}
              />
              {FormValidation.getFormErrorMessage("date", formik, t)}
            </div>
            {formik.values.date && (
              <div className="time-picker-container">
                <DatePicker
                  name="time"
                  id="time"
                  value={formik.values.time}
                  onChange={(date: Date) => handleTimeChange(date)}
                  calendarClassName="time-picker"
                  selected={parseSelectedTime()}
                  showTimeSelect
                  showTimeSelectOnly
                  minTime={setMinTimeDatePicker()}
                  maxTime={setMaxTimeDatePicker()}
                  inline
                  timeFormat="HH:mm"
                  timeIntervals={30}
                  timeClassName={computeTimeClassName}
                />
                {FormValidation.getFormErrorMessage("time", formik, t)}
              </div>
            )}
            <p className="information">
              {t(
                "Please note that 60 min before your appointment, a TCS agent will call to organize the next steps of service"
              )}
            </p>
          </div>
        </MainHeader>
        <MainFooter>
          {!state.isInEditMode && (
            <IonButton
              id="ss-back"
              className="button-tcs back"
              expand="block"
              onClick={() => history.back()}
            >
              <div className="wrapper">
                <IonIcon
                  size="large"
                  src={`${process.env.PUBLIC_URL}/assets/images/chevron-back.svg`}
                />
                <IonLabel>{t("Back")}</IonLabel>
                <div />
              </div>
            </IonButton>
          )}
          {state.isInEditMode && (
            <IonButton
              id="ss-cancel"
              className="button-tcs"
              expand="block"
              onClick={() => history.back()}
            >
              <IonLabel>{t("Cancel")}</IonLabel>
            </IonButton>
          )}
          {!state.isInEditMode && (
            <IonButton
              id="ss-next"
              className="button-tcs cta"
              color="primary"
              expand="block"
              onClick={() => formik.handleSubmit()}
            >
              <div className="wrapper">
                <div />
                <IonLabel>{t("Next")}</IonLabel>
                <IonIcon
                  slot="end"
                  size="large"
                  src={`${process.env.PUBLIC_URL}/assets/images/chevron-forward.svg`}
                />
              </div>
            </IonButton>
          )}
          {state.isInEditMode && (
            <IonButton
              id="ss-save"
              className="button-tcs cta"
              color="primary"
              expand="block"
              onClick={() => formik.handleSubmit()}
            >
              <IonLabel>{t("Save")}</IonLabel>
            </IonButton>
          )}
        </MainFooter>
      </form>
    </IonContent>
  );
};

export default ScheduleService;
