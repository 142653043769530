import { IncidentType } from "../model/IncidentType.enum";
import { RoutePath } from "../model/RoutePath.enum";
import {
  StarterIssueDetails,
  StarterIssueIncidentOptions,
} from "../model/Incident/StarterIssueDetails";
import { BicycleDetails } from "../model/Incident/BicycleDetails";
import {
  EngineDetails,
  EngineIncidentOptions,
} from "../model/Incident/EngineDetails";
import {
  DoorsAndKeysDetails,
  DoorsAndKeysIncidentType,
} from "../model/Incident/DoorsAndKeysDetails";
import { OtherDetails } from "../model/Incident/OtherDetails";
import { WheelAndTireDetails } from "../model/Incident/WheelAndTireDetails";
import {
  FluidLossDetails,
  FluidLossFluidType,
} from "../model/Incident/FluidLossDetails";
import {
  FuelDetails,
  FuelIncidentType,
  FuelTypeOptions,
} from "../model/Incident/FuelDetails";
import { RSAServiceDelivery, Vehicle } from "../api/models/Case";
import { StarterIssueUtils } from "./Incidents/StarterIssueUtils";
import { FuelUtils } from "./Incidents/FuelUtils";
import { FluidLossUtils } from "./Incidents/FluidLossUtils";
import { EngineUtils } from "./Incidents/EngineUtils";
import { DoorAndKeysUtils } from "./Incidents/DoorAndKeysUtils";
import { OtherUtils } from "./Incidents/OtherUtils";
import { BicycleUtils } from "./Incidents/BicycleUtils";
import { VehicleType } from "./VehicleType";
import { DigitalIntakeSteps } from "../types/global";
import { TFunction } from "react-i18next";
import { CountryUtils } from "./CountryUtils";
import { LocationFields } from "../components/Location/GeoLocation/GeoLocation";

export type SerializedIncidents =
  | Partial<StarterIssueDetails>
  | Partial<EngineDetails>
  | Partial<WheelAndTireDetails>
  | Partial<FuelDetails>
  | Partial<FluidLossDetails>
  | Partial<DoorsAndKeysDetails>
  | Partial<OtherDetails>
  | Partial<BicycleDetails>;

export class IncidentUtils {
  private static readonly incidentTypeToRoutes: {
    [incidentType: string]: string;
  } = {
    [IncidentType.OTHER]: RoutePath.OTHER_INCIDENT,
    [IncidentType.START_ISSUE]: RoutePath.STARTER_ISSUE_INCIDENT,
    [IncidentType.BICYCLE]: RoutePath.BICYCLE_INCIDENT,
    [IncidentType.ENGINE]: RoutePath.ENGINE_INCIDENT,
    [IncidentType.DOOR_AND_KEYS]: RoutePath.DOORS_AND_KEYS_INCIDENT,
    [IncidentType.WHEEL_AND_TIRE]: RoutePath.WHEELS_AND_TIRES,
    [IncidentType.FUEL]: RoutePath.FUEL_INCIDENT,
    [IncidentType.FLUID_LOSS]: RoutePath.FLUID_LOSS_INCIDENT,
    [IncidentType.ACCIDENT]: RoutePath.INCIDENT_TYPE,
  };

  public static findRouteByIncidentType(incidentType: string): string {
    const result = this.incidentTypeToRoutes[incidentType];
    return result ? result : "";
  }

  public static getSummary(
    type: IncidentType | undefined,
    incident: SerializedIncidents
  ): string {
    switch (type) {
      case IncidentType.START_ISSUE:
        return StarterIssueUtils.getSummary(incident as StarterIssueDetails);
      case IncidentType.FUEL:
        return FuelUtils.getSummary(incident as FuelDetails);
      case IncidentType.FLUID_LOSS:
        return FluidLossUtils.getSummary(incident as FluidLossDetails);
      case IncidentType.ENGINE:
        return EngineUtils.getSummary(incident as EngineDetails);
      case IncidentType.WHEEL_AND_TIRE:
        return "";
      case IncidentType.DOOR_AND_KEYS:
        return DoorAndKeysUtils.getSummary(incident as DoorsAndKeysDetails);
      case IncidentType.OTHER:
        return OtherUtils.getSummary(incident as OtherDetails);
      case IncidentType.BICYCLE:
        return BicycleUtils.getSummary(incident as BicycleDetails);
      default:
        return "";
    }
  }

  public static getItemType(): Partial<Vehicle> {
    return {
      TypeOfItem: VehicleType.vehicle,
      ItemType: "string",
    };
  }

  public static getServiceType(
    location: LocationFields
  ): Partial<RSAServiceDelivery> {
    let typeOfService = "rsaservicedelivery";
    let serviceType = "1";

    /*if (!CountryUtils.isInSwitzerlandOrLiechtenstein(location.googleResult) && location.address) {
      typeOfService = "onsiteservicedelivery";
      serviceType = "10";
    }*/

    return {
      TypeOfService: typeOfService,
      ServiceType: serviceType,
      Product: {
        Name: process.env.REACT_APP_SERVICE_DELIVERY_PRODUCT_NAME,
      },
      Businessunit: "RSA",
      ReportComplete: false,
      Active: true,
    };
  }

  public static goToNextRoute(
    isInEditMode: boolean,
    immobilizedOnHighway: boolean | undefined,
    history: any,
    summary: DigitalIntakeSteps
  ): void {
    if (isInEditMode) {
      // If incident different from bicycle we need the licence plate to be filled
      if (
        summary.incidentType !== IncidentType.BICYCLE &&
        summary.vehicleDetails.licensePlateNumber.trim() === "" &&
        summary.vehicleDetails.shortLicencePlate.trim() === ""
      ) {
        history.push(`${RoutePath.REQUEST}/${RoutePath.VEHICLE_DETAILS}`);
      } else {
        history.push(`${RoutePath.REQUEST}/${RoutePath.SUMMARY}`);
      }
    } else {
      //immobilizedOnHighway || (process.env.REACT_APP_SKIP_PARKING_TYPE && process.env.REACT_APP_SKIP_PARKING_TYPE === "true")
      //? history.push(`${RoutePath.REQUEST}/${RoutePath.LOCATE_ME_NOW}`)
      //: history.push(`${RoutePath.REQUEST}/${RoutePath.PARKING_TYPE}`);
      immobilizedOnHighway
        ? history.push(`${RoutePath.REQUEST}/${RoutePath.LOCATION}`)
        : history.push(`${RoutePath.REQUEST}/${RoutePath.LOCATE_ME_NOW}`);
    }
  }

  public static displayOptionalTag(
    type: IncidentType | undefined,
    option: string | FluidLossFluidType | undefined,
    t: TFunction<"translation">,
    fuelType?: string
  ): string {
    if (!type) {
      return "";
    }

    const startIssue =
      IncidentType.START_ISSUE === type &&
      option !== StarterIssueIncidentOptions.OTHER;
    const engine =
      IncidentType.ENGINE === type && option !== EngineIncidentOptions.OTHER;
    const doorKeys =
      IncidentType.DOOR_AND_KEYS === type &&
      option !== DoorsAndKeysIncidentType.OTHER;
    const fuel =
      IncidentType.FUEL === type &&
      option !== FuelIncidentType.OTHER &&
      (!fuelType || (fuelType && fuelType !== FuelTypeOptions.OTHER));
    const fluidLoss =
      IncidentType.FLUID_LOSS === type &&
      option !== FluidLossFluidType.OTHER_UNKNOWN;

    if (startIssue || engine || doorKeys || fuel || fluidLoss) {
      return ` (${t("optional")})`;
    } else {
      return "";
    }
  }

  public static formatAdditionalInformation(
    additionalInformation: string | undefined
  ): string {
    if (!additionalInformation || additionalInformation === "") {
      return "";
    }
    return `Zusätzliche Informationen : ${additionalInformation}`;
  }
}
