import { VehicleDetail } from "../model/VehicleDetail";
import { Vehicle } from "../api/models/Case";
import CarModels from "../assets/utils/car-model.json";
import carBrands from "../assets/utils/car-brand";
import carColors from "../assets/utils/car-color";
import { VehicleColor } from "../api/models/VehicleInfos";
import { MofisColorsEnum } from "../model/MofisColors.enum";
import i18n from "i18next";
import { CountryUtils } from "./CountryUtils";

interface Values {
  de: string;
  en: string;
  it: string;
  fr: string;
}

interface Car {
  deleted?: boolean;
  key: string;
  values: Values;
  active?: boolean;
  sortOrder?: number;
  additional?: string;
  parentCode?: string;
}

export class VehicleDetailsUtils {
  public static serializeVehicleDefaults(
    details: VehicleDetail,
    lang: string
  ): Partial<Vehicle> {
    return {
      Numberplate: VehicleDetailsUtils.getNumberPlate(details),
      ImmatriculationCountry: CountryUtils.isSwitzerlandCountry(details.country)
        ? VehicleDetailsUtils.isSpecialPlate(details.canton)
          ? details.canton
          : CountryUtils.getCountryCodeByNonEnglishName(details.country)
        : CountryUtils.getCountryCodeByNonEnglishName(details.country),
      Brand: VehicleDetailsUtils.getKeyByValue(carBrands, details.brand, lang),
      Model: VehicleDetailsUtils.getKeyByValue(CarModels, details.model, lang),
      Color: VehicleDetailsUtils.getKeyByValue(carColors, details.color, lang),
      ...(process.env.REACT_APP_ENABLE_VIN_INPUT && {
        VIN: details.vin,
      }),
    };
  }

  public static getNumberPlate = (vehicleDetail: VehicleDetail) => {
    if (CountryUtils.isSwitzerlandCountry(vehicleDetail.country)) {
      if (VehicleDetailsUtils.isSpecialPlate(vehicleDetail.canton)) {
        return vehicleDetail.shortLicencePlate
          .toUpperCase()
          .replace(/[^A-Z0-9]+/g, "");
      } else {
        return (vehicleDetail.canton + vehicleDetail.shortLicencePlate)
          .toUpperCase()
          .replace(/[^A-Z0-9]+/g, "");
      }
    } else {
      return vehicleDetail.licensePlateNumber
        .toUpperCase()
        .replace(/[^A-Z0-9]+/g, "");
    }
  };

  public static getKeyByValue(
    object: Car[],
    brand: string,
    lang: string
  ): string | undefined {
    const result = object.find((car) => {
      if (car.values) {
        return (
          car.values[lang as keyof typeof car.values] === brand ?? undefined
        );
      }
      return undefined;
    });

    return result ? result.key : undefined;
  }

  public static translateVehicleColor(vehicleColor: VehicleColor): string {
    const colorCode: string = vehicleColor.code;
    switch (i18n.language) {
      case "en":
        return MofisColorsEnum[
          ("COLOR_" + colorCode) as keyof typeof MofisColorsEnum
        ];
      default:
        if (
          vehicleColor.translations.find((i) => i.langage == i18n.language)!
            .name
        ) {
          return vehicleColor.translations.find(
            (i) => i.langage == i18n.language
          )!.name;
        } else {
          return "";
        }
    }
  }

  public static isSpecialPlate(licencePlate: string) {
    return licencePlate == "CC" || licencePlate == "CD" || licencePlate == "AT";
  }
}
