import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import TagManager from "react-gtm-module";

if (process.env.REACT_APP_THEME_SCSS_FILE_LOCATION) {
  require(`${process.env.REACT_APP_THEME_SCSS_FILE_LOCATION}`);
}

const {
  REACT_APP_GTM_DISABLE,
  REACT_APP_GTM_AUTH,
  REACT_APP_GTM_PREVIEW,
  REACT_APP_GTM_ID,
  REACT_APP_BASE_URL,
  REACT_APP_CLIENT_ID,
} = process.env;

if (!REACT_APP_BASE_URL || !REACT_APP_CLIENT_ID) {
  throw Error("Please set the app client id and base URL!");
}

if (
  process.env.NODE_ENV !== "development" &&
  REACT_APP_GTM_DISABLE !== "true"
) {
  if (!REACT_APP_GTM_AUTH || !REACT_APP_GTM_PREVIEW || !REACT_APP_GTM_ID) {
    throw Error("Please set google tag environment variables!");
  }
  const tagManagerArgs = {
    gtmId: REACT_APP_GTM_ID,
    auth: REACT_APP_GTM_AUTH,
    preview: REACT_APP_GTM_PREVIEW,
  };

  TagManager.initialize(tagManagerArgs);
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
