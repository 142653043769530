import "./AbroadAdditionalInfos.scss";

import React, { FormEventHandler, useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { MainFooter, MainHeader } from "../Layout/Layout";
import { useStateMachine } from "little-state-machine";
import {
  IonContent,
  IonInput,
  IonDatetime,
  IonLabel,
  IonItem,
  IonButton,
  IonIcon,
  IonCheckbox,
} from "@ionic/react";
import {
  updateSteps,
  updateSummary,
  updateIsAbroadCase,
  updateTemporaryLocation,
  updateHasSubmittedLocation,
} from "../../state/updateState";
import { RoutePath } from "../../model/RoutePath.enum";
import * as Yup from "yup";
import { FormikProvider, useFormik } from "formik";
import { FormValidation } from "../../helpers/FormValidation";
import { AbroadCaseDetail } from "../../model/AbroadCaseDetail";
import { CountryUtils } from "../../helpers/CountryUtils";
import { useJsApiLoader } from "@react-google-maps/api";
import useOnlineStatus from "../../hooks/useOnlineStatus";
import { Libraries } from "@react-google-maps/api/dist/utils/make-load-script-url";
const libraries: Libraries = ["places"];

const AbroadAdditionalInfos: React.FC = () => {
  const { t } = useTranslation();
  const { actions, state } = useStateMachine({
    updateSteps,
    updateSummary,
    updateIsAbroadCase,
    updateTemporaryLocation,
    updateHasSubmittedLocation,
  });
  const [returnDate, setReturnDate] = useState(
    state.steps.abroadCaseDetail.returnDate
  );
  const [noReturnDate, setNoReturnDate] = useState(
    state.steps.abroadCaseDetail.noReturnDate
  );

  const todaysDate = new Date();
  const stringTodaysDate = todaysDate.toLocaleDateString("se-SE");
  const history = useHistory();

  const requiredMessage = t(
    "Either indicate a date or check 'Date of return not defined'"
  );

  const returnDateEl = useRef<HTMLIonInputElement>(null);
  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY
      ? process.env.REACT_APP_GOOGLE_API_KEY
      : "",
    libraries,
  });
  const isOnline = useOnlineStatus();
  useEffect(() => {
    if (isLoaded && isOnline) {
      if (state.steps.locationFields?.hasUpdatedAddress == false) {
        geocode(state.temporaryLocation?.location as any);
      }
    }
  }, [isLoaded, isOnline]);
  const geocode = async (loc: google.maps.LatLng) => {
    try {
      const geocoder = new google.maps.Geocoder();
      const resp = await geocoder.geocode({ location: loc });
      const results = resp.results;
      if (results && results.length > 0) {
        let result;
        const isOnHighway =
          (!state.isInEditMode && state.steps.immobilizedOnHighway) ||
          (state.isInEditMode && state.summary.immobilizedOnHighway);
        if (isOnHighway) {
          result = results.find((gr) =>
            gr.types.some((type) => ["postal_code"].includes(type))
          );
          // fallback in case no address with the type postal code
          result = result
            ? result
            : results.find((gr) =>
                gr.types.some((type) =>
                  ["locality", "political"].includes(type)
                )
              );
        } else {
          // The first element returned by googles maps is the more precise.
          // Just remove the results that contain plus_code since contains
          // unreadable info for the user ( indicates an encoded location reference)
          result = results.find((value) => !value.types.includes("plus_code"));
        }

        if (result) {
          let locationFields = state.steps.locationFields;
          locationFields.address = result.formatted_address;
          locationFields.googleResult = result;
          actions.updateSteps({ locationFields });
          actions.updateTemporaryLocation(undefined);
          actions.updateHasSubmittedLocation(true);
          if (CountryUtils.isInSwitzerlandOrLiechtenstein(result)) {
            actions.updateIsAbroadCase(false);
            history.push(`${RoutePath.REQUEST}/${RoutePath.PERSONAL_DETAILS}`, {
              from: RoutePath.LOCATION,
            });
          }
        } else {
          console.log("No results");
        }
      } else {
        console.log("No results");
      }
    } catch (e) {
      console.log(e);
    }
  };
  const formik = useFormik({
    initialValues: state.steps.abroadCaseDetail,
    onSubmit: () => goToNextRoute(),
    validate: (values: AbroadCaseDetail) => {
      const errors = {};
      setNoReturnDate(formik.values.noReturnDate);
      setReturnDate(formik.values.returnDate);
      if (formik.values.noReturnDate === false && !formik.values.returnDate) {
        (errors as any).returnDate = requiredMessage;
      }

      return errors;
    },
  });

  const goToNextRoute = () => {
    let abroadCaseDetail = { ...(formik.values as AbroadCaseDetail) };
    actions.updateSteps({ abroadCaseDetail });
    if (state.isInEditMode) {
      actions.updateSummary({ abroadCaseDetail: abroadCaseDetail });
      history.push(`${RoutePath.REQUEST}/${RoutePath.SUMMARY}`);
    } else {
      history.push(`${RoutePath.REQUEST}/${RoutePath.PERSONAL_DETAILS}`, {
        from: RoutePath.ABROAD_ADDITIONAL_INFOS,
      });
    }
  };
  const handleSubmit = (hasCreditCard: boolean) => {
    formik.setFieldValue("hasCreditCard", hasCreditCard);
    formik.handleSubmit();
  };

  return (
    <IonContent>
      <FormikProvider value={formik}>
        <MainHeader
          title={t("lbl_return_date_question")}
          titleStyle={{ textAlign: "left" }}
        >
          <form
            className="abroad-case-component"
            onSubmit={formik.handleSubmit}
          >
            <section>
              {/* No way to not set default picked date...
          <IonDatetime
              placeholder={t("Select a Date")}
              min={todaysDate.toISOString()}
              max={oneYearFromNow.toISOString()}
              display-format="DD.MM.YYYY"
              picker-format="DD.MM.YYYY"
              presentation="date"
              value={null}
              onIonChange={(value) => handleChange(value.detail.value!) }
          ></IonDatetime>*/}
              <IonInput
                ref={returnDateEl}
                type="date"
                min={stringTodaysDate}
                value={formik.values.returnDate}
                disabled={noReturnDate}
                id="returnDate"
                name="returnDate"
                placeholder={t("dd/mm/yyyy")}
                onIonChange={(value) =>
                  formik.setFieldValue("returnDate", value.detail.value)
                }
              ></IonInput>
              {FormValidation.getFormErrorMessage("returnDate", formik, t)}
              <IonItem>
                <IonCheckbox
                  slot="start"
                  id="noReturnDate"
                  name="noReturnDate"
                  checked={formik.values.noReturnDate}
                  value={true}
                  onIonChange={(value) =>
                    formik.setFieldValue("noReturnDate", value.detail.checked)
                  }
                  /*onIonChange={(el) => setNoReturnDate(el.detail.checked)}*/
                ></IonCheckbox>
                <IonLabel>{t("Date of return not defined")}</IonLabel>
              </IonItem>
            </section>
            <section>
              <h3>{t("lbl_cc_question")}</h3>
              <div className="button-grid">
                <IonButton
                  id="abroad-case-btn"
                  className="button-tcs"
                  color="secondary"
                  expand="block"
                  type="button"
                  onClick={() => handleSubmit(true)}
                >
                  {t("Yes")}
                </IonButton>
                <IonButton
                  id="abroad-case-btn-no"
                  className="button-tcs"
                  color="secondary"
                  expand="block"
                  type="button"
                  onClick={() => handleSubmit(false)}
                >
                  {t("No")}
                </IonButton>
              </div>
            </section>
          </form>
        </MainHeader>
      </FormikProvider>
      <MainFooter>
        {!state.isInEditMode && (
          <IonButton
            id="abroad-case-back"
            className="button-tcs back"
            expand="block"
            onClick={() =>
              history.push(`${RoutePath.REQUEST}/${RoutePath.LOCATION}`)
            }
          >
            <div className="wrapper">
              <IonIcon
                size="large"
                src={`${process.env.PUBLIC_URL}/assets/images/chevron-back.svg`}
              />
              <IonLabel>{t("Back")}</IonLabel>
              <div />
            </div>
          </IonButton>
        )}
        {state.isInEditMode && (
          <IonButton
            id="abroad-case-cancel"
            className="button-tcs"
            expand="block"
            onClick={() => history.back()}
          >
            <IonLabel>{t("Cancel")}</IonLabel>
          </IonButton>
        )}
      </MainFooter>
    </IonContent>
  );
};

export default AbroadAdditionalInfos;
