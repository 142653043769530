// t('Steering wheel blocked')
// t('Light left on / Door not closed')
// t('Clicking starter noises')
// t('Other')
export enum StarterIssueIncidentOptions {
  STEERING_WHEEL_BLOCKED = "Steering wheel blocked",
  LIGHT_LEFT_ON_DOOR_NOT_CLOSED = "Light left on / Door not closed",
  CLICKING_START_NOISES = "Clicking starter noises",
  OTHER = "Other",
}

export interface StarterIssueDetails {
  breakdownType: string;
  additionalInformation: string;
}
