import "./ParkType.scss";

import { IonButton, IonContent, IonIcon, IonLabel } from "@ionic/react";
import { useStateMachine } from "little-state-machine";

import { useHistory } from "react-router";
import { RoutePath } from "../../model/RoutePath.enum";
import { updateSteps } from "../../state/updateState";
import { MainFooter, MainHeader } from "../Layout/Layout";
import { BicycleDetails } from "../../model/Incident/BicycleDetails";
import React from "react";
import { useTranslation } from "react-i18next";
import { IncidentType } from "../../model/IncidentType.enum";

const ParkType: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { actions, state } = useStateMachine({ updateSteps });

  const isBicycleIncident = (): boolean => {
    return (
      state.steps.incidentType === IncidentType.BICYCLE &&
      (state.steps.incidentDetails as BicycleDetails).description !== ""
    );
  };

  const onOptionSelected = (isAtHome: boolean) => {
    actions.updateSteps({ parkedAtHome: isAtHome });
    if (isAtHome) {
      handleParkedAtHome(RoutePath.LOCATE_ME_NOW);
    } else {
      handleNotParkedAtHome(RoutePath.LOCATE_ME_NOW);
    }
  };

  const handleParkedAtHome = (route: string) => {
    if (state.isInEditMode) {
      history.push(`${RoutePath.REQUEST}/${RoutePath.LOCATION}`);
    } else {
      history.push(`${RoutePath.REQUEST}/${route}`);
    }
  };

  const handleNotParkedAtHome = (route: string) => {
    if (state.isInEditMode) {
      history.push(`${RoutePath.REQUEST}/${RoutePath.LOCATION}`);
    } else if (isBicycleIncident()) {
      history.push(`${RoutePath.REQUEST}/${RoutePath.LOCATE_ME_NOW}`);
    } else {
      history.push(`${RoutePath.REQUEST}/${route}`);
    }
  };

  return (
    <IonContent>
      <MainHeader
        title={t("Is your vehicle currently parked at your home?")}
        image={`${process.env.PUBLIC_URL}/assets/images/${
          process.env.REACT_APP_ALT_ICONS_PATH || ""
        }Home.svg`}
      >
        <section>
          <div className="button-grid">
            <IonButton
              id="parked-home"
              color="secondary"
              className="button-tcs"
              expand="block"
              type="button"
              onClick={() => onOptionSelected(true)}
            >
              {t("Yes")}
            </IonButton>
            <IonButton
              id="not-parked-home"
              color="secondary"
              className="button-tcs"
              expand="block"
              type="button"
              onClick={() => onOptionSelected(false)}
            >
              {t("No")}
            </IonButton>
          </div>
        </section>
      </MainHeader>
      <MainFooter>
        <IonButton
          id="parking-back"
          className="button-tcs outline full-width back"
          expand="block"
          onClick={() => history.back()}
        >
          <div className="wrapper">
            <IonIcon
              size="large"
              src={`${process.env.PUBLIC_URL}/assets/images/chevron-back.svg`}
            />
            <IonLabel>{t("Back")}</IonLabel>
            <div />
          </div>
        </IonButton>
      </MainFooter>
    </IonContent>
  );
};

export default ParkType;
