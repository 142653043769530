import "./SummaryCard.scss";
import classNames from "classnames/bind";

import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
} from "@ionic/react";
import { CardProps } from "./SummaryCardPropsModel";
import { RoutePath } from "../../../model/RoutePath.enum";
import { useTranslation } from "react-i18next";
import React from "react";
import { language } from "ionicons/icons";

const AbroadCaseCard: React.FC<CardProps> = ({
  onClick,
  isEditing,
  abroadCaseDetail,
}) => {
  const { t, i18n } = useTranslation();

  const displayReturnDate = (): string => {
    const splittedReturnDate = abroadCaseDetail.returnDate.split("-");
    return splittedReturnDate[2].concat(
      "." + splittedReturnDate[1] + "." + splittedReturnDate[0]
    );
  };

  return (
    <IonCard
      onClick={() => isEditing && onClick(RoutePath.ABROAD_ADDITIONAL_INFOS)}
      className={classNames("summary-card")}
    >
      <IonCardHeader className="card-header">
        <IonCardTitle>{t("Additional Informations")}</IonCardTitle>
      </IonCardHeader>

      <IonCardContent class="card-content">
        <div className={classNames("summary-card", { editing: isEditing })}>
          <div className="edit-icon">
            <img
              alt="pencil"
              src={`${process.env.PUBLIC_URL}/assets/images/${
                process.env.REACT_APP_ALT_ICONS_PATH || ""
              }pencil.svg`}
            />
          </div>

          <div
            className={classNames("sub-card-container", "pd-5", {
              "row-min-height": isEditing,
            })}
          >
            <p className="problem-text-title">{t("Return Date")}:</p>
            <p className="problem-description-text">
              {!abroadCaseDetail.noReturnDate
                ? displayReturnDate()
                : t("Not specified")}
            </p>
            {isEditing && <p />}
          </div>

          <div
            className={classNames("sub-card-container", "pd-5", {
              "row-min-height": isEditing,
            })}
          >
            <p className="problem-text-title">
              {t("Valid credit card for deposit")}:
            </p>
            <p className="problem-description-text">
              {abroadCaseDetail.hasCreditCard ? t("Yes") : t("No")}
            </p>
            {isEditing && <p />}
          </div>
        </div>
      </IonCardContent>
    </IonCard>
  );
};

export default AbroadCaseCard;
