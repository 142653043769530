import { TokenResponse } from "../model/OneTimePassword/one-time-password-params";
import { fromUnixTime } from "date-fns";
import jwt_decode from "jwt-decode";
import { DecodeToken } from "../components/OtpAuthentication/RequestAuthentication/RequestAuthentication";

export class GeneralUtils {
  public static isDev(): boolean {
    return !process.env.NODE_ENV || process.env.NODE_ENV === "development";
  }

  public static isTokenValid(token: TokenResponse | undefined): boolean {
    try {
      if (token && token.access_token) {
        const payload = jwt_decode(token.access_token) as DecodeToken;
        const expDate = payload?.exp && fromUnixTime(payload.exp);
        return !this.isDateExpired(expDate);
      }
      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  public static isDateExpired(dateToCheck: 0 | undefined | Date): boolean {
    return dateToCheck ? new Date() > dateToCheck : true;
  }

  public static isNumeric(str: string): boolean {
    return !isNaN(parseFloat(str)) && isFinite(Number(str));
  }

  public static orderArrayWithNumbersLast(
    a: string | number,
    b: string | number
  ): number {
    const strA = a.toString();
    const strB = b.toString();

    if (!GeneralUtils.isNumeric(strA) && !GeneralUtils.isNumeric(strB)) {
      // Both are non-numeric (strings)
      const lowerA = strA.toLowerCase();
      const lowerB = strB.toLowerCase();
      if (lowerA < lowerB) {
        return -1;
      } else if (lowerA > lowerB) {
        return 1;
      } else {
        return 0;
      }
    } else if (!GeneralUtils.isNumeric(strA) && GeneralUtils.isNumeric(strB)) {
      // 'a' is string, 'b' is numeric
      return -1;
    } else if (GeneralUtils.isNumeric(strA) && !GeneralUtils.isNumeric(strB)) {
      // 'a' is numeric, 'b' is non-numeric
      return 1;
    } else {
      // Both numbers
      return parseFloat(strA) - parseFloat(strB);
    }
  }
}
