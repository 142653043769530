import axios, {
  AxiosInstance,
  AxiosRequestHeaders,
  AxiosResponse,
} from "axios";
import {
  OneTimePasswordParams,
  OneTimePasswordResponse,
  TokenResponse,
} from "../model/OneTimePassword/one-time-password-params";
import { Case } from "./models/Case";
import { Guid } from "guid-typescript";
import { Party } from "./models/PersonalInfo";
import { APP_VERSION } from "../constants/Common";

export class Api {
  private static readonly BASE_URL = process.env.REACT_APP_BASE_URL!;
  private static readonly CLIENT_ID = process.env.REACT_APP_CLIENT_ID!;
  private static readonly APP_VERSION = APP_VERSION!;

  private static readonly API: AxiosInstance = axios.create({
    baseURL: Api.BASE_URL,
    responseType: "json",
    headers: Api.getHeaders(),
  });

  public static sendPhoneNumber(
    otp: OneTimePasswordParams
  ): Promise<AxiosResponse<OneTimePasswordResponse>> {
    return this.API.post("/exchange-clients/v2/one-time-passwords", otp);
  }

  public static sendCode(
    otp: OneTimePasswordParams
  ): Promise<AxiosResponse<TokenResponse>> {
    return this.API.post("/exchange-clients/v2/one-time-passwords/jwts", otp);
  }

  public static sendSummary(
    caseRequest: Partial<Case>,
    token: string
  ): Promise<AxiosResponse<Case>> {
    return this.API.post("/assistance/v1/cases/", caseRequest, {
      headers: this.getHeaders(token),
    });
  }

  public static getPersonalInfo(
    phoneNumber: string,
    token: string
  ): Promise<AxiosResponse<Party[]>> {
    return this.API.get<Party[]>("/client-prospects/v2/physical-persons/", {
      headers: this.getHeaders(token),
      params: {
        "phone-number": phoneNumber,
      },
    });
  }

  private static getHeaders(token?: string): AxiosRequestHeaders {
    return {
      "X-IBM-Client-Id": Api.CLIENT_ID,
      "X-Global-Transaction-ID": Guid.raw(),
      Authorization: token ? `Bearer ${token}` : "",
      "X-Consapp": "dgtint-v" + Api.APP_VERSION,
    };
  }
}
