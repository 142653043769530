import "./LocationTutorial.scss";

import {
  IonButton,
  IonContent,
  IonIcon,
  IonLabel,
  IonSlide,
  IonSlides,
} from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";

import { RoutePath } from "../../../model/RoutePath.enum";
import { MainFooter, MainHeader } from "../../Layout/Layout";
import { Trans, useTranslation } from "react-i18next";
import classNames from "classnames/bind";
import { useStateMachine } from "little-state-machine";
import { updateLocateOnLoad } from "../../../state/updateState";

const LocationTutorial: React.FC = () => {
  const history = useHistory();
  const { actions } = useStateMachine({ updateLocateOnLoad });
  const [active, setActive] = useState<number>(0);
  const [slideChange, setSlideChange] = useState<boolean>(false);
  const { t, i18n } = useTranslation();
  const mySlides = useRef<any>();

  useEffect(() => {
    const ionSlides = mySlides.current;
    if (mySlides && ionSlides !== null) {
      ionSlides.getActiveIndex().then((index: number) => {
        if (index !== active) {
          ionSlides.slideTo(active, 500);
        }
      });
    }
  }, [active]);

  useEffect(() => {
    const ionSlides = mySlides.current;
    if (mySlides && ionSlides !== null) {
      ionSlides.getActiveIndex().then((index: number) => setActive(index));
    }
  }, [slideChange]);

  const buttons = [
    {
      label: t("GPS"),
      value: 0,
      icon: `${process.env.PUBLIC_URL}/assets/images/GPS.svg`,
    },
    {
      label: t("Manual Input"),
      value: 1,
      icon: `${process.env.PUBLIC_URL}/assets/images/Manual-input.svg`,
    },
    {
      label: t("Map"),
      value: 2,
      icon: `${process.env.PUBLIC_URL}/assets/images/Pin.svg`,
    },
  ];
  const sliderOptions = {
    slidesPerView: 1,
    centeredSlides: true,
    initialSlide: 0,
    speed: 400,
  };

  const increaseActiveIndex = () => {
    setActive((prevState) => prevState + 1);
  };

  const decreaseActiveIndex = () => {
    setActive((prevState) => prevState - 1);
  };

  const handleLocateMeNow = () => {
    actions.updateLocateOnLoad(true);
    history.replace(`${RoutePath.REQUEST}/${RoutePath.LOCATE_ME_NOW}`);
    history.push(`${RoutePath.REQUEST}/${RoutePath.LOCATION}`);
  };

  const handleOnNext = () => {
    actions.updateLocateOnLoad(false);
    history.replace(`${RoutePath.REQUEST}/${RoutePath.LOCATE_ME_NOW}`);
    history.push(`${RoutePath.REQUEST}/${RoutePath.LOCATION}`);
  };

  return (
    <IonContent>
      <MainHeader title={t("Location tutorial")}>
        <div>
          <section className="location-tutorial-info">
            <div className="location-tutorial-buttons-container">
              {buttons.map((button, i) => (
                <div key={i} className="location-tutorial-button-container">
                  <IonButton
                    id={button.label}
                    color="secondary"
                    onClick={() => setActive(button.value)}
                    className={classNames("rounded-button", {
                      active: active === button.value,
                    })}
                  >
                    <IonIcon src={button.icon} />
                  </IonButton>
                  <h6 className="location-tutorial-button-title">
                    {button.label}
                  </h6>
                </div>
              ))}
            </div>
            <div className="divider" />
          </section>
          <div className="location-tutorial-slider">
            <IonButton
              id="location-chevron-decrease"
              className="arrow-button"
              fill="default"
              disabled={active === 0}
              onClick={() => decreaseActiveIndex()}
            >
              <IonIcon
                src={`${process.env.PUBLIC_URL}/assets/images/chevron-back.svg`}
              />
            </IonButton>
            <IonContent className="location-tutorial-slider-content">
              <IonSlides
                pager={true}
                options={sliderOptions}
                ref={mySlides}
                onIonSlideDidChange={() =>
                  setSlideChange((prevState) => !prevState)
                }
              >
                <IonSlide>
                  <p>
                    <Trans i18nKey="location-tutorial-text-1" />
                    <img
                      alt="gps"
                      className="gps-img"
                      src={`${process.env.PUBLIC_URL}/assets/images/GPS-black.svg`}
                    />
                    &nbsp;
                    <Trans i18nKey="location-tutorial-text-2" />
                  </p>
                  <img
                    alt="location-permission-img"
                    className="location-permission-img"
                    src={`${process.env.PUBLIC_URL}/assets/images/location-permission-${i18n.language}.svg`}
                  />
                </IonSlide>
                <IonSlide>
                  <p>
                    {t(
                      "Enter an address or a point of interest near your location"
                    )}
                  </p>

                  <img
                    alt="keyboard"
                    className="location-write-img"
                    src={`${process.env.PUBLIC_URL}/assets/images/location-write-${i18n.language}.svg`}
                  />
                </IonSlide>
                <IonSlide>
                  <p>
                    {t(
                      "Move the map with your finger to place the pin at your location"
                    )}
                  </p>

                  <img
                    alt="gesture-hand"
                    className="gesture-hand-img"
                    src={`${process.env.PUBLIC_URL}/assets/images/gesture-hand.svg`}
                  />
                </IonSlide>
              </IonSlides>
            </IonContent>
            <IonButton
              id="location-chevron-increase"
              className="arrow-button"
              fill="default"
              disabled={active === 2}
              onClick={() => increaseActiveIndex()}
            >
              <IonIcon
                src={`${process.env.PUBLIC_URL}/assets/images/chevron-forward.svg`}
              />
            </IonButton>
          </div>
          <IonButton
            id="locate-me-now"
            color="primary"
            className="button-tcs locate-me-now-button cta"
            expand="block"
            onClick={() => handleLocateMeNow()}
          >
            <IonLabel>{t("Locate me now")}</IonLabel>
          </IonButton>
        </div>
      </MainHeader>
      <MainFooter>
        <IonButton
          id="geolocation-tutorial-back"
          className="button-tcs back"
          expand="block"
          onClick={() => history.back()}
        >
          <div className="wrapper">
            <IonIcon
              size="large"
              src={`${process.env.PUBLIC_URL}/assets/images/chevron-back.svg`}
            />
            <IonLabel>{t("Back")}</IonLabel>
            <div />
          </div>
        </IonButton>
        <IonButton
          id="geolocation-tutorial-next"
          className="button-tcs cta"
          color="primary"
          expand="block"
          onClick={() => handleOnNext()}
        >
          <div className="wrapper">
            <div />
            <IonLabel>{t("Next")}</IonLabel>
            <IonIcon
              size="large"
              src={`${process.env.PUBLIC_URL}/assets/images/chevron-forward.svg`}
            />
          </div>
        </IonButton>
      </MainFooter>
    </IonContent>
  );
};

export default LocationTutorial;
