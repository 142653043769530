// t('Incorrectly refueled')
// t('Empty tank')
// t('Other')
// t('Unleaded / Gas')
// t('Diesel')
// t('Electric')

export enum FuelTypeOptions {
  UNLEADED_GAS = "Unleaded / Gas",
  DIESEL = "Diesel",
  ELECTRIC = "Electric",
  ADBLUE = "Adblue",
  OTHER = "Other",
}

export enum FuelIncidentType {
  INCORRECTLY_REFUELED = "Incorrectly refueled",
  EMPTY_TANK = "Empty tank",
  //FUEL_FILLER_BLOCKED = "Fuel filler cap/charging flap/charging plug blocked",
  FUEL_LOSS = "Fuel loss",
  FUEL_TANK_EV_CONNECTOR = "Fuel filler flap / EV connector",
  OTHER = "Other",
}

export interface FuelDetails {
  breakdownType: string;
  fuelType: string;
  wrongFuelLiters: string;
  additionalInformation: string;
}
