import "./Calendar.scss";
import React, { useEffect, useState } from "react";
import {
  format,
  startOfWeek,
  addDays,
  isSameDay,
  lastDayOfWeek,
  getWeek,
  addWeeks,
  subWeeks,
  isBefore,
  subDays,
  isAfter,
} from "date-fns";
import { IonIcon } from "@ionic/react";
import classNames from "classnames/bind";
import { DateUtils } from "../../../helpers/DateUtils";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { enUS, fr, it, de } from "date-fns/esm/locale";
/*
  t('January')
  t('February')
  t('March')
  t('April')
  t('May')
  t('June')
  t('July')
  t('August')
  t('September')
  t('October')
  t('November')
  t('December')
  t('Monday')
  t('Tuesday')
  t('Wednesday')
  t('Thursday')
  t('Friday')
  t('Saturday')
  t('Sunday')
 */

const Calendar = ({ onDateChange, currentDate }: any) => {
  const { t } = useTranslation();
  const [currentMonth, setCurrentMonth] = useState<Date>(new Date());
  const [currentWeek, setCurrentWeek] = useState<number>(getWeek(currentMonth));
  const [selectedDate, setSelectedDate] = useState<Date>(currentDate);

  useEffect(() => {
    if (DateUtils.isValidDate(selectedDate)) {
      setCurrentMonth(selectedDate);
    }
  }, []);

  const changeWeekHandle = (btnType: string) => {
    if (btnType === "prev") {
      setCurrentMonth(subWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(subWeeks(currentMonth, 1)));
    }
    if (btnType === "next") {
      setCurrentMonth(addWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(addWeeks(currentMonth, 1)));
    }
  };

  const onDateClickHandle = (day: Date, dayStr: string) => {
    setSelectedDate(day);
    onDateChange(dayStr);
  };

  const getLocaleByLanguage = (): Locale => {
    switch (i18n.language) {
      case "fr":
        return fr;
      case "it":
        return it;
      case "de":
        return de;
      default:
        return enUS;
    }
  };
  const renderHeader = () => {
    const dateFormat = "MMMM yyyy";
    return (
      <div className="header">
        <div className="header-back">
          <IonIcon
            size="large"
            src={`${process.env.PUBLIC_URL}/assets/images/chevron-back.svg`}
            onClick={() => changeWeekHandle("prev")}
          />
        </div>
        <span className="header-month">
          {format(currentMonth, dateFormat, { locale: getLocaleByLanguage() })}
        </span>
        <div className="header-forward">
          <IonIcon
            size="large"
            src={`${process.env.PUBLIC_URL}/assets/images/chevron-forward.svg`}
            onClick={() => changeWeekHandle("next")}
          />
        </div>
      </div>
    );
  };
  const renderDays = () => {
    const dateFormat = "EEEE";
    const days = [];
    let startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="days-col" key={i}>
          {t(format(addDays(startDate, i), dateFormat))}
        </div>
      );
    }
    return <div className="days row">{days}</div>;
  };

  const renderCells = () => {
    const startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
    const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 1 });
    const dateFormat = "d";
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = "";
    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        const cloneDay = day;
        days.push(
          <div
            className={classNames("cell", {
              disabled:
                isBefore(day, subDays(new Date(), 1)) ||
                isAfter(day, addDays(new Date(), 7)),
              selected: isSameDay(day, selectedDate),
            })}
            key={day.toISOString()}
            onClick={() => {
              const dayStr = format(cloneDay, "dd/MM/yyyy");
              onDateClickHandle(cloneDay, dayStr);
            }}
          >
            <span>{formattedDate}</span>
          </div>
        );
        day = addDays(day, 1);
      }

      rows.push(
        <div className="row" key={day.toISOString()}>
          {days}
        </div>
      );
      days = [];
    }
    return <div className="body">{rows}</div>;
  };

  return (
    <div className="calendar">
      {renderHeader()}
      {renderDays()}
      {renderCells()}
    </div>
  );
};

export default Calendar;
