import "./SummaryCard.scss";
import classNames from "classnames/bind";

import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
} from "@ionic/react";
import { CardProps } from "./SummaryCardPropsModel";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import React, { useCallback, useState } from "react";
import { Libraries } from "@react-google-maps/api/dist/utils/make-load-script-url";
import { RoutePath } from "../../../model/RoutePath.enum";
import { useTranslation } from "react-i18next";
import { LocationUtils } from "../../../helpers/LocationUtils";
import { IncidentType } from "../../../model/IncidentType.enum";
import { useStateMachine } from "little-state-machine";

const libraries: Libraries = ["places"];

const LocationCard: React.FC<CardProps> = ({
  onClick,
  isEditing,
  locationFields,
  immobilizedOnHighway,
  parkedAtHome,
  incidentType,
  additionalInformationLabel,
}) => {
  const { t } = useTranslation();
  const { state } = useStateMachine();
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY
      ? process.env.REACT_APP_GOOGLE_API_KEY
      : "",
    libraries,
  });
  const containerStyle = { width: "100%", height: "100%", minHeight: "200px" };
  const [map, setMap] = useState<google.maps.Map>();
  const onUnmount = useCallback(() => setMap(undefined), []);
  const options: google.maps.MapOptions = {
    streetViewControl: false,
    fullscreenControl: false,
    mapTypeControl: false,
    draggable: false,
    zoomControl: false,
    clickableIcons: false,
    gestureHandling: "cooperative",
  };

  const getFormattedAddress = (): string | undefined => {
    let address = [
      //locationFields.placeName,
      LocationUtils.formattedAddressFromGoogleResult(
        locationFields.googleResult
      ),
    ]
      .filter(Boolean)
      .join(", ");
    address = state.locationAdditionalInfo
      ? `${state.locationAdditionalInfo}, ${address}`
      : address;
    return address ? address : t("No address specified");
  };

  return (
    <IonCard className={classNames("summary-card")}>
      <IonCardHeader className="card-header">
        <IonCardTitle>{t("Location")}</IonCardTitle>
      </IonCardHeader>

      <IonCardContent class="card-content">
        {incidentType !== IncidentType.BICYCLE && (
          <div
            className={classNames("summary-card", "mb-10", {
              editing: isEditing,
            })}
            onClick={() => isEditing && onClick(RoutePath.HIGHWAY_IMMOBILIZED)}
          >
            <div className="edit-icon">
              <img
                alt="pencil"
                src={`${process.env.PUBLIC_URL}/assets/images/${
                  process.env.REACT_APP_ALT_ICONS_PATH || ""
                }pencil.svg`}
              />
            </div>

            <div
              className={classNames("sub-card-container", "pd-5", {
                "row-min-height": isEditing,
              })}
            >
              <p className="problem-text-title">{t("Highway assistance")}:</p>
              <p className="problem-description-text">
                {immobilizedOnHighway ? t("Yes") : t("No")}
              </p>
              {isEditing && <p />}
            </div>
          </div>
        )}

        {immobilizedOnHighway === false && (
          <div
            className={classNames("summary-card", "mb-10", {
              editing: isEditing,
            })}
            onClick={() => isEditing && onClick(RoutePath.LOCATE_ME_NOW)}
          >
            <div className="edit-icon">
              <img
                alt="pencil"
                src={`${process.env.PUBLIC_URL}/assets/images/${
                  process.env.REACT_APP_ALT_ICONS_PATH || ""
                }pencil.svg`}
              />
            </div>

            <div
              className={classNames("sub-card-container", "pd-5", {
                "row-min-height": isEditing,
              })}
            >
              <p className="problem-text-title">{t("Domicile assistance:")}</p>
              <p className="problem-description-text">{parkedAtHome}</p>
              {isEditing && <p />}
            </div>
          </div>
        )}

        <div
          className={classNames("summary-card", { editing: isEditing })}
          onClick={() => {
            if (isEditing) {
              if (immobilizedOnHighway === true) {
                if (
                  state.summary.parkedAtHome === true ||
                  state.locateMeNow === false
                ) {
                  onClick(RoutePath.LOCATE_ME_NOW);
                } else {
                  onClick(RoutePath.LOCATION);
                }
              } else {
                onClick(RoutePath.LOCATION);
              }
            }
          }}
        >
          <div className="edit-icon">
            <img
              alt="pencil"
              src={`${process.env.PUBLIC_URL}/assets/images/${
                process.env.REACT_APP_ALT_ICONS_PATH || ""
              }pencil.svg`}
            />
          </div>

          <div
            className={classNames("sub-card-container", "pd-5", {
              "row-min-height": !locationFields.additionalInformation,
            })}
          >
            <p className="problem-text-title">{t("Address")}:</p>
            <p className="problem-description-text">{getFormattedAddress()}</p>
            {isEditing && <p />}
          </div>

          {locationFields.additionalInformation && (
            <div className={classNames("sub-card-container", "pd-5")}>
              <p className="problem-text-title">
                {additionalInformationLabel}:
              </p>
              <p className="problem-description-text">
                {locationFields.additionalInformation}
              </p>
              {isEditing && <p />}
            </div>
          )}

          {isLoaded && locationFields.location && (
            <>
              <br />
              <GoogleMap
                mapContainerStyle={containerStyle}
                onUnmount={onUnmount}
                zoom={16}
                center={locationFields.location}
                options={options}
              >
                <Marker
                  position={locationFields.location}
                  icon={{
                    url: `${process.env.PUBLIC_URL}${process.env.REACT_APP_PIN_ICON}`,
                    scaledSize: new window.google.maps.Size(35, 35),
                  }}
                />
              </GoogleMap>
            </>
          )}
        </div>
      </IonCardContent>
    </IonCard>
  );
};

export default LocationCard;
