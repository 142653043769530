import React, { useState } from "react";
import "./SearchModal.scss";

import { getMode } from "@ionic/core";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonSearchbar,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useTranslation } from "react-i18next";

interface SearchProps {
  selectedValue: (value: string) => void;
  elements: string[];
  onDismissModal: () => void;
  title: string;
}

const SearchModal: React.FC<SearchProps> = ({
  elements,
  onDismissModal,
  selectedValue,
  title,
}) => {
  const { t } = useTranslation();
  const [allResults, setAllResults] = useState<any[]>(elements);
  const ios = getMode() === "ios";

  const filterData = (value: string | undefined) => {
    if (value) {
      setAllResults(
        elements.filter((v: string) =>
          v.toLowerCase().includes(value.trim().toLowerCase())
        )
      );
    } else {
      setAllResults(elements);
    }
  };

  const onItemClick = (value: string) => {
    selectedValue(value);
    onDismissModal();
  };

  return (
    <div className="search-modal-page">
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonTitle className="search-modal-title">{title}</IonTitle>
          <IonButtons slot="end">
            <IonButton id="close-modal" onClick={onDismissModal}>
              <IonIcon
                size="large"
                src={`${process.env.PUBLIC_URL}/assets/images/close.svg`}
              />
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar
            className="search-modal-searchbar"
            placeholder={t("Search")}
            onIonChange={(e) => filterData(e.detail.value)}
          />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList lines={ios ? "inset" : "full"} className="search-modal-list">
          <IonList>
            {allResults.map((value, index) => (
              <IonItem onClick={() => onItemClick(value)} key={index}>
                {value}
              </IonItem>
            ))}
          </IonList>
        </IonList>
      </IonContent>
    </div>
  );
};

export default SearchModal;
