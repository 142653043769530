import "./Home.scss";

import {
  IonAlert,
  IonButtons,
  IonContent,
  IonHeader,
  IonLabel,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToast,
  IonToolbar,
} from "@ionic/react";
import { useHistory, useLocation } from "react-router";

import MainContainer from "../components/MainContainer/MainContainer";
import { RoutePath } from "../model/RoutePath.enum";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import useOnlineStatus from "../hooks/useOnlineStatus";

const Home: React.FC = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const isOnline = useOnlineStatus();
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const logo = `${process.env.PUBLIC_URL}${process.env.REACT_APP_THEME_LOGO}`;
  const MIN_DELAY_FOR_ALREADY_SUBMITTED_CASE = 30;

  useEffect(() => {
    if (isOnline && showAlert && isNetworkNeeded()) {
      setShowAlert(false);
      window.location.reload();
    } else if (!isOnline && isNetworkNeeded()) {
      setShowAlert(true);
    }

    const alreadySubmittedCase = localStorage.getItem("alreadySubmittedCase");
    if (alreadySubmittedCase) {
      const ASCValidDate = new Date(
        new Date().getTime() - MIN_DELAY_FOR_ALREADY_SUBMITTED_CASE * 60000
      );
      if (new Date(alreadySubmittedCase) > ASCValidDate) {
        const isOnCaseAlreadySubmitted =
          history.location.pathname.split("/").pop() ===
          RoutePath.CASE_ALREADY_SUBMITTED;
        const appShouldReinitialize = localStorage.getItem(
          "appShouldReinitialize"
        );
        if (appShouldReinitialize && !isOnCaseAlreadySubmitted) {
          history.push(
            `${RoutePath.REQUEST}/${RoutePath.CASE_ALREADY_SUBMITTED}`
          );
        } else {
          localStorage.setItem("appShouldReinitialize", "true");
        }
      } else {
        localStorage.removeItem("alreadySubmittedCase");
        localStorage.removeItem("appShouldReinitialize");
      }
    }
  }, [isOnline, location]);

  const isNetworkNeeded = (): boolean => {
    const result = history.location.pathname.split("/").pop();
    return (
      result === RoutePath.REQUEST_AUTHENTICATION ||
      result === RoutePath.VALIDATE_AUTHENTICATION ||
      result === RoutePath.LOCATION ||
      result === RoutePath.SUMMARY
    );
  };
  const shouldDisplayHeader = () => {
    const route = history.location.pathname.split("/");
    return route[route.length - 1] !== RoutePath.THANK_YOU;
  };
  return (
    <IonPage className="home-component">
      {shouldDisplayHeader() && (
        <>
          <IonHeader className="ion-no-border">
            <img alt="logo" className="logo" src={logo} />
            <IonToolbar>
              <IonTitle>
                <div className="header-title">{t("Online Assistance")}</div>
              </IonTitle>
              <IonButtons slot="end">
                <IonMenuButton>
                  <IonLabel className="header-lang">
                    {i18n.language.toUpperCase()}
                  </IonLabel>
                </IonMenuButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
        </>
      )}
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar class="new-background-color">
            <IonTitle>Digital Intake</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div />
        <MainContainer />
      </IonContent>
      <IonAlert
        backdropDismiss={false}
        cssClass="no-connection-alert"
        isOpen={showAlert}
        header={t("Alert")}
        message={t(
          "Network connection is needed to continue. \n Please call 0800 140 140 to speak to an agent directly."
        )}
        onDidDismiss={() => setShowAlert(false)}
      />
      <IonToast
        position="top"
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        message={t("No network connection.")}
      />
    </IonPage>
  );
};

export default Home;
