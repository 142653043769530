import "./Summary.scss";
import {
  IonAlert,
  IonButton,
  IonContent,
  IonLabel,
  IonLoading,
  IonToast,
} from "@ionic/react";
import { useStateMachine } from "little-state-machine";
import { useHistory } from "react-router";
import {
  updateEditMode,
  updateIsBCMFallBack,
  updateSteps,
  updateSummary,
} from "../../state/updateState";

import { RoutePath } from "../../model/RoutePath.enum";
import IssueCard from "./Card/IssueCard";
import LocationCard from "./Card/LocationCard";
import CustomerCard from "./Card/CustomerCard";
import VehicleCard from "./Card/VehicleCard";
import AbroadCaseCard from "./Card/AbroadCaseCard";
import React, { useEffect, useState } from "react";
import { MainFooter, MainHeader } from "../Layout/Layout";
import { IncidentUtils } from "../../helpers/IncidentUtils";
import ScheduleCard from "./Card/ScheduleCard";
import { useTranslation } from "react-i18next";
import { Api } from "../../api/api";
import { CaseUtils } from "../../helpers/CaseUtils";
import { CountryUtils } from "../../helpers/CountryUtils";
import { AppointmentUtils } from "../../helpers/AppointmentUtils";
import { IncidentType } from "../../model/IncidentType.enum";
import classNames from "classnames/bind";
import {
  FluidLossDetails,
  FluidLossFluidType,
} from "../../model/Incident/FluidLossDetails";
import { WheelAndTireDetails } from "../../model/Incident/WheelAndTireDetails";
import {
  DoorsAndKeysDetails,
  DoorsAndKeysIncidentType,
} from "../../model/Incident/DoorsAndKeysDetails";
import {
  FuelDetails,
  FuelIncidentType,
} from "../../model/Incident/FuelDetails";
import { LocationUtils } from "../../helpers/LocationUtils";

const Summary: React.FC = () => {
  const { actions, state } = useStateMachine({
    updateSteps,
    updateEditMode,
    updateSummary,
    updateIsBCMFallBack,
  });
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [missingDatas, setMissingDatas] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [displayError, setDisplayError] = useState<boolean>(false);
  const isAbroadCase = state.isAbroadCase;
  const baseIconPath = `${process.env.PUBLIC_URL}/assets/images/${
    process.env.REACT_APP_ALT_ICONS_PATH || ""
  }`;
  const getImagePath = () => {
    return `${baseIconPath}${state.summary.incidentType}.svg`;
  };

  const history = useHistory();

  useEffect(() => {
    if (!state.summary) {
      actions.updateSummary({ ...state.steps });
    }
  }, []);

  const cancelEditing = () => {
    actions.updateEditMode(!state.isInEditMode);
    actions.updateSummary({ ...state.steps });
  };

  const handleOnSave = () => {
    actions.updateEditMode(!state.isInEditMode);
    actions.updateSteps({ ...state.summary });
  };

  const displayErrorMessage = (err: string) => {
    setError(err);
    setDisplayError(true);
  };

  const handleOnSubmit = () => {
    if (
      state.summary.incidentType == IncidentType.BICYCLE ||
      state.summary.vehicleDetails.shortLicencePlate ||
      state.summary.vehicleDetails.licensePlateNumber
    ) {
      setLoading(true);
      Api.sendSummary(
        CaseUtils.createCase(
          state.summary,
          i18n.language,
          state.cachedPasswords,
          isAbroadCase,
          state.MOFISVehicle,
          state.locationAdditionalInfo
        ),
        state.token!.access_token
      )
        .then((response) => {
          setLoading(false);
          if (response.status === 202) {
            actions.updateIsBCMFallBack(true);
          }
          actions.updateSteps({ caseNumber: response.data.Id });
          history.push(`${RoutePath.REQUEST}/${RoutePath.CONFIRMATION}`);
        })
        .catch(() => {
          setLoading(false);
          displayErrorMessage(t("Error submitting the request"));
        });
    } else {
      setMissingDatas(true);
    }
  };

  const computeExtraSummaryBasedOnIncident = (): JSX.Element | undefined => {
    if (state.summary.incidentType === IncidentType.FLUID_LOSS) {
      const incident = state.summary.incidentDetails as FluidLossDetails;

      if (
        incident.fluidType === FluidLossFluidType.FUEL &&
        incident.hasSmell !== undefined
      ) {
        const hasSmell = incident.hasSmell ? t("Yes") : t("No");

        return (
          <div className={classNames("sub-card-container", "pd-5")}>
            <p className="problem-text-title">{t("Smell in vehicle")}:</p>
            <p className="problem-description-text">{hasSmell}</p>
            {state.isInEditMode && <p />}
          </div>
        );
      }
    }

    if (state.summary.incidentType === IncidentType.WHEEL_AND_TIRE) {
      const incident = state.summary.incidentDetails as WheelAndTireDetails;

      const hasSpareWheel = incident.hasSpareWheel ? t("Yes") : t("No");
      return (
        <>
          <div className={classNames("sub-card-container", "pd-5")}>
            <p className="problem-text-title">{t("Affected wheels")}:</p>
            <p className="problem-description-text">
              {t(incident.numberOfWheelsDamaged)}
            </p>
            {state.isInEditMode && <p />}
          </div>

          <div className={classNames("sub-card-container", "pd-5")}>
            <p className="problem-text-title">{t("Spare on site")}:</p>
            <p className="problem-description-text">{hasSpareWheel}</p>
            {state.isInEditMode && <p />}
          </div>
        </>
      );
    }

    if (state.summary.incidentType === IncidentType.DOOR_AND_KEYS) {
      const incident = state.summary.incidentDetails as DoorsAndKeysDetails;

      if (
        incident.breakdownType !== DoorsAndKeysIncidentType.FUEL_CAP_JAMMED &&
        incident.isLocked !== undefined
      ) {
        const isLocked = incident.isLocked ? t("Yes") : t("No");

        return (
          <div className={classNames("sub-card-container", "pd-5")}>
            <p className="problem-text-title">{t("Person locked in")}:</p>
            <p className="problem-description-text">{isLocked}</p>
            {state.isInEditMode && <p />}
          </div>
        );
      }
    }

    if (state.summary.incidentType === IncidentType.FUEL) {
      const incident = state.summary.incidentDetails as FuelDetails;

      return (
        <>
          {incident.breakdownType === FuelIncidentType.INCORRECTLY_REFUELED && (
            <div className={classNames("sub-card-container", "pd-5")}>
              <p className="problem-text-title">{t("Quantity (in liters)")}:</p>
              <p className="problem-description-text">
                {incident.wrongFuelLiters}
              </p>
              {state.isInEditMode && <p />}
            </div>
          )}
          <div className={classNames("sub-card-container", "pd-5")}>
            <p className="problem-text-title">{t("Fuel type")}:</p>
            <p className="problem-description-text">{t(incident.fuelType)}</p>
            {state.isInEditMode && <p />}
          </div>
        </>
      );
    }

    return undefined;
  };

  return (
    (!state.summary && <div>{t("No summary available...")}</div>) ||
    (state.summary && (
      <IonContent>
        <MainHeader title={t("Summary")}>
          <h5>
            {t(
              "Verify that the information is correct before submitting. If not, press the Edit button to correct any errors."
            )}
          </h5>
          <IssueCard
            onClick={(route) => history.push(`${RoutePath.REQUEST}/${route}`)}
            isEditing={state.isInEditMode}
            incident={{
              type: state.summary.incidentType,
              incidentPickerRoute: RoutePath.INCIDENT_TYPE,
              incidentRoute: IncidentUtils.findRouteByIncidentType(
                state.summary.incidentType
              ),
              problem: state.summary.incidentDetails
                ? IncidentUtils.getSummary(
                    state.summary.incidentType,
                    state.summary.incidentDetails!
                  )
                : "",
              additionalInformation:
                state.summary.incidentDetails &&
                state.summary.incidentDetails.additionalInformation
                  ? state.summary.incidentDetails.additionalInformation
                  : "",
              image: getImagePath(),
            }}
            customHtml={computeExtraSummaryBasedOnIncident()}
          />
          {AppointmentUtils.canMakeAnAppointment(
            state.summary.incidentType,
            state.summary.incidentDetails,
            state.summary.immobilizedOnHighway,
            state.summary.locationFields
          ) && (
            <ScheduleCard
              onClick={(route) => history.push(`${RoutePath.REQUEST}/${route}`)}
              isEditing={state.isInEditMode}
              schedule={state.summary.schedule}
            />
          )}
          <LocationCard
            onClick={(route) => history.push(`${RoutePath.REQUEST}/${route}`)}
            isEditing={state.isInEditMode}
            locationFields={state.summary.locationFields}
            immobilizedOnHighway={state.summary.immobilizedOnHighway}
            incidentType={state.summary.incidentType}
            parkedAtHome={state.summary.parkedAtHome ? t("Yes") : t("No")}
            additionalInformationLabel={LocationUtils.getLabelAdditionalInformation(
              state.isInEditMode,
              state.summary.immobilizedOnHighway,
              state.steps.immobilizedOnHighway,
              t
            )}
          />
          {isAbroadCase && (
            <AbroadCaseCard
              onClick={(route) => history.push(`${RoutePath.REQUEST}/${route}`)}
              isEditing={state.isInEditMode}
              abroadCaseDetail={state.summary.abroadCaseDetail}
            ></AbroadCaseCard>
          )}
          <CustomerCard
            onClick={(route) => history.push(`${RoutePath.REQUEST}/${route}`)}
            isEditing={state.isInEditMode}
            personalDetails={state.summary.personalDetails}
          />

          {state.summary.incidentType !== IncidentType.BICYCLE && (
            <VehicleCard
              onClick={() =>
                history.push(
                  `${RoutePath.REQUEST}/${RoutePath.VEHICLE_DETAILS}`
                )
              }
              isEditing={state.isInEditMode}
              vehicleDetails={state.summary.vehicleDetails}
              MOFISVehicle={state.MOFISVehicle}
            />
          )}
        </MainHeader>

        <MainFooter isSticky={true}>
          {!state.isInEditMode && (
            <IonButton
              id="summary-edit"
              className="button-tcs back"
              expand="block"
              color="secondary"
              onClick={() => {
                actions.updateEditMode(!state.isInEditMode);
              }}
            >
              <IonLabel>{t("Edit")}</IonLabel>
            </IonButton>
          )}
          {state.isInEditMode && (
            <IonButton
              id="summary-cancel"
              className="button-tcs"
              expand="block"
              color="secondary"
              onClick={() => cancelEditing()}
            >
              <IonLabel>{t("Cancel")}</IonLabel>
            </IonButton>
          )}
          {!state.isInEditMode && (
            <IonButton
              id="summary-submit"
              className="button-tcs cta"
              color="primary"
              expand="block"
              onClick={() => handleOnSubmit()}
            >
              <IonLabel>{t("Submit")}</IonLabel>
            </IonButton>
          )}
          {state.isInEditMode && (
            <IonButton
              id="summary-save"
              className="button-tcs cta"
              color="primary"
              expand="block"
              onClick={() => handleOnSave()}
            >
              <IonLabel>{t("Save")}</IonLabel>
            </IonButton>
          )}
        </MainFooter>
        <IonLoading
          isOpen={loading}
          onDidDismiss={() => setLoading(false)}
          message={t("Processing...")}
        />

        <IonAlert
          isOpen={missingDatas}
          onDidDismiss={() => {
            setMissingDatas(false);
            history.push(
              `${RoutePath.REQUEST}/${RoutePath.HIGHWAY_IMMOBILIZED}`
            );
          }}
          message={t("The data you are trying to submit is incomplete")}
          buttons={[
            {
              text: t("Continue"),
              cssClass: "button-tcs customAlertYellowButton",
            },
          ]}
        />
        <IonToast
          position="top"
          isOpen={displayError}
          onDidDismiss={() => setDisplayError(false)}
          message={error}
          duration={3000}
        />
      </IonContent>
    ))
  );
};

export default Summary;
