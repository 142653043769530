import "./SummaryCard.scss";
import classNames from "classnames/bind";

import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
} from "@ionic/react";
import { CardProps } from "./SummaryCardPropsModel";
import { RoutePath } from "../../../model/RoutePath.enum";
import { useTranslation } from "react-i18next";
import React from "react";
import { VehicleDetailsUtils } from "../../../helpers/VehicleDetailsUtils";
import { CountryUtils } from "../../../helpers/CountryUtils";

const VehicleCard: React.FC<CardProps> = ({
  onClick,
  isEditing,
  vehicleDetails,
  MOFISVehicle,
}) => {
  const { t } = useTranslation();
  const displayFormattedPlate = () => {
    if (CountryUtils.isSwitzerlandCountry(vehicleDetails.country)) {
      if (VehicleDetailsUtils.isSpecialPlate(vehicleDetails.canton)) {
        return vehicleDetails.canton + " - " + vehicleDetails.shortLicencePlate;
      } else {
        return vehicleDetails.canton + " " + vehicleDetails.shortLicencePlate;
      }
    } else {
      return vehicleDetails.licensePlateNumber;
    }
  };

  const onlyLicencePlate = () => {
    return (
      !vehicleDetails.brand && !vehicleDetails.model && !vehicleDetails.color
    );
  };

  return (
    <IonCard
      onClick={() => isEditing && onClick(RoutePath.VEHICLE_DETAILS)}
      className={classNames("summary-card")}
    >
      <IonCardHeader className="card-header">
        <IonCardTitle>{t("Vehicle")}</IonCardTitle>
      </IonCardHeader>

      <IonCardContent class="card-content">
        <div className={classNames("summary-card", { editing: isEditing })}>
          <div className="edit-icon">
            <img
              alt="pencil"
              src={`${process.env.PUBLIC_URL}/assets/images/${
                process.env.REACT_APP_ALT_ICONS_PATH || ""
              }pencil.svg`}
            />
          </div>

          {vehicleDetails.country && (
            <div className={classNames("sub-card-container", "pd-5")}>
              <p className="problem-text-title">{t("Country")}:</p>
              <p className="problem-description-text">
                {vehicleDetails.country}
              </p>
              {isEditing && <p />}
            </div>
          )}

          <div
            className={classNames("sub-card-container", "pd-5", {
              "row-min-height": onlyLicencePlate(),
            })}
          >
            <p className="problem-text-title">{t("License plate number")}:</p>
            <p className="problem-description-text">
              {displayFormattedPlate()}
            </p>
            {isEditing && <p />}
          </div>

          {process.env.REACT_APP_ENABLE_VIN_INPUT === "true" &&
            vehicleDetails?.vin && (
              <div className={classNames("sub-card-container", "pd-5")}>
                <p className="problem-text-title">{t("VIN")}:</p>
                <p className="problem-description-text">
                  {vehicleDetails?.vin?.slice(0, 10)}
                </p>
                <p></p>
                <p className="problem-description-text">
                  {vehicleDetails?.vin?.slice(10, vehicleDetails.vin.length)}
                </p>
                {isEditing && <p />}
              </div>
            )}

          {MOFISVehicle && (
            <div className={classNames("sub-card-container", "pd-5")}>
              <p className="problem-text-title">{t("Model")}:</p>
              <p className="problem-description-text">
                {MOFISVehicle.completeBrandModel},{" "}
                {VehicleDetailsUtils.translateVehicleColor(MOFISVehicle.color)},{" "}
                {MOFISVehicle.firstDateOfRegistration.slice(0, 4)}
              </p>
              {isEditing && <p />}
            </div>
          )}

          {vehicleDetails.brand && (
            <div className={classNames("sub-card-container", "pd-5")}>
              <p className="problem-text-title">{t("Brand")}:</p>
              <p className="problem-description-text">{vehicleDetails.brand}</p>
              {isEditing && <p />}
            </div>
          )}

          {vehicleDetails.model && (
            <div className={classNames("sub-card-container", "pd-5")}>
              <p className="problem-text-title">{t("Model")}:</p>
              <p className="problem-description-text">{vehicleDetails.model}</p>
              {isEditing && <p />}
            </div>
          )}

          {vehicleDetails.color && (
            <div className={classNames("sub-card-container", "pd-5")}>
              <p className="problem-text-title">{t("Color")}:</p>
              <p className="problem-description-text">{vehicleDetails.color}</p>
              {isEditing && <p />}
            </div>
          )}
          {vehicleDetails.howManyPassengers != 0 && (
            <div className={classNames("sub-card-container", "pd-5")}>
              <p className="problem-text-title">{t("Number of passenger")}:</p>
              <p className="problem-description-text">
                {vehicleDetails.howManyPassengers}
              </p>
              {isEditing && <p />}
            </div>
          )}
          {vehicleDetails.additionalInformations && (
            <div className={classNames("sub-card-container", "pd-5")}>
              <p className="problem-text-title">
                {t("Additional information")}:
              </p>
              <p className="problem-description-text">
                {vehicleDetails.additionalInformations}
              </p>
              {isEditing && <p />}
            </div>
          )}
        </div>
      </IonCardContent>
    </IonCard>
  );
};

export default VehicleCard;
