import axios, {
  AxiosInstance,
  AxiosRequestHeaders,
  AxiosResponse,
} from "axios";
import {
  VehicleBrand,
  VehicleColor,
  VehicleInfos,
} from "./models/VehicleInfos";

export class Mofis {
  private static readonly BASE_URL = process.env.REACT_APP_MOFIS_BASE_URL!; // TODO: Need to be updated with partner external api. see DGTINT-367
  private static readonly CLIENT_ID = process.env.REACT_APP_MOFIS_CLIENT_ID!;
  private static readonly CLIENT_SECRET =
    process.env.REACT_APP_MOFIS_CLIENT_SECRET!;
  private static readonly TOKEN = process.env.REACT_APP_MOFIS_TOKEN!;

  private static readonly VEHICLE_CATEGORY_TYPES = [
    "001",
    "002", // TODO: Need to be updated according to DI requirements. see DGTINT-367
  ];

  private static readonly API: AxiosInstance = axios.create({
    baseURL: Mofis.BASE_URL,
    responseType: "json",
    headers: Mofis.getHeaders(),
  });

  public static getVehicleInfos(
    licensePlate: string,
    token: string
  ): Promise<AxiosResponse<VehicleInfos[]>> {
    return this.API.get<VehicleInfos[]>("vehicles/", {
      headers: this.getHeaders(token),
      params: {
        "license-plate": licensePlate,
      },
      timeout: 10000,
    });
  }

  public static getMofisBrands(): Promise<AxiosResponse<VehicleBrand[]>> {
    return this.API.get<VehicleBrand[]>("vehicle-reference/manufacturers", {
      headers: this.getHeaders(),
      params: {
        "vehicle-category-types": Mofis.VEHICLE_CATEGORY_TYPES.join(","),
      },
    });
  }

  public static getMofisColors(): Promise<AxiosResponse<VehicleColor[]>> {
    return this.API.get<VehicleColor[]>("vehicle-reference/colors", {
      headers: this.getHeaders(),
    });
  }

  private static getHeaders(token?: string): AxiosRequestHeaders {
    return {
      "X-IBM-Client-Id": Mofis.CLIENT_ID,
      "X-IBM-Client-Secret": Mofis.CLIENT_SECRET,
      Authorization: token ? `Bearer ${token}` : Mofis.TOKEN,
    };
  }
}
