// t('Problem with the key / remote')
// t('Key / remote locked in car')
// t('Fuel cap jammed / defective')
// t('Lost key')
// t('Other')

export enum DoorsAndKeysIncidentType {
  KEY_REMOTE_PROBLEM = "Problem with the key / remote",
  KEY_REMOTE_LOCKED_IN_CAR = "Key / remote locked in car",
  FUEL_CAP_JAMMED = "Fuel cap jammed / defective",
  LOST_KEY_REMOTE = "Lost key",
  OTHER = "Other",
}

export interface DoorsAndKeysDetails {
  breakdownType: string;
  isLocked: boolean;
  additionalInformation: string;
}
