import "./SummaryCard.scss";
import classNames from "classnames/bind";

import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
} from "@ionic/react";
import { CardProps } from "./SummaryCardPropsModel";
import { RoutePath } from "../../../model/RoutePath.enum";
import { useTranslation } from "react-i18next";
import React from "react";
import { PolicyHolderType } from "../../../model/PolicyHolderType.enum";

const CustomerCard: React.FC<CardProps> = ({
  onClick,
  isEditing,
  personalDetails,
}) => {
  const displayPolicyHolderCard =
    process.env.REACT_APP_ADDITIONAL_POLICY_HOLDER_INFORMATION &&
    process.env.REACT_APP_ADDITIONAL_POLICY_HOLDER_INFORMATION == "true";
  const { t } = useTranslation();

  const getFormattedAddress = (): string => {
    return `${personalDetails.streetAddress} ${personalDetails.streetNumber}, ${personalDetails.postalCode} ${personalDetails.city}, ${personalDetails.country}`;
  };

  return (
    <>
      <IonCard
        onClick={() => isEditing && onClick(RoutePath.PERSONAL_DETAILS)}
        className={classNames("summary-card")}
      >
        <IonCardHeader className="card-header">
          {displayPolicyHolderCard && (
            <IonCardTitle>{t("Driver")}</IonCardTitle>
          )}
          {!displayPolicyHolderCard && (
            <IonCardTitle>{t("Customer")}</IonCardTitle>
          )}
        </IonCardHeader>
        <IonCardContent class="card-content">
          <div className={classNames("summary-card", { editing: isEditing })}>
            <div className="edit-icon">
              <img
                alt="pencil"
                src={`${process.env.PUBLIC_URL}/assets/images/${
                  process.env.REACT_APP_ALT_ICONS_PATH || ""
                }pencil.svg`}
              />
            </div>

            {personalDetails.personalReference && (
              <div className={classNames("sub-card-container", "pd-5")}>
                <p className="problem-text-title">{t("Personal Reference")}:</p>
                <p className="problem-description-text">
                  {personalDetails.personalReference}
                </p>
                {isEditing && <p />}
              </div>
            )}

            <div className={classNames("sub-card-container", "pd-5")}>
              <p className="problem-text-title">{t("First Name")}:</p>
              <p className="problem-description-text">
                {personalDetails.firstName}
              </p>
              {isEditing && <p />}
            </div>

            <div className={classNames("sub-card-container", "pd-5")}>
              <p className="problem-text-title">{t("Last Name")}:</p>
              <p className="problem-description-text">
                {personalDetails.lastName}
              </p>
              {isEditing && <p />}
            </div>

            <div className={classNames("sub-card-container", "pd-5")}>
              <p className="problem-text-title">{t("Phone")}:</p>
              <p className="problem-description-text">
                {personalDetails.phone}
              </p>
              {isEditing && <p />}
            </div>
            {!process.env.REACT_APP_SIMPLIFIED_PERSONAL_DETAILS ||
              (process.env.REACT_APP_SIMPLIFIED_PERSONAL_DETAILS == "false" && (
                <div className={classNames("sub-card-container", "pd-5")}>
                  <p className="problem-text-title">{t("Address")}:</p>
                  <p className="problem-description-text">
                    {getFormattedAddress()}
                  </p>
                  {isEditing && <p />}
                </div>
              ))}
          </div>
        </IonCardContent>
      </IonCard>
      {displayPolicyHolderCard && (
        <IonCard
          onClick={() => isEditing && onClick(RoutePath.PERSONAL_DETAILS)}
          className={classNames("summary-card")}
        >
          <IonCardHeader className="card-header">
            {displayPolicyHolderCard && (
              <IonCardTitle>{t("Customer")}</IonCardTitle>
            )}
          </IonCardHeader>
          <IonCardContent class="card-content">
            <div className={classNames("summary-card", { editing: isEditing })}>
              <div className="edit-icon">
                <img
                  alt="pencil"
                  src={`${process.env.PUBLIC_URL}/assets/images/${
                    process.env.REACT_APP_ALT_ICONS_PATH || ""
                  }pencil.svg`}
                />
              </div>
              {personalDetails.policyHolderType ===
                PolicyHolderType.COMPANY && (
                <div
                  className={classNames("sub-card-container", "pd-5", {
                    "row-min-height": isEditing,
                  })}
                >
                  <p className="problem-text-title">{t("Company Name")}:</p>
                  <p className="problem-description-text">
                    {personalDetails.policyHolderCompanyName}
                  </p>
                  {isEditing && <p />}
                </div>
              )}
              {personalDetails.policyHolderType ===
                PolicyHolderType.ANOTHER_PERSON && (
                <div
                  className={classNames("sub-card-container", "pd-5", {
                    "row-min-height": isEditing,
                  })}
                >
                  <p className="problem-text-title">{t("Another person")}:</p>
                  <p className="problem-description-text">
                    {personalDetails.policyHolderFirstName}{" "}
                    {personalDetails.policyHolderLastName}
                  </p>
                  {isEditing && <p />}
                </div>
              )}
              {personalDetails.policyHolderType === PolicyHolderType.ME && (
                <div
                  className={classNames("sub-card-container", "pd-5", {
                    "row-min-height": isEditing,
                  })}
                >
                  <p className="problem-text-title">{t("Me")}:</p>
                  <p className="problem-description-text">
                    {personalDetails.firstName} {personalDetails.lastName}
                  </p>
                  {isEditing && <p />}
                </div>
              )}
            </div>
          </IonCardContent>
        </IonCard>
      )}
    </>
  );
};

export default CustomerCard;
